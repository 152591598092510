import React, { useState, useEffect } from 'react'
import 'dhtmlx-scheduler/codebase/dhtmlxscheduler_material.css'
import { initScheduler } from '../../../helpers/planning/gestionTache/gantt'
import { useNavigate } from 'react-router-dom'
import { BeatLoader } from 'react-spinners'
import LoaderFixed from '../../../components/loader/LoaderFixed'
import { scheduler } from 'dhtmlx-scheduler'
import { useSelector, useDispatch } from 'react-redux'
import { ReactTabulator } from 'react-tabulator'
import { useToasts } from 'react-toast-notifications'
import FormatPdfLtp from './component/FormatPdfLtp'
import UpdateZoneLtp from './component/UpdateZoneLtp'
import {
  getAllEvent,
  addEvent,
  addZone,
  getAllZone,
  delEvent,
  updateEvent,
  getAllLotGlissant,
  getAllCarteGlissant,
  UpdateZone,
  DeleteZone,
  shareEvent,
} from '../../../helpers/planning/gestionTache/dataScheduler'
import { getDataPresence } from '../../../helpers/suivi/suivi'
import './partage.scss'
//Page d'affichage du las planner system
import { getTacheAssigner } from '../../../helpers/observation/observation'
const LastPlanner2 = ({
  zoom,
  planning,
  btnPartage,
  updateBtnPartage,
  width,
  btnPartageValue,
  rfltp,
  setRfLtp,
}) => {
  const { addToast } = useToasts()
  const bool = useSelector((state) => state.bool.bool)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  if (
    !localStorage.getItem('isLogin') ||
    (infoUser?.user?.role?.roleType != 'admin' &&
      infoUser?.user?.role?.roleType != 'opc' &&
      infoUser?.user?.role?.roleType != 'moex')
  ) {
    return <PageNotFound />
  }

  const dispatch = useDispatch()
  scheduler.plugins({
    multiselect: true,
    multisection: true,
    timeline: true,
    minical: true,
    daytimeline: true,
    limit: true,
    outerdrag: true,
    drag_between: true,
  })
  scheduler.xy.bar_height = 37
  const navigate = useNavigate()
  const [realWidth, setRealWidth] = useState(width)
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const [zone, setZone] = useState([])
  const [zonePdf, setZonePdf] = useState([])
  const [wait, setWait] = useState(false)
  const [bools, setBools] = useState(false)
  const [newZone, setNewZone] = useState('')
  const [format, setFormat] = useState(true)
  const [legende, setLegende] = useState([])
  const [dataPresence, setDataPresence] = useState([])
  const [dataScheduler, setDataScheduler] = useState([])
  const [itemPdf, setItemPdf] = useState('zone')
  const [item, setItem] = useState('semaine')
  const [sousTitre, setSousTitre] = useState('')
  const [btnLtp, setBtnLtp] = useState(
    localStorage.getItem('ltp')
      ? JSON.parse(localStorage.getItem('ltp'))
      : false
  )

  //variable d'assignement
  const [tacheSd, setTacheSd] = useState([])
  const [tacheSc, setTacheSc] = useState([])
  const [tacheSp, setTacheSp] = useState([])
  const [allTacheAssigner, setAllTacheAssigner] = useState([])
  const [idEvent, setIdEvent] = useState('')
  const dateFr = (value) => {
    const dt = value.split('T')[0]
    const dt1 = dt.split('-')
    const result = `${dt1[2]}/${dt1[1]}/${dt1[0]}`
    return result
  }
  const getDate = (date) => {
    const oneDay = 24 * 60 * 60 * 1000
    const dateDuCalcul = date || new Date()
    const currentDayOfWeek = dateDuCalcul.getDay()
    const monday = new Date(date.getTime() - (currentDayOfWeek - 1) * oneDay)

    const friday = new Date(monday.getTime() + 4 * oneDay)

    const previousMonday = new Date(monday.getTime() - 7 * oneDay)

    const previousFriday = new Date(friday.getTime() - 7 * oneDay)

    const nextMonday = new Date(monday.getTime() + 7 * oneDay)

    const nextFriday = new Date(friday.getTime() + 7 * oneDay)

    const dates = {
      currentWeek: {
        start: monday.toISOString().slice(0, 10),
        end: friday.toISOString().slice(0, 10),
      },
      previousWeek: {
        start: previousMonday.toISOString().slice(0, 10),
        end: previousFriday.toISOString().slice(0, 10),
      },
      nextWeek: {
        start: nextMonday.toISOString().slice(0, 10),
        end: nextFriday.toISOString().slice(0, 10),
      },
    }

    return dates
  }

  useEffect(() => {
    const date = getDate(new Date(Date.now()))
    //S-1
    getTacheAssigner(
      infoChantier.planning,
      'end_date[lte]',
      'end_date[gte]',
      date.previousWeek.end,
      date.previousWeek.start,
      infoUser.token
    ).then((e) => {
      if (e.data) {
        setTacheSd(e.data)
      }
    })
    //S
    getTacheAssigner(
      infoChantier.planning,
      'start_date[lte]',
      'end_date[gte]',
      date.currentWeek.start,
      date.currentWeek.end,
      infoUser.token
    ).then((e1) => {
      getTacheAssigner(
        infoChantier.planning,
        'start_date[gte]',
        'start_date[lte]',
        date.currentWeek.start,
        date.currentWeek.end,
        infoUser.token
      ).then((e2) => {
        getTacheAssigner(
          infoChantier.planning,
          'end_date[gte]',
          'end_date[lte]',
          date.currentWeek.start,
          date.currentWeek.end,
          infoUser.token
        ).then((e3) => {
          if (e1.data && e2.data && e3.data) {
            const tableauFusionner = [...e1.data, ...e2.data, ...e3.data]
            const tableauSansDouble = tableauFusionner.filter((obj, i, tab) => {
              const position = tab.findIndex((item) => item.id === obj.id)
              return position == i
            })
            setTacheSc(tableauSansDouble)
          }
        })
      })
    })
    //S+1
    getTacheAssigner(
      infoChantier.planning,
      'start_date[gte]',
      'start_date[lte]',
      date.nextWeek.start,
      date.nextWeek.end,
      infoUser.token
    ).then((e) => {
      if (e.data) {
        setTacheSp(e.data)
      }
    })
    if (document.querySelector('.image-marker__marker')) {
      document
        .querySelector('.image-marker__marker')
        .setAttribute('draggable', 'true')
    }
  }, [])

  useEffect(() => {
    setRealWidth(width)
  }, [width])
  useEffect(() => {
    setBtnLtp(JSON.parse(localStorage.getItem('ltp')))
  }, [rfltp])
  scheduler.attachEvent('onDblClick', function (id, e) {
    //any custom logic here
    let ev = scheduler.getEvent(id)
    setIdEvent(id)
    setAllTacheAssigner(ev.tachesAssigne ? ev.tachesAssigne : [])
    return true
  })
  const trouverDateMax = (tableau) => {
    if (tableau.length === 0) return null // Retourne null si le tableau est vide

    // Initialise l'objet avec la première entrée du tableau
    let dateMaxObjet = tableau[0]
    let dateMax = new Date(tableau[0].end_date)

    // Parcourt le tableau pour trouver la date la plus grande
    for (let i = 1; i < tableau.length; i++) {
      let currentDate = new Date(tableau[i].end_date)
      if (currentDate > dateMax) {
        dateMax = currentDate
        dateMaxObjet = tableau[i]
      }
    }
    return dateMaxObjet.end_date
  }
  if (!scheduler._onEventSave) {
    scheduler._onEventSave = scheduler.attachEvent(
      'onEventSave',
      async function (id, ev, is_new, original) {
        const planningLP = localStorage.getItem('infoChantiers')
        const planningReel = JSON.parse(planningLP)
        var ev1 = scheduler.getEvent(id)
        if (ev1._id) {
          console.log(ev1)
          const dateMax = trouverDateMax(ev1.tachesAssigne)
          if (dateMax) {
            const dateEv = new Date(ev1.end_date)
            const dateMaxValue = new Date(dateMax)
            if (dateEv > dateMaxValue) {
              if (
                confirm(
                  'La date dépasse le délai des tâches initiales. Voulez-vous confirmer les modifications ?'
                )
              ) {
                await updateEvent(
                  ev1._id,
                  ev1,
                  infoUser.token,
                  infoChantier.planning
                ).then((e) => {
                  //setBools(!bools)
                  //dispatch({ type: 'bool/modifier', payload: false })
                })
              } else {
                console.log('ne pas confirmer')
                scheduler.getEvent(ev1.id).end_date = original.end_date
                scheduler.updateEvent(ev1.id)
              }
            } else {
              console.log('la date ne depasse pas')
              await updateEvent(
                ev1._id,
                ev1,
                infoUser.token,
                infoChantier.planning
              ).then((e) => {
                //setBools(!bools)
                //dispatch({ type: 'bool/modifier', payload: false })
              })
            }
          } else {
            await updateEvent(
              ev1._id,
              ev1,
              infoUser.token,
              infoChantier.planning
            ).then((e) => {
              //setBools(!bools)
              //dispatch({ type: 'bool/modifier', payload: false })
            })
          }
        } else {
         await addEvent(
            planningReel.planning,
            ev,
            infoUser.token,
            infoChantier.planning
          ).then((e) => {
            ev1._id = e._id
            //setBools(!bools)
            //dispatch({ type: 'bool/modifier', payload: false })
          })
        }
        return true
      }
    )
  }
  if (!scheduler._onBeforeEventChanged) {
    scheduler._onBeforeEventChanged = scheduler.attachEvent(
      'onBeforeEventChanged',
      async function (ev, e, is_new, original) {
        console.log(ev)
        if (ev._id) {
          const dateMax = trouverDateMax(ev.tachesAssigne)
          if (dateMax) {
            console.log(dateMax)
            const dateEv = new Date(ev.end_date)
            const dateMaxValue = new Date(dateMax)
            if (dateEv > dateMaxValue) {
              if (
                confirm(
                  'La date dépasse le délai des tâches initiales. Voulez-vous confirmer les modifications ?'
                )
              ) {
                await updateEvent(
                  ev._id,
                  ev,
                  infoUser.token,
                  infoChantier.planning
                )
                  .then((e) => {})
                  .catch((er) => {
                    console.log(er)

                    alert("Une erreur s'est produit, veuillez réssayer!")
                  })
              } else {
                console.log('ne pas confirmer')
                scheduler.getEvent(ev.id).end_date = original.end_date
                scheduler.updateEvent(ev.id)
              }
            } else {
              console.log('ne depasse pas')
              await updateEvent(
                ev._id,
                ev,
                infoUser.token,
                infoChantier.planning
              )
                .then((e) => {})
                .catch((er) => {
                  console.log(er)
                  alert("Une erreur s'est produit, veuillez réssayer!")
                })
            }
          } else {
            await updateEvent(ev._id, ev, infoUser.token, infoChantier.planning)
              .then((e) => {})
              .catch((er) => {
                console.log(er)
                alert("Une erreur s'est produit, veuillez réssayer!")
              })
          }

          return true
        } else {
          return true
        }
      }
    )
  }
  if (!scheduler._onBeforeEventDelete) {
    scheduler._onBeforeEventDelete = scheduler.attachEvent(
      'onBeforeEventDelete',
      function (id, ev) {
        delEvent(ev._id, infoUser.token, infoChantier.planning).then((e) => {
          //setBools(!bools)
          //dispatch({ type: 'bool/modifier', payload: false })
        })
        return true
      }
    )
  }

  const checkbox = (cell) => {
    const candidate = cell.getData()
    const input = document.createElement('input')
    input.type = 'checkbox'
    input.className = 'form-check-input'
    input.defaultChecked = cell.getValue()
    input.addEventListener('change', function (e) {
      cell.setValue(!cell.getValue())
      if (cell.getValue()) {
        candidate.envoyerEmailLp = true
      } else {
        candidate.envoyerEmailLp = false
      }
    })
    const view = document.createElement('div')
    view.className = 'form-check form-switch form-switch-primary mt-2'
    view.appendChild(input)
    return view
  }

  const colonneDestinataire = [
    { title: 'Nom', field: 'name' },
    { title: 'Prénom', field: 'lastname' },
    { title: 'Entreprise', field: 'entrepriseName' },
    { title: 'Email', field: 'email' },
    {
      title: '',
      field: 'envoyerEmailLp',
      formatter: checkbox,
    },
  ]

  const printContent = async () => {
    //await setRealWidth('1200px')
    // initScheduler(scheduler, 'schedule', dataScheduler, legende,[], zonePdf)
    /*const lastPlannerCible = document.querySelector('#schedule')
     lastPlannerCible.style = "width:400px" */
    const original = document.body.innerHTML
    const buttonHide = document.querySelector('.dhx_cal_today_button')
    buttonHide.style = 'display:none'
    const buttonHide1 = document.querySelector('.dhx_cal_prev_button')
    buttonHide1.style = 'display:none'
    const buttonHide2 = document.querySelector('.dhx_cal_next_button')
    buttonHide2.style = 'display:none'
    //nouveau format
    const cible = document.getElementById('cibles')
    const navline = document.querySelector('.dhx_cal_navline')
    const header = document.querySelector('.dhx_cal_header')
    const dataPlanner = document.querySelector('.dhx_timeline_table_wrapper')
    dataPlanner.style = 'position:absolute;top:100px;width:100%'
    const containerData = document.createElement('div')
    containerData.style =
      'position:relative !important;background-color:white;z-index:222222222222'
    containerData.appendChild(navline)
    containerData.appendChild(header)
    containerData.appendChild(dataPlanner)
    cible.appendChild(containerData)
    const container = document.querySelector('.hide-format')
    container.style = 'display:block'
    document.body.innerHTML = ''
    document.body.style =
      'background-color:white;width:1200px;padding:0;position:relative'
    document.body.appendChild(container)
    window.print()
    document.body.innerHTML = original
    location.reload()
  }
  useEffect(() => {
    getDataPresence(infoChantier.planning, infoUser.token, '')
      .then((e) => {
        if (e.responsables) {
          setDataPresence(e.responsables)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  useEffect(() => {
    setWait(true)
    if (infoChantier.planning) {
      getAllEvent(infoChantier.planning, infoUser.token).then((datas) => {
        setDataScheduler(datas ? datas : [])
        getAllZone(infoChantier.planning, infoUser.token).then((zones) => {
          zones.map((el, index) => {
            el.index = index
          })
          setZonePdf(zones)
          setZone(zones)
          getAllLotGlissant(infoChantier.planning, infoUser.token).then(
            (lot) => {
              getAllCarteGlissant(infoChantier.planning, infoUser.token).then(
                (res) => {
                  setLegende([...lot, ...res])
                  if (btnPartageValue) {
                    initScheduler(scheduler, 'schedule', datas, lot, res, zones)
                  } else {
                    initScheduler(scheduler, 'schedule', datas, lot, res, [
                      ...zones,
                      { label: 'Non attribuées', key: '0' },
                    ])
                  }
                  const events = scheduler.getEvents()
                  events.map((e) => {
                    for (var i = 0; i < lot.length; i++) {
                      if (lot[i].key === e.lot) {
                        e.color = lot[i].color
                        break
                      }
                    }
                  })
                  setWait(false)
                }
              )
            }
          )
        })
      })
    } else {
    }
  }, [bools, bool])
  return (
    <>
      <div
        style={{
          border: '1px solid #E2E8F0',
          backgroundColor: '#FFFFFF',
          boxShadow:
            '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
          borderRadius: '8px',
          width: '100%',
          overflow: 'hidden',
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '12px',
            marginBottom: '12px',
            marginLeft: '12px',
            marginRight: '12px',
            padding: '12px 0px',
          }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              border: '1px solid #CBD5E1',
              borderRadius: '4px',
              padding: '4px 4px 4px 16px',
              fontSize: '16px',
              fontWeight: '600',
              color: '#475569',
            }}>
            <span
              style={
                item == 'semaine'
                  ? {
                      color: '#F8FAFC',
                      padding: '4px 24px',
                      backgroundColor: '#6366F1',
                      borderRadius: '4px',
                      marginLeft: '16px',
                    }
                  : { marginLeft: '16px', cursor: 'pointer' }
              }
              onClick={(e) => {
                e.preventDefault()
                setItem('semaine')
                scheduler.setCurrentView(new Date(), 'timeline')
              }}>
              Semaine
            </span>
            <span
              style={
                item == 'mois'
                  ? {
                      color: '#F8FAFC',
                      padding: '4px 24px',
                      backgroundColor: '#6366F1',
                      borderRadius: '4px',
                      marginLeft: '16px',
                    }
                  : {
                      marginLeft: '22px',
                      cursor: 'pointer',
                      marginRight: '22px',
                    }
              }
              onClick={(e) => {
                e.preventDefault()
                setItem('mois')
                scheduler.setCurrentView(new Date(), 'month')
              }}>
              Mois
            </span>
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            {btnPartage && (
              <div
                className="d-flex"
                style={{ alignItems: 'center', marginRight: '5px' }}>
                <div className="btn">
                  <span style={{ paddingTop: '2px' }} className="mt-2">
                    Partager:
                  </span>
                </div>
                <label class="switch p-1" style={{ position: 'relative' }}>
                  <input
                    type="checkbox"
                    defaultChecked={btnLtp}
                    onChange={(e) => {
                      scheduler.setCurrentView(new Date(), 'timeline')
                      if (e.target.checked) {
                        localStorage.setItem('ltp', JSON.stringify(true))
                        setRfLtp(!rfltp)
                      } else {
                        localStorage.setItem('ltp', JSON.stringify(false))
                        setRfLtp(!rfltp)
                      }
                    }}
                  />
                  <span class="slider round"></span>
                </label>
              </div>
            )}
            <div>
              <button
                className="btn"
                style={{
                  backgroundColor: '#2DD4BF',
                  color: '#F8FAFC',
                  padding: '8px 12px',
                }}
                data-bs-toggle="modal"
                data-bs-target="#myModal">
                + Nouvelle zone
              </button>
            </div>
            <div>
              <button
                className="btn"
                style={{
                  backgroundColor: '#4F46E5',
                  color: '#F8FAFC',
                  padding: '8px 12px',
                  marginLeft: '5px',
                }}
                data-bs-toggle="modal"
                data-bs-target="#myModal1">
                Liste des zones
              </button>
            </div>
            <div
              style={{
                border: '1px solid #CBD5E1',
                borderRadius: '8px',
                padding: '8px',
                width: '36px',
                height: '36px',
                marginLeft: '32px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              data-bs-toggle="modal"
              data-bs-target="#myModal2"
              onClick={(ev) => {
                setRealWidth('1200px')
              }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="#6366F1"
                style={{ width: '20px', height: '20px' }}>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z"
                />
              </svg>
            </div>
            <button
              style={{
                border: '1px solid #CBD5E1',
                borderRadius: '8px',
                padding: '8px',
                width: '36px',
                height: '36px',
                marginLeft: '8px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                backgroundColor: 'transparent',
              }}
              id="dropdownMenuButton1"
              onClick={(e) => {
                e.preventDefault()
              }}
              data-bs-toggle="modal"
              data-bs-target="#myModalListZone">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="#6366F1"
                style={{ width: '20px', height: '20px' }}>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                />
              </svg>
            </button>
          </div>
        </div>
        <div
          style={{ width: `${realWidth}`, borderTop: '1px solid #E2E8F0' }}
          className="lastPlannerCible">
          <div
            id="schedule"
            class="dhx_cal_container"
            style={{ width: '100%', minHeight: '70vh' }}>
            <div class="dhx_cal_navline">
              <div
                class="dhx_cal_prev_button"
                onClick={(e) => {
                  e.preventDefault()
                  if (scheduler.getState().mode == 'timeline') {
                    scheduler.setCurrentView(
                      new Date(scheduler.getState().date.valueOf() + 86400000),
                      scheduler.getState().mode
                    )
                  }
                }}></div>
              <div
                class="dhx_cal_next_button"
                onClick={(e) => {
                  e.preventDefault()
                  if (scheduler.getState().mode == 'timeline') {
                    scheduler.setCurrentView(
                      new Date(scheduler.getState().date.valueOf() - 86400000),
                      scheduler.getState().mode
                    )
                  }
                }}></div>
              <div class="dhx_cal_today_button">Aujourd'hui</div>
              <div class="dhx_cal_date"></div>
            </div>
            <div class="dhx_cal_header"></div>
            <div class="dhx_cal_data"></div>
          </div>
        </div>
        {wait && (
          <>
            <LoaderFixed />
          </>
        )}

        {format && (
          <>
            <FormatPdfLtp legende={legende} sousTitre={sousTitre} />
          </>
        )}
        <div class="modal" id="myModal">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
                <h4 class="modal-title" style={{ color: '#334155' }}>
                  Ajouter une zone
                </h4>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"></button>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  e.target.reset()
                }}>
                <div class="modal-body">
                  <div class="mb-3 mt-3">
                    <label for="email" class="form-label">
                      Nouvelle zone:
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      onChange={(e) => {
                        e.preventDefault()
                        setNewZone(e.target.value)
                      }}
                      required
                    />
                  </div>
                </div>

                <div class="modal-footer">
                  <button
                    type="submit"
                    class="btn"
                    data-bs-dismiss="modal"
                    onClick={(e) => {
                      addZone(
                        infoChantier.planning,
                        newZone,
                        infoUser.token,
                        zone.length
                      ).then((e) => {
                        setBools(!bools)
                        dispatch({ type: 'bool/modifier', payload: !bool })
                      })
                    }}
                    style={{
                      color: 'white',
                      backgroundColor: '#4F46E5',
                      borderRadius: '8px',
                    }}>
                    Enregistrer
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/*Modal pour la modification et suppression d'une zone*/}
        <UpdateZoneLtp
          zone={zone}
          setZone={setZone}
          bool={bool}
          dispatch={dispatch}
          UpdateZone={UpdateZone}
          DeleteZone={DeleteZone}
        />
        <div class="modal" id="myModal2">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
                <h4 class="modal-title" style={{ color: '#334155' }}>
                  Liste des destinataires
                </h4>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"></button>
              </div>
              <div
                class="modal-body cibleDestinataire"
                style={{ overflow: 'scroll !important' }}>
                <h5>Sélectionner les destinataires</h5>
                {/* <div className="d-flex flex-row-reverse">
                    <div className='form-check form-switch form-switch-primary mt-2' style={{position:'absolute',zIndex:'2000000',right:'125px'}}>
                    <input type="text" className='form-check-input' />
                    </div>                  
                  </div> */}
                <ReactTabulator
                  data={dataPresence}
                  columns={colonneDestinataire}
                  layout={'fitColumns'}
                  options={{
                    locale: true,
                    pagination: 'local',
                    paginationSize: 5,
                    langs: {
                      fr: {
                        pagination: {
                          first: 'Premier',
                          first_title: 'Première page',
                          last: 'Dernier',
                          last_title: 'Dernière page',
                          prev: 'Précédent',
                          prev_title: 'Page précédente',
                          next: 'Suivant',
                          next_title: 'Page suivante',
                          page_size: 'Taille de page',
                          page_title: 'Afficher la page',
                          all: 'Tous',
                        },
                      },
                    },
                  }}
                />
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn"
                  onClick={(e) => {
                    e.preventDefault()
                    const garde = document.querySelector(
                      '.pageGardeLastPlanner'
                    )
                    const corps = document.createElement('div')
                    corps.style =
                      'width:3000px;position:relative;z-index:222222222222222222222;background-color:white'
                    const navPlanner =
                      document.querySelector('.dhx_cal_navline')
                    const headPlanner =
                      document.querySelector('.dhx_cal_header')
                    const dataPlanner = document.querySelector(
                      '.dhx_timeline_table_wrapper'
                    )
                    dataPlanner.style =
                      'position:absolute;top:110px;left:0;width:140%;background-color:white'
                    corps.appendChild(navPlanner)
                    corps.appendChild(headPlanner)
                    corps.appendChild(dataPlanner)
                    shareEvent(
                      infoChantier.planning,
                      corps.innerHTML,
                      garde.innerHTML,
                      dataPresence,
                      infoUser.token,
                      infoChantier.planning
                    ).then((res) => {
                      alert("L'email a été envoyé avec succès.")
                      location.reload()
                    })
                  }}
                  data-bs-dismiss="modal"
                  style={{
                    color: 'white',
                    backgroundColor: '#4F46E5',
                    borderRadius: '8px',
                  }}>
                  Envoyer
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal" id="myModalListZone">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
                <h4 class="modal-title" style={{ color: '#334155' }}>
                  Sélectionnez les zones à afficher
                </h4>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"></button>
              </div>
              <div
                class="modal-body"
                style={{ maxHeight: '100%', overflowY: 'scroll' }}>
                <div className="wrapper">
                  <div
                    className="item"
                    style={{
                      borderLeft: '1px solid #CBD5E1',
                      borderTopLeftRadius: '8px',
                      borderBottomLeftRadius: '8px',
                    }}
                    onClick={(ev) => {
                      ev.preventDefault()
                      setItemPdf('zone')
                    }}>
                    <span
                      style={
                        itemPdf == 'zone'
                          ? {
                              color: '#F8FAFC',
                              fontSize: '16px',
                              fontWeight: '400',
                              backgroundColor: '#6366F1',
                              padding: '8px 24px',
                              borderRadius: '8px',
                              marginLeft: '10px',
                            }
                          : {
                              color: '#334155',
                              fontSize: '16px',
                              fontWeight: '400',
                              cursor: 'pointer',
                            }
                      }>
                      zones
                    </span>
                  </div>
                  <div
                    className="item"
                    style={{
                      borderRight: '1px solid #CBD5E1',
                      borderTopRightRadius: '8px',
                      borderBottomRightRadius: '8px',
                    }}
                    onClick={(ev) => {
                      ev.preventDefault()
                      setItemPdf('sousTitre')
                    }}>
                    <span
                      style={
                        itemPdf == 'sousTitre'
                          ? {
                              color: '#F8FAFC',
                              fontSize: '16px',
                              fontWeight: '400',
                              backgroundColor: '#6366F1',
                              padding: '8px 24px',
                              borderRadius: '8px',
                              marginRight: '10px',
                            }
                          : {
                              color: '#334155',
                              fontSize: '16px',
                              fontWeight: '400',
                              cursor: 'pointer',
                            }
                      }>
                      Sous titre
                    </span>
                  </div>
                </div>
                {itemPdf == 'zone' && (
                  <>
                    <div
                      className="container-fluid"
                      style={{ marginTop: '20px' }}>
                      {zone.map((el) => {
                        return (
                          <>
                            <div style={{ display: 'flex' }}>
                              <input
                                type="checkbox"
                                defaultChecked="true"
                                onChange={(ev) => {
                                  if (ev.target.checked) {
                                    setZonePdf([...zonePdf, el])
                                  } else {
                                    setZonePdf(
                                      zonePdf.filter((e) => e.id !== el.id)
                                    )
                                  }
                                }}
                              />
                              <span style={{ marginLeft: '20px' }}>
                                {el.label}
                              </span>
                            </div>{' '}
                            <br />
                          </>
                        )
                      })}
                    </div>
                  </>
                )}
                {itemPdf == 'sousTitre' && (
                  <>
                    <label htmlFor="" style={{ marginTop: '20px' }}>
                      Sous-titre
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(ev) => {
                        ev.preventDefault()
                        setSousTitre(ev.target.value)
                      }}
                      defaultValue={sousTitre}
                    />
                  </>
                )}
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn"
                  style={{
                    color: 'white',
                    backgroundColor: '#4F46E5',
                    borderRadius: '8px',
                  }}
                  onClick={(ev) => {
                    ev.preventDefault()
                    setWait(true)
                    printContent()
                  }}
                  data-bs-dismiss="modal">
                  Exporter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/** Modal d'assignement des taches */}
      <div
        class="modal "
        id="myModalAssigne"
        style={{ zIndex: '22222222222222222222222222' }}>
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Liste des tâches
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>
            <div class="modal-body">
              <ul class="nav nav-tabs" role="tablist">
                <li className="nav-item">
                  <a
                    class="nav-link active"
                    data-bs-toggle="tab"
                    href="#S-1"
                    role="tab"
                    aria-selected="true">
                    Semaine dernière
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    class="nav-link"
                    data-bs-toggle="tab"
                    href="#S"
                    role="tab"
                    aria-selected="true">
                    Semaine en cours
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    class="nav-link"
                    data-bs-toggle="tab"
                    href="#S1"
                    role="tab"
                    aria-selected="true">
                    Semaine prochaine
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    class="nav-link d-flex align-items-center"
                    data-bs-toggle="tab"
                    href="#Taches"
                    role="tab"
                    aria-selected="true">
                    Tâches assignées{' '}
                    <span
                      className="justify-content-center d-flex align-items-center bg-dark "
                      style={{
                        marginLeft: '2px',
                        color: 'white',
                        width: '25px',
                        height: '25px',
                        borderRadius: '50%',
                        fontSize: '10px',
                        fontWeight: 'bold',
                      }}>
                      {allTacheAssigner.length}
                    </span>
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div class="tab-pane p-3 active" id="S-1" role="tabpanel">
                  <div class="row">
                    <div className="container-fluid">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>Nom du tâche</th>
                            <th>date de début</th>
                            <th>date de fin</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tacheSd &&
                            tacheSd.map((el) => {
                              return (
                                <>
                                  <tr>
                                    <td>{el.id ? el.id : 'Non défini'}</td>
                                    <td>{el.text ? el.text : 'Non défini'}</td>
                                    <td>
                                      {el.start_date
                                        ? dateFr(el.start_date)
                                        : 'Non défini'}
                                    </td>
                                    <td>
                                      {el.end_date
                                        ? dateFr(el.end_date)
                                        : 'Non défini'}
                                    </td>
                                    <td>
                                      {' '}
                                      <button
                                        type="button"
                                        className={
                                          allTacheAssigner.find(
                                            (obj) => obj.id === el.id
                                          )
                                            ? 'btn btn-xs btn-dark'
                                            : 'btn btn-xs btn-success'
                                        }
                                        style={{
                                          fontSize: '10px',
                                          fontWeight: 'bold',
                                        }}
                                        onClick={(e) => {
                                          e.preventDefault()
                                          const objetExistant =
                                            allTacheAssigner.find(
                                              (obj) => obj.id === el.id
                                            )
                                          if (objetExistant) {
                                          } else {
                                            setAllTacheAssigner([
                                              ...allTacheAssigner,
                                              el,
                                            ])
                                          }
                                        }}>
                                        {allTacheAssigner.find(
                                          (obj) => obj.id === el.id
                                        )
                                          ? 'Assigné'
                                          : 'Assigner'}
                                      </button>
                                    </td>
                                  </tr>
                                </>
                              )
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div class="tab-pane p-3" id="S" role="tabpanel">
                  <div class="row">
                    <div className="container-fluid">
                      <table
                        class="table table-bordered"
                        style={{ maxWidth: '100%', overflow: 'scroll' }}>
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>Nom du tâche</th>
                            <th>date de début</th>
                            <th>date de fin</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tacheSc &&
                            tacheSc.map((el) => {
                              return (
                                <>
                                  <tr>
                                    <td>{el.id ? el.id : 'Non défini'}</td>
                                    <td>{el.text ? el.text : 'Non défini'}</td>
                                    <td>
                                      {el.start_date
                                        ? dateFr(el.start_date)
                                        : 'Non défini'}
                                    </td>
                                    <td>
                                      {el.end_date
                                        ? dateFr(el.end_date)
                                        : 'Non défini'}
                                    </td>
                                    <td>
                                      {' '}
                                      <button
                                        type="button"
                                        className={
                                          allTacheAssigner.find(
                                            (obj) => obj.id === el.id
                                          )
                                            ? 'btn btn-xs btn-dark'
                                            : 'btn btn-xs btn-success'
                                        }
                                        style={{
                                          fontSize: '10px',
                                          fontWeight: 'bold',
                                        }}
                                        onClick={(e) => {
                                          e.preventDefault()
                                          const objetExistant =
                                            allTacheAssigner.find(
                                              (obj) => obj.id === el.id
                                            )
                                          if (objetExistant) {
                                          } else {
                                            setAllTacheAssigner([
                                              ...allTacheAssigner,
                                              el,
                                            ])
                                          }
                                        }}>
                                        {allTacheAssigner.find(
                                          (obj) => obj.id === el.id
                                        )
                                          ? 'Assigné'
                                          : 'Assigner'}
                                      </button>
                                    </td>
                                  </tr>
                                </>
                              )
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div class="tab-pane p-3" id="S1" role="tabpanel">
                  <div class="row">
                    <div className="container-fluid">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>Nom du tâche</th>
                            <th>date de début</th>
                            <th>date de fin</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tacheSp &&
                            tacheSp.map((el) => {
                              return (
                                <>
                                  <tr>
                                    <td>{el.id ? el.id : 'Non défini'}</td>
                                    <td>{el.text ? el.text : 'Non défini'}</td>
                                    <td>
                                      {el.start_date
                                        ? dateFr(el.start_date)
                                        : 'Non défini'}
                                    </td>
                                    <td>
                                      {el.end_date
                                        ? dateFr(el.end_date)
                                        : 'Non défini'}
                                    </td>
                                    <td>
                                      {' '}
                                      <button
                                        type="button"
                                        className={
                                          allTacheAssigner.find(
                                            (obj) => obj.id === el.id
                                          )
                                            ? 'btn btn-xs btn-dark'
                                            : 'btn btn-xs btn-success'
                                        }
                                        style={{
                                          fontSize: '10px',
                                          fontWeight: 'bold',
                                        }}
                                        onClick={(e) => {
                                          e.preventDefault()
                                          const objetExistant =
                                            allTacheAssigner.find(
                                              (obj) => obj.id === el.id
                                            )
                                          if (objetExistant) {
                                          } else {
                                            setAllTacheAssigner([
                                              ...allTacheAssigner,
                                              el,
                                            ])
                                          }
                                        }}>
                                        {allTacheAssigner.find(
                                          (obj) => obj.id === el.id
                                        )
                                          ? 'Assigné'
                                          : 'Assigner'}
                                      </button>
                                    </td>
                                  </tr>
                                </>
                              )
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div class="tab-pane p-3" id="Taches" role="tabpanel">
                  <div class="row">
                    <div className="container-fluid">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>Nom du tâche</th>
                            <th>date de début</th>
                            <th>date de fin</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allTacheAssigner &&
                            allTacheAssigner.map((el) => {
                              return (
                                <>
                                  <tr>
                                    <td>{el.id ? el.id : 'Non défini'}</td>
                                    <td>{el.text ? el.text : 'Non défini'}</td>
                                    <td>
                                      {el.start_date
                                        ? dateFr(el.start_date)
                                        : 'Non défini'}
                                    </td>
                                    <td>
                                      {el.end_date
                                        ? dateFr(el.end_date)
                                        : 'Non défini'}
                                    </td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-xs btn-danger"
                                        style={{
                                          fontSize: '10px',
                                          fontWeight: 'bold',
                                        }}
                                        onClick={(e) => {
                                          e.preventDefault()
                                          setAllTacheAssigner(
                                            allTacheAssigner.filter(
                                              (res) => res.id != el.id
                                            )
                                          )
                                        }}>
                                        retirer
                                      </button>
                                    </td>
                                  </tr>
                                </>
                              )
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn"
                data-bs-dismiss="modal"
                style={{
                  color: 'white',
                  backgroundColor: '#4F46E5',
                  borderRadius: '8px',
                }}
                onClick={(e) => {
                  e.preventDefault()
                  scheduler.getEvent(idEvent).tachesAssigne = allTacheAssigner
                  scheduler.updateEvent(idEvent)
                  const ev = scheduler.getEvent(idEvent)
                  console.log(ev)
                  console.log(idEvent)
                  console.log(allTacheAssigner)
                }}>
                Assigner
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LastPlanner2
