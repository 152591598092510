import React, { useState, useEffect } from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import LoaderFixed from '../../../components/loader/LoaderFixed'
import { ReactTabulator } from 'react-tabulator'
import { useToasts } from 'react-toast-notifications'
import { Button, Upload } from 'antd'
import trash from '../../../assets/images/Kolos_logo/trash.png'
import {
  addDataCarte,
  updateData,
  delData,
  getAllData,
  getData,
} from '../../../helpers/suivi/suivi'
import ModalDocument from './component/ModalDocument'
import PageNotFound from '../../../components/404/PageNotFound'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { searchOptimizedData } from '../../../helpers/recherche/recherche'
//Profil de chaque entreprise
const ProfilIntervenant = () => {
  const { addToast } = useToasts()
  if (!localStorage.getItem('isLogin')) {
    return <PageNotFound />
  }
  const navigate = useNavigate()
  const [_id, setId] = useState(
    localStorage.getItem('_idIntervenant')
      ? localStorage.getItem('_idIntervenant')
      : ''
  )
  const [titreLot, setTitreLot] = useState(
    localStorage.getItem('titreLot') ? localStorage.getItem('titreLot') : ''
  )
  const [dataEngagement, setDataEngagement] = useState([])
  const [dataAdministratif, setDataAdministratif] = useState([])
  const [dataDocument, setDataDocument] = useState([])
  const [dataModalAction, setDataModalAction] = useState({})
  const [cibleAction, setCibleAction] = useState('')
  const [imageAction, setImageAction] = useState([])
  const [dataModalEngagement, setDataModalEngagement] = useState({})
  const [dataModalAdministration, setDataModalAdministration] = useState({})
  const [dataModalDocument, setDataModalDocument] = useState({})
  const [dataModalSuiviDoc, setDataModalSuiviDoc] = useState([])
  const [dataMaj,setDataMaj] = useState()
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const [wait, setWait] = useState(false)
  const [bool, setBool] = useState(false)
  const indiceDoc = [
    { value: 'A' },
    { value: 'B' },
    { value: 'C' },
    { value: 'D' },
    { value: 'E' },
    { value: 'F' },
    { value: 'G' },
    { value: 'H' },
    { value: 'I' },
    { value: 'J' },
    { value: 'K' },
    { value: 'L' },
    { value: 'M' },
    { value: 'N' },
    { value: 'O' },
    { value: 'P' },
    { value: 'Q' },
    { value: 'R' },
    { value: 'S' },
    { value: 'T' },
    { value: 'U' },
    { value: 'V' },
    { value: 'W' },
    { value: 'X' },
    { value: 'Y' },
    { value: 'Z' },
  ]
  const [item, setItem] = useState('engagement')
  const [carousel, setCarousel] = useState(true)
  const [intervenant, setIntervenant] = useState({})
  //Assigenment nouvelle donner action
  const assignDataAction = (item, data) => {
    switch (item) {
      case 'planningSo':
        setDataPlanningSo(
          dataPlanningSo.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
      case 'installationchantiers':
        setDataInstallation(
          dataInstallation.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
      case 'securites':
        setDataSecurite(
          dataSecurite.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
      case 'engagements':
        setDataEngagement(
          dataEngagement.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
    }
  }
  //Recupération information entreprise
  useEffect(() => {
    setId(localStorage.getItem('_idIntervenant'))
    setTitreLot(localStorage.getItem('titreLot'))
  }, [_id, titreLot])
  useEffect(() => {
    setWait(true)
    getAllData(
      `planning=${infoChantier.planning}`,
      `cartes/${localStorage.getItem('_idIntervenant')}`,
      infoUser.token
    )
      .then((res) => {
        console.log(res)
        if (res.data) {
          setIntervenant(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally((f) => {
        setWait(false)
      })
  }, [])
  useEffect(() => {
    getAllData(
      `planning=${infoChantier.planning}&carte=${localStorage.getItem(
        '_idIntervenant'
      )}`,
      `carteEngagement`,
      infoUser.token
    ).then((res) => {
      console.log(res);
      setDataEngagement(res.data)
    })
    getAllData(
      `planning=${infoChantier.planning}&carte=${localStorage.getItem(
        '_idIntervenant'
      )}`,
      `carteAdmin`,
      infoUser.token
    ).then((res) => {
      setDataAdministratif(res.data)
    })
    getAllData(
      `planning=${infoChantier.planning}&carte=${localStorage.getItem(
        '_idIntervenant'
      )}`,
      `carteDoc`,
      infoUser.token
    ).then((res) => {
      console.log(res.data)
      setDataDocument(res.data)
    }).finally((res)=>{
      setWait(false)
    })
  }, [_id, bool])
  //del Function
  const pop = (candidate, data) => {
    return data.filter((d) => d._id !== candidate._id)
  }

  const delEngagements = async (cell) => {
    try {
      await delData(
        cell.getData(),
        'carteEngagement',
        infoUser.token,
        infoChantier.planning
      )
      cell.getRow().delete()
    } catch (err) {
      addToast(err, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const delAdministratifs = async (cell) => {
    try {
      await delData(
        cell.getData(),
        'carteAdmin',
        infoUser.token,
        infoChantier.planning
      )
      cell.getRow().delete()
    } catch (err) {
      addToast(err, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const delDocuments = async (cell) => {
    try {
      await delData(
        cell.getData(),
        'carteDoc',
        infoUser.token,
        infoChantier.planning
      )
      cell.getRow().delete()
    } catch (err) {
      addToast(err, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  //Boutton de suppression
  const button = (delFunction) => (cell) => {
    const delIcons = document.createElement('img')
    delIcons.src = trash
    delIcons.style = 'width:24px;height:24px'

    //create delete button
    var delBt = document.createElement('button')
    delBt.style = 'padding:8px 10px; border:1px solid #CBD5E1 '
    delBt.type = 'button'
    delBt.appendChild(delIcons)
    delBt.classList.add('btn')
    delBt.addEventListener('click', function () {
      delFunction(cell)
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(delBt)
    return buttonHolder
  }

  //Modal document
  const modalAction = (value) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-download')
    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalAction')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      console.log(cell.getData())
      setCibleAction(value)
      setDataModalAction(cell.getData())
      setImageAction(null)
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)
    return buttonHolder
  }
  const modalDocument = (dellFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')
    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalDoc')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      console.log(cell.getData())
      setDataMaj(cell)
      setDataModalDocument(cell.getData())
      setDataModalSuiviDoc(
        cell.getData().suiviDocs ? cell.getData().suiviDocs : []
      )
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)
    return buttonHolder
  }
  const maj = (cell,info) => {
    console.log(cell.getValue())
    cell.setValue(info)
}

  //Modal Engagement
  const modalEngagement = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalEngagement')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      setDataMaj(cell)
      setDataModalEngagement(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }
  //Modal Administratif
  const modalAdministratif = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalAdministratif')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      setDataMaj(cell)
      setDataModalAdministration(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }

  //Create date picker
  const date = (cell) => {
    const view = document.createElement('input')
    view.type = 'date'
    view.style = 'background-color:rgba(226, 232, 240, 0.4);padding:11px 22px'
    view.defaultValue = cell.getValue()?.split('T')[0]
    view.addEventListener('change', function (e) {
      cell.setValue(e.target.value)
    })
    view.classList.add('form-control')
    const container = document.createElement('span')
    container.appendChild(view)
    return container
  }
  //Create date picker
  const datePM = (cell) => {
    const data = cell.getData()
    const view = document.createElement('input')
    view.type = 'date'
    view.style = 'background-color:rgba(226, 232, 240, 0.4);padding:11px 22px'
    view.defaultValue = cell.getValue()?.split('T')[0]
    if (data.pm) {
      view.disabled = true
    }
    view.addEventListener('change', function (e) {
      cell.setValue(e.target.value)
    })
    view.classList.add('form-control')
    const container = document.createElement('span')
    container.appendChild(view)
    return container
  }

  const inputUrgence = (cell) => {
    const ul = document.createElement('ul')
    ul.className = 'dropdown-menu'
    const option1 = document.createElement('li')
    option1.className = 'dropdown-item'
    option1.innerText = 'Faible'
    option1.addEventListener('click', function (e) {
      e.preventDefault()
      cell.setValue(1)
    })
    const option2 = document.createElement('li')
    option2.className = 'dropdown-item'
    option2.innerText = 'Important'
    option2.addEventListener('click', function (e) {
      e.preventDefault()
      cell.setValue(2)
    })
    const option3 = document.createElement('li')
    option3.className = 'dropdown-item'
    option3.innerText = 'Primordial'
    option3.addEventListener('click', function (e) {
      e.preventDefault()
      cell.setValue(3)
    })
    ul.appendChild(option1)
    ul.appendChild(option2)
    ul.appendChild(option3)
    const btn = document.createElement('button')
    btn.className = 'btn'
    btn.type = 'button'
    btn.addEventListener('click', function (e) {
      e.preventDefault()
    })
    btn.setAttribute('data-bs-toggle', 'dropdown')
    const span = document.createElement('span')
    const icon = document.createElement('i')
    icon.className = 'ti ti-selector'
    if (cell.getValue() == 1) {
      btn.style =
        'color:#0D9488;background-color:rgba(45, 212, 191, 0.15);width:150px;display:flex;justify-content:space-between'
      span.innerText = 'Faible'
    }
    if (cell.getValue() == 2) {
      btn.style =
        'color:#F59E0B;background-color:#FEF3C7;width:150px;display:flex;justify-content:space-between'
      span.innerText = 'Important'
    }
    if (cell.getValue() == 3) {
      btn.style =
        'color:#FB7185;background-color:#FFF1F2;width:150px;display:flex;justify-content:space-between'
      span.innerText = 'Primordial'
    }
    if (!cell.getValue()) {
      btn.style =
        'color:#0D9488;background-color:rgba(45, 212, 191, 0.15);width:150px;display:flex;justify-content:space-between'
      span.innerText = 'Faible'
    }
    btn.appendChild(span)
    btn.appendChild(icon)
    const container = document.createElement('div')
    container.className = 'dropdown dropend'
    container.style = 'position:absolute;z-index:999'
    container.appendChild(btn)
    container.appendChild(ul)
    return container
  }
  const simpleInput = (cell) => {
    const input = document.createElement('input')
    input.type = 'text'
    input.style =
      'width:100%;border:none;padding:11px 22px;font-size:16px;font-weight:600;color:#334155'
    input.defaultValue = cell.getValue()
    input.disabled = cell.getField()=="visa" ? true : false
    input.addEventListener('blur', function (ev) {
      ev.preventDefault()
      cell.setValue(ev.target.value)
    })
    const container = document.createElement('div')
    container.style = 'display:flex;align-items:center;width:100%'
    container.appendChild(input)
    return container
  }
  const checkbox = (cell) => {
    const data = cell.getData()
    const input = document.createElement('input')
    input.type = 'checkbox'
    input.className = 'form-check-input'
    input.defaultChecked = cell.getValue()
    input.addEventListener('change', function (e) {
      cell.setValue(!cell.getValue())
      if (cell.getValue()) {
        console.log('ok')
        if (
          (data.prevuLe == undefined) |
          (data.prevuLe == '') |
          (data.prevuLe == null)
        ) {
          data.prevuLe = new Date(Date.now()).toISOString()
        }
        if (
          (data.faitLe == undefined) |
          (data.faitLe == '') |
          (data.faitLe == null)
        ) {
          data.faitLe = new Date(Date.now()).toISOString()
        }
        console.log(data)
      } else {
      }
    })
    const view = document.createElement('div')
    view.className = 'form-check form-switch mt-2'
    view.style = 'color:#2DD4BF;margin-left:12px'
    view.appendChild(input)
    return view
  }
  const getItem = (value) => {
    return value
  }

  const colonneEngagement = [
    { title: 'Engagements', field: 'titre', formatter: simpleInput },
    { title: 'Noté Le', field: 'noteLe', formatter: date },
    { title: 'Prévu Le', field: 'prevuLe', formatter: date },
    { title: 'Fait Le', field: 'faitLe', formatter: date },
    {
      title: 'Urgence',
      minWidth: 170,
      field: 'urgence',
      formatter: inputUrgence,
    },
    { title: 'PM', field: 'pm', width: 100, formatter: checkbox },
    { title: 'DQP', field: 'dqp', width: 100, formatter: checkbox },
    { title: 'Tenue', field: 'statut', width: 100, formatter: checkbox },
    { title: 'Details', field:'observation',width: 100, formatter: modalEngagement() },
    {
      title: 'Action',
      width: 100,
      formatter: modalAction(getItem('engagements')),
    },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delEngagements),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    },
  ]
  const colonneAdministratif = [
    { title: 'Titre', field: 'titre', formatter: simpleInput },
    { title: 'Noté Le', field: 'noteLe', formatter: date },
    { title: 'Prévu Le', field: 'prevuLe', formatter: date },
    { title: 'Fait Le', field: 'faitLe', formatter: date },
    { title: 'Statut', field: 'statut', width: 100, formatter: checkbox },
    { title: 'Details',field:'observation', width: 100, formatter: modalAdministratif() },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delAdministratifs),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    },
  ]
  const colonneDocument = [
    { title: 'Titre', field: 'titre', formatter: simpleInput },
    { title: 'Phase', field: 'phase', formatter: simpleInput },
    { title: 'Type', field: 'type', formatter: simpleInput },
    { title: 'Niveau', field: 'niveau', formatter: simpleInput },
    { title: 'Zone', field: 'zone', formatter: simpleInput },
    { title: 'Indice', field: 'indice', formatter: simpleInput },
    { title: 'Date BPE', field: 'dateBPE', formatter: date },
    { title: 'Prévu Le', field: 'prevision', formatter: date },
    { title: 'Diffusé Le', field: 'diffusion', formatter: date },
    { title: 'Visa', field: 'visa', formatter: simpleInput },
    { title: 'Statut', field: 'statut', width: 100, formatter: checkbox },
    {
      title: 'Details',
      field: 'functionButton',
      formatter: modalDocument(),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delDocuments),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    },
  ]

  const rowDataCandidate = {
    titre: 'New',
    noteLe: Date.now(),
    entreprise: _id,
    planning: infoChantier.planning,
  }
  const engagementEdited = async (cell) => {
    const data = cell.getData()
    console.log(cell.getData())
    if (cell.getField() == 'faitLe') {
      if (
        (data.faitLe == '') |
        (data.faitLe == undefined) |
        (data.faitLe == null)
      ) {
        data.statut = false
        updateData(
          data,
          `carteEngagement`,
          infoUser.token,
          infoChantier.planning
        )
        cell.getRow().update(data)
      } else {
        data.statut = true
        updateData(
          data,
          `carteEngagement`,
          infoUser.token,
          infoChantier.planning
        )
        cell.getRow().update(data)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.faitLe == '') |
            (data.faitLe == undefined) |
            (data.faitLe == null)
          ) {
            data.faitLe = new Date(Date.now()).toISOString()
            updateData(
              data,
              `carteEngagement`,
              infoUser.token,
              infoChantier.planning
            )
            cell.getRow().update(data)
          }
        } else {
          data.faitLe = ''
          updateData(
            data,
            `carteEngagement`,
            infoUser.token,
            infoChantier.planning
          )
          cell.getRow().update(data)
        }
      } else {
        updateData(
          data,
          `carteEngagement`,
          infoUser.token,
          infoChantier.planning
        )
          .then((res) => {
            console.log(res);
            if(res.data){
              cell.getRow().update(res.data)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }

  const administratifEdited = async (cell) => {
    const data = cell.getData()
    console.log(cell.getData())
    if (cell.getField() == 'faitLe') {
      if (
        (data.faitLe == '') |
        (data.faitLe == undefined) |
        (data.faitLe == null)
      ) {
        data.statut = false
        updateData(data, `carteAdmin`, infoUser.token, infoChantier.planning)
        cell.getRow().update(data)
      } else {
        data.statut = true
        updateData(data, `carteAdmin`, infoUser.token, infoChantier.planning)
        cell.getRow().update(data)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.faitLe == '') |
            (data.faitLe == undefined) |
            (data.faitLe == null)
          ) {
            data.faitLe = new Date(Date.now()).toISOString()
            updateData(
              data,
              `carteAdmin`,
              infoUser.token,
              infoChantier.planning
            )
            cell.getRow().update(data)
          }
        } else {
          data.faitLe = ''
          updateData(data, `carteAdmin`, infoUser.token, infoChantier.planning)
          cell.getRow().update(data)
        }
      } else {
        updateData(data, 'carteAdmin', infoUser.token, infoChantier.planning)
      }
    }
  }
  const documentEdited = async (cell) => {
    const data = cell.getData()
    console.log(cell.getData())
    if (cell.getField() == 'diffusion') {
      if (
        (data.diffusion == '') |
        (data.diffusion == undefined) |
        (data.diffusion == null)
      ) {
        data.statut = false
        updateData(
          data,
          `carteDoc`,
          infoUser.token,
          infoChantier.planning
        ).then((e) => {})
        cell.getRow().update(data)
      } else {
        data.statut = true
        updateData(data, `carteDoc`, infoUser.token, infoChantier.planning)
        cell.getRow().update(data)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.diffusion == '') |
            (data.diffusion == undefined) |
            (data.diffusion == null)
          ) {
            data.diffusion = new Date(Date.now()).toISOString()
            updateData(data, `carteDoc`, infoUser.token, infoChantier.planning)
            cell.getRow().update(data)
          }
        } else {
          data.diffusion = ''
          updateData(data, `carteDoc`, infoUser.token, infoChantier.planning)
          cell.getRow().update(data)
        }
      } else {
        updateData(data, `carteDoc`, infoUser.token, infoChantier.planning)
      }
    }
  }

  const addEngagementRow = async (e) => {
    e.preventDefault()
    setWait(true)
    const result = await addDataCarte(
      rowDataCandidate,
      'carteEngagement',
      _id,
      infoUser.token
    ).then((res) => {
      setBool(!bool)
      return res
    })
  }
  const addAdministratifRow = async (e) => {
    e.preventDefault()
    setWait(true)
    const result = await addDataCarte(
      rowDataCandidate,
      'carteAdmin',
      _id,
      infoUser.token
    ).then((e) => {
      setBool(!bool)
      console.log(e)
      return e
    })
  }
  const addDocumentRow = async (e) => {
    e.preventDefault()
    setWait(true)
    await addDataCarte({...rowDataCandidate,typeDoc:2}, 'carteDoc', _id, infoUser.token).then(
      (e) => {
        console.log(e)
        if (e.name == 'AxiosError' || e.message == 'erreur') {
          setWait(false)
          addToast("Une erreur s'est produit.", {
            appearance: 'error',
            autoDismiss: true,
          })
        } else {
          setBool(!bool)
        }
      }
    )
  }
  //fonction pour le nav bar scroll horizontal
  const right = () => {
    const element = document.querySelector('.wrapper')
    console.log(element.scrollLeft)
    const item = document.querySelector('.item')
    element.scrollLeft = element.scrollLeft + item.clientWidth + 10
  }

  const left = () => {
    const element = document.querySelector('.wrapper')
    const item = document.querySelector('.item')
    element.scrollLeft = element.scrollLeft - item.clientWidth + 10
  }

  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={true} active="suivi" />
        <Header bool={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div class="page-title-box">
              <div class="float-end"></div>
              <span style={{ marginLeft: '10px' }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="#94A3B8"
                  style={{ width: '16px', height: '16px' }}>
                  <path
                    fillRule="evenodd"
                    d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                    clipRule="evenodd"
                  />
                </svg>{' '}
                <span
                  style={{
                    color: '#94A3B8',
                    fontSize: '14px',
                    fontWeight: '400',
                  }}>
                  / Suivi opérationnel
                </span>
                <span
                  style={{
                    color: '#334155',
                    fontSize: '14px',
                    fontWeight: '600',
                  }}>
                  {' '}
                  / Profil
                </span>
              </span>{' '}
              <br />
              <span
                style={{
                  marginLeft: '15px',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <span
                  style={{
                    backgroundColor: '#E2E8F0',
                    padding: '2px',
                    borderRadius: '6px',
                  }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="#6366F1"
                    style={{
                      width: '20px',
                      height: '20px',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      navigate('/SuiviOperationnel')
                    }}>
                    <path
                      fillRule="evenodd"
                      d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
                <span
                  style={{
                    color: '#334155',
                    fontSize: '20px',
                    fontWeight: '600',
                    marginLeft: '10px',
                  }}>
                  {' '}
                  Profil
                </span>
              </span>
            </div>
            {/*debut profil */}
            <div style={{ width: '100%' }}>
              <div style={{ width: '100%' }}>
                <div className="row">
                  <div className="col-lg-6" style={{ marginTop: '20px' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        padding: '20px 20px',
                        backgroundColor: '#FFFFFF',
                        boxShadow:
                          '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                        borderRadius: '8px',
                        height: '166px',
                        border: '1px solid #E2E8F0',
                      }}>
                      <span
                        style={{
                          color: ' #334155',
                          fontSize: '20px',
                          fontWeight: '600',
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}>
                        <span
                          style={{
                            width: '70%',
                            display: 'flex',
                            flexWrap: 'nowrap',
                            overflow: 'hidden',
                          }}>
                          {intervenant.titre ? intervenant.titre : 'Non défini'}
                        </span>
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="#818CF8"
                            style={{
                              width: '20px',
                              height: '20px',
                              marginRight: '5px',
                            }}>
                            <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                            <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                          </svg>
                          <span
                            style={{
                              color: '#64748B',
                              fontSize: '14px',
                              fontWeight: '600',
                            }}>
                            Envoi courrier (Bientôt)
                          </span>
                        </span>
                      </span>
                      <span
                        style={{
                          color: '#6366F1',
                          fontSize: '14px',
                          fontWeight: '400',
                        }}>
                        Société :{' '}
                        {intervenant.responsable?.entrepriseName
                          ? intervenant.responsable.entrepriseName
                          : 'Non défini'}
                      </span>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: '0px',
                          alignItems: 'flex-start',
                          marginTop: '24px',
                        }}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}>
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="#818CF8"
                              style={{
                                width: '20px',
                                height: '20px',
                                marginRight: '5px',
                              }}>
                              <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" />
                            </svg>
                          </span>
                          <div>
                            <span
                              style={{
                                color: '#1E293B',
                                fontSize: '14px',
                                fontWeight: '600',
                              }}>
                              {intervenant.responsable?.name
                                ? intervenant.responsable.name
                                : 'Non défini'}{' '}
                              {intervenant.responsable?.lastname &&
                                intervenant.responsable.lastname}
                            </span>{' '}
                            <br />
                            <span
                              style={{
                                color: '#64748B',
                                fontSize: '14px',
                                fontWeight: '400',
                              }}>
                              Responsable
                            </span>
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="#818CF8"
                              style={{
                                width: '20px',
                                height: '20px',
                                marginRight: '5px',
                              }}>
                              <path
                                fillRule="evenodd"
                                d="M2 3.5A1.5 1.5 0 013.5 2h1.148a1.5 1.5 0 011.465 1.175l.716 3.223a1.5 1.5 0 01-1.052 1.767l-.933.267c-.41.117-.643.555-.48.95a11.542 11.542 0 006.254 6.254c.395.163.833-.07.95-.48l.267-.933a1.5 1.5 0 011.767-1.052l3.223.716A1.5 1.5 0 0118 15.352V16.5a1.5 1.5 0 01-1.5 1.5H15c-1.149 0-2.263-.15-3.326-.43A13.022 13.022 0 012.43 8.326 13.019 13.019 0 012 5V3.5z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                          <div>
                            <span
                              style={{
                                color: '#1E293B',
                                fontSize: '14px',
                                fontWeight: '600',
                              }}>
                              {intervenant.responsable?.telephone
                                ? intervenant.responsable.telephone
                                : 'Non défini'}
                            </span>{' '}
                            <br />
                            <span
                              style={{
                                color: '#64748B',
                                fontSize: '14px',
                                fontWeight: '400',
                              }}>
                              Téléphone
                            </span>
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="#818CF8"
                              style={{
                                width: '20px',
                                height: '20px',
                                marginRight: '5px',
                              }}>
                              <path
                                fillRule="evenodd"
                                d="M17.834 6.166a8.25 8.25 0 100 11.668.75.75 0 011.06 1.06c-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788 3.807-3.808 9.98-3.808 13.788 0A9.722 9.722 0 0121.75 12c0 .975-.296 1.887-.809 2.571-.514.685-1.28 1.179-2.191 1.179-.904 0-1.666-.487-2.18-1.164a5.25 5.25 0 11-.82-6.26V8.25a.75.75 0 011.5 0V12c0 .682.208 1.27.509 1.671.3.401.659.579.991.579.332 0 .69-.178.991-.579.3-.4.509-.99.509-1.671a8.222 8.222 0 00-2.416-5.834zM15.75 12a3.75 3.75 0 10-7.5 0 3.75 3.75 0 007.5 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                          <div>
                            <span
                              style={{
                                color: '#1E293B',
                                fontSize: '14px',
                                fontWeight: '600',
                              }}>
                              {intervenant.responsable?.email
                                ? intervenant.responsable.email
                                : 'Non défini'}
                            </span>{' '}
                            <br />
                            <span
                              style={{
                                color: '#64748B',
                                fontSize: '14px',
                                fontWeight: '400',
                              }}>
                              Email
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6" style={{ marginTop: '20px' }}>
                    {/**Carousel */}
                    <div
                      style={{
                        width: '100%',
                        border: '1px solid #E2E8F0',
                        boxShadow:
                          '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                        borderRadius: '8px',
                        height: '166px',
                        backgroundColor: '#FFFFFF',
                        padding: '19px 42px 19px 24px ',
                        display: 'flex',
                        position: 'relative',
                      }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}>
                        <span style={{ width: '45%' }}>
                          <span
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginTop: '10px',
                            }}>
                            <span
                              style={{
                                fontSize: '14px',
                                fontWeight: '400',
                                color: '#1E293B',
                              }}>
                              Engagements
                            </span>
                            <span style={{ color: '#E11D48' }}>
                              {intervenant.progressDetail?.globalProgressDetails
                                ?.pourcentage?.cEngagement
                                ? intervenant.progressDetail.globalProgressDetails.pourcentage.cEngagement.toFixed(
                                    0
                                  )
                                : 0}
                              %
                            </span>
                          </span>
                          <div
                            style={{
                              width: '100%',
                              position: 'relative',
                              height: '7px',
                              borderRadius: '4px',
                              backgroundColor: 'rgba(251, 113, 133, 0.3)',
                            }}>
                            <span
                              style={{
                                position: 'absolute',
                                left: '0',
                                top: '0',
                                bottom: '0',
                                backgroundColor: '#E11D48',
                                width: `${
                                  intervenant.progressDetail
                                    ?.globalProgressDetails?.pourcentage
                                    ?.cEngagement
                                    ? intervenant.progressDetail
                                        .globalProgressDetails.pourcentage
                                        .cEngagement
                                    : 0
                                }%`,
                                borderRadius: '4px',
                              }}></span>
                          </div>
                          <span
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginTop: '10px',
                            }}>
                            <span
                              style={{
                                fontSize: '14px',
                                fontWeight: '400',
                                color: '#1E293B',
                              }}>
                              Administratifs
                            </span>
                            <span style={{ color: '#F59E0B' }}>
                              {intervenant.progressDetail?.globalProgressDetails
                                ?.pourcentage?.cAdministratif
                                ? intervenant.progressDetail.globalProgressDetails.pourcentage.cAdministratif.toFixed(
                                    0
                                  )
                                : 0}
                            </span>
                          </span>
                          <div
                            style={{
                              width: '100%',
                              position: 'relative',
                              height: '7px',
                              borderRadius: '4px',
                              backgroundColor: '#FEF3C7',
                            }}>
                            <span
                              style={{
                                position: 'absolute',
                                left: '0',
                                top: '0',
                                bottom: '0',
                                backgroundColor: '#F59E0B',
                                width: `${
                                  intervenant.progressDetail
                                    ?.globalProgressDetails?.pourcentage
                                    ?.cAdministratif
                                    ? intervenant.progressDetail
                                        .globalProgressDetails.pourcentage
                                        .cAdministratif
                                    : 0
                                }%`,
                                borderRadius: '4px',
                              }}></span>
                          </div>
                        </span>
                        <span style={{ width: '45%', paddingTop: '10px' }}>
                          <span
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}>
                            <span
                              style={{
                                fontSize: '14px',
                                fontWeight: '400',
                                color: '#1E293B',
                              }}>
                              Documents
                            </span>
                            <span style={{ color: '#0D9488' }}>
                              {intervenant.progressDetail?.globalProgressDetails
                                ?.pourcentage?.cDocument
                                ? intervenant.progressDetail.globalProgressDetails.pourcentage.cDocument.toFixed(
                                    0
                                  )
                                : 0}
                            </span>
                          </span>
                          <div
                            style={{
                              width: '100%',
                              position: 'relative',
                              height: '7px',
                              borderRadius: '4px',
                              backgroundColor: 'rgba(45, 212, 191, 0.2)',
                            }}>
                            <span
                              style={{
                                position: 'absolute',
                                left: '0',
                                top: '0',
                                bottom: '0',
                                backgroundColor: '#2DD4BF',
                                width: `${
                                  intervenant.progressDetail
                                    ?.globalProgressDetails?.pourcentage
                                    ?.cDocument
                                    ? intervenant.progressDetail
                                        .globalProgressDetails.pourcentage
                                        .cDocument
                                    : 0
                                }%`,
                                borderRadius: '4px',
                              }}></span>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div className="col-12" style={{ marginTop: '32px' }}>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    width: '100%',
                  }}>
                  <button
                    className="btn"
                    style={{
                      width: '10px',
                      height: '52px',
                      backgroundColor: 'rgba(226, 232, 240, 0.6)',
                      borderRadius: '0px',
                      borderTopLeftRadius: '8px',
                      borderBottomLeftRadius: '8px',
                      borderTop: '1px solid #CBD5E1',
                      borderBottom: '1px solid #CBD5E1',
                      borderLeft: '1px solid #CBD5E1',
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      left()
                    }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      style={{ width: '20px', height: '20px' }}>
                      <path
                        fillRule="evenodd"
                        d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                  <div className="wrapper">
                    <div
                      className="item"
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setItem('engagement')
                      }}>
                      <span
                        style={
                          item == 'engagement'
                            ? {
                                color: '#F8FAFC',
                                fontSize: '16px',
                                fontWeight: '400',
                                backgroundColor: '#6366F1',
                                padding: '8px 24px',
                                borderRadius: '8px',
                              }
                            : {
                                color: '#334155',
                                fontSize: '16px',
                                fontWeight: '400',
                                cursor: 'pointer',
                              }
                        }>
                        Engagements
                      </span>
                    </div>
                    <div
                      className="item"
                      onClick={(e) => {
                        e.preventDefault()
                        setItem('administratif')
                      }}>
                      <span
                        style={
                          item == 'administratif'
                            ? {
                                color: '#F8FAFC',
                                fontSize: '16px',
                                fontWeight: '400',
                                backgroundColor: '#6366F1',
                                padding: '8px 24px',
                                borderRadius: '8px',
                              }
                            : {
                                color: '#334155',
                                fontSize: '16px',
                                fontWeight: '400',
                                cursor: 'pointer',
                              }
                        }>
                        Administratifs
                      </span>
                    </div>
                    <div
                      className="item"
                      onClick={(e) => {
                        e.preventDefault()
                        setItem('document')
                      }}>
                      <span
                        style={
                          item == 'document'
                            ? {
                                color: '#F8FAFC',
                                fontSize: '16px',
                                fontWeight: '400',
                                backgroundColor: '#6366F1',
                                padding: '8px 24px',
                                borderRadius: '8px',
                              }
                            : {
                                color: '#334155',
                                fontSize: '16px',
                                fontWeight: '400',
                                cursor: 'pointer',
                              }
                        }>
                        Documents
                      </span>
                    </div>
                  </div>

                  <button
                    className="btn"
                    style={{
                      width: '10px',
                      height: '52px',
                      backgroundColor: 'rgba(226, 232, 240, 0.6)',
                      borderRadius: '0px',
                      borderTopRightRadius: '8px',
                      borderBottomRightRadius: '8px',
                      textAlign: 'center',
                      position: 'relative',
                      borderTop: '1px solid #CBD5E1',
                      borderBottom: '1px solid #CBD5E1',
                      borderRight: '1px solid #CBD5E1',
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      right()
                    }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      style={{
                        width: '20px',
                        height: '20px',
                        position: 'absolute',
                        left: '0',
                        top: '17',
                      }}>
                      <path
                        fillRule="evenodd"
                        d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="row" style={{ padding: '10px 10px' }}>
              <div
                className="col-12"
                style={{
                  backgroundColor: '#FFFFFF',
                  borderRadius: '8px',
                  boxShadow:
                    '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                  padding: '16px 0px 24px',
                }}>
                {/** Tableau Engagement */}
                {item == 'engagement' && (
                  <>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0px 0px 0px 40px',
                      }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span
                          style={{
                            color: '#334155',
                            fontSize: '20px',
                            fontWeight: '600',
                          }}>
                          Engagements
                        </span>
                        <span
                          style={{
                            backgroundColor: '#E0E7FF',
                            borderRadius: '24px',
                            padding: '6px 12px',
                            color: '#64748B',
                            fontSize: '14px',
                            fontWeight: '600',
                            marginLeft: '8px',
                            maxHeight: '32px',
                            width: 'auto',
                          }}>
                          {dataEngagement.length > 0
                            ? dataEngagement.length
                            : '0'}{' '}
                          engagements
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            padding: '8px 12px 8px 12px',
                            backgroundColor: 'rgba(226, 232, 240, 0.6)',
                            borderRadius: '8px',
                            height: '40px',
                          }}>
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="#818CF8"
                              style={{ width: '18px', height: '18px' }}>
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                              />
                            </svg>
                          </span>
                          <input
                            type="text"
                            placeholder="Recherche"
                            style={{
                              border: 'none',
                              backgroundColor: 'transparent',
                            }}
                            onChange={(ev)=>{
                              ev.preventDefault()
                              ev.stopPropagation()
                              const newData = searchOptimizedData(ev.target.value,dataEngagement)
                              setDataEngagement(newData)
                            }}
                          />
                        </span>
                        <button
                          className="btn"
                          style={{
                            backgroundColor: '#4F46E5',
                            color: '#F8FAFC',
                            marginLeft: '24px',
                          }}
                          onClick={(e) => addEngagementRow(e)}>
                          + Nouvelle tâche
                        </button>
                      </div>
                    </div>
                    <div
                      className="cibleTabulatorCr"
                      style={{
                        width: '100%',
                        borderTop: 'solid #E2E8F0 1px',
                        marginTop: '16px',
                      }}>
                      <ReactTabulator
                        data={dataEngagement}
                        columns={colonneEngagement}
                        layout={'fitColumns'}
                        events={{ cellEdited: engagementEdited }}
                        options={{
                          locale: true,
                          pagination: 'local',
                          paginationSize: 5,
                          langs: {
                            fr: {
                              pagination: {
                                first: 'Premier',
                                first_title: 'Première',
                                last: 'Dernier',
                                last_title: 'Dernière',
                                prev: 'Précédent',
                                prev_title: 'Précédente',
                                next: 'Suivant',
                                next_title: 'Page suivante',
                                page_size: 'Taille de page',
                                page_title: 'Afficher la page',
                                all: 'Tous',
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </>
                )}
                {/** Tableau Administratif */}
                {item == 'administratif' && (
                  <>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0px 0px 0px 40px',
                      }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span
                          style={{
                            color: '#334155',
                            fontSize: '20px',
                            fontWeight: '600',
                          }}>
                          Administratifs
                        </span>
                        <span
                          style={{
                            backgroundColor: '#E0E7FF',
                            borderRadius: '24px',
                            padding: '6px 12px',
                            color: '#64748B',
                            fontSize: '14px',
                            fontWeight: '600',
                            marginLeft: '8px',
                            maxHeight: '32px',
                            width: 'auto',
                          }}>
                          {dataAdministratif.length > 0
                            ? dataAdministratif.length
                            : '0'}{' '}
                          Administratifs
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            padding: '8px 12px 8px 12px',
                            backgroundColor: 'rgba(226, 232, 240, 0.6)',
                            borderRadius: '8px',
                            height: '40px',
                          }}>
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="#818CF8"
                              style={{ width: '18px', height: '18px' }}>
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                              />
                            </svg>
                          </span>
                          <input
                            type="text"
                            placeholder="Recherche"
                            style={{
                              border: 'none',
                              backgroundColor: 'transparent',
                            }}
                            onChange={(ev)=>{
                              ev.preventDefault()
                              ev.stopPropagation()
                              const newData = searchOptimizedData(ev.target.value,dataAdministratif)
                              setDataAdministratif(newData)
                            }}
                          />
                        </span>
                        <button
                          className="btn"
                          style={{
                            backgroundColor: '#4F46E5',
                            color: '#F8FAFC',
                            marginLeft: '24px',
                          }}
                          onClick={(e) => addAdministratifRow(e)}>
                          + Nouvelle tâche
                        </button>
                      </div>
                    </div>
                    <div
                      style={{
                        width: '100%',
                        borderTop: 'solid #E2E8F0 1px',
                        marginTop: '16px',
                      }}>
                      <ReactTabulator
                        data={dataAdministratif}
                        columns={colonneAdministratif}
                        layout={'fitColumns'}
                        events={{ cellEdited: administratifEdited }}
                        options={{
                          locale: true,
                          pagination: 'local',
                          paginationSize: 5,
                          langs: {
                            fr: {
                              pagination: {
                                first: 'Premier',
                                first_title: 'Première',
                                last: 'Dernier',
                                last_title: 'Dernière',
                                prev: 'Précédent',
                                prev_title: 'Précédente',
                                next: 'Suivant',
                                next_title: 'Page suivante',
                                page_size: 'Taille de page',
                                page_title: 'Afficher la page',
                                all: 'Tous',
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </>
                )}
                {/** Tableau Documents */}
                {item == 'document' && (
                  <>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0px 0px 0px 40px',
                      }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span
                          style={{
                            color: '#334155',
                            fontSize: '20px',
                            fontWeight: '600',
                          }}>
                          Documents
                        </span>
                        <span
                          style={{
                            backgroundColor: '#E0E7FF',
                            borderRadius: '24px',
                            padding: '6px 12px',
                            color: '#64748B',
                            fontSize: '14px',
                            fontWeight: '600',
                            marginLeft: '8px',
                            maxHeight: '32px',
                            width: 'auto',
                          }}>
                          {dataDocument.length > 0 ? dataDocument.length : '0'}{' '}
                          Documents
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            padding: '8px 12px 8px 12px',
                            backgroundColor: 'rgba(226, 232, 240, 0.6)',
                            borderRadius: '8px',
                            height: '40px',
                          }}>
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="#818CF8"
                              style={{ width: '18px', height: '18px' }}>
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                              />
                            </svg>
                          </span>
                          <input
                            type="text"
                            placeholder="Recherche"
                            style={{
                              border: 'none',
                              backgroundColor: 'transparent',
                            }}
                            onChange={(ev)=>{
                              ev.preventDefault()
                              ev.stopPropagation()
                              const newData = searchOptimizedData(ev.target.value,dataDocument)
                              setDataDocument(newData)
                            }}
                          />
                        </span>
                        <button
                          className="btn"
                          style={{
                            backgroundColor: '#4F46E5',
                            color: '#F8FAFC',
                            marginLeft: '24px',
                          }}
                          onClick={(e) => addDocumentRow(e)}>
                          + Nouvelle tâche
                        </button>
                      </div>
                    </div>
                    <div
                      style={{
                        width: '100%',
                        borderTop: 'solid #E2E8F0 1px',
                        marginTop: '16px',
                      }}>
                      <ReactTabulator
                        data={dataDocument}
                        columns={colonneDocument}
                        layout={'fitColumns'}
                        events={{ cellEdited: documentEdited }}
                        options={{
                          locale: true,
                          pagination: 'local',
                          paginationSize: 5,
                          langs: {
                            fr: {
                              pagination: {
                                first: 'Premier',
                                first_title: 'Première',
                                last: 'Dernier',
                                last_title: 'Dernière',
                                prev: 'Précédent',
                                prev_title: 'Précédente',
                                next: 'Suivant',
                                next_title: 'Page suivante',
                                page_size: 'Taille de page',
                                page_title: 'Afficher la page',
                                all: 'Tous',
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* Fin profil */}
          </div>
        </div>
      </div>
      {/* Modal Action */}
      <div class="modal fade" id="myModalAction">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Importer une photo
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                e.target.reset()
                addToast('Enregistrement en cours.', {
                  appearance: 'success',
                  autoDismiss: true,
                })
                const formData = new FormData()
                imageAction.map((item) => {
                  formData.append('item', item.originFileObj)
                })
                axios
                  .patch(
                    `${process.env.REACT_APP_URL}/api/v1/carteEngagement/${dataModalAction._id}?planning=${infoChantier.planning}`,
                    formData,
                    {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${infoUser.token}`,
                      },
                    }
                  )
                  .then((res) => {
                    addToast('Enregistrement avec succès.', {
                      appearance: 'success',
                      autoDismiss: true,
                    })
                    console.log(res)
                    if (res.data.data) {
                      console.log(res.data.data)
                      setDataEngagement(
                        dataEngagement.map((el) => {
                          if (el._id == res.data.data._id) {
                            return res.data.data
                          } else {
                            return el
                          }
                        })
                      )
                      setBool(!bool)
                    }
                  })
                  .catch((err) => {
                    console.log(err)
                    addToast("Une erreur s'est produit.", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  })
              }}>
              {' '}
              <div class="modal-body">
                {dataModalAction?.actionPhoto?.length < 4 ? (
                  <>
                    <div class="mb-3 mt-3">
                      <label for="comment">
                        (4 images max, Taille 10Mo max, type:".png,.jpeg,.jpg"):
                      </label>
                      <Upload.Dragger
                        multiple={true}
                        maxCount={4}
                        beforeUpload={(files) => {
                          return false
                        }}
                        showUploadList={{ showRemoveIcon: true }}
                        accept=".png,.jpeg,.jpg"
                        listType="picture"
                        onChange={(ev) => {
                          console.log(ev.fileList)
                          setImageAction(ev.fileList)
                        }}>
                        <Button>Parcourir Fichier</Button>
                      </Upload.Dragger>
                    </div>
                  </>
                ) : (
                  <>
                    <h4
                      class="modal-title"
                      style={{ color: '#334155', textAlign: 'center' }}>
                      Vous avez atteint la limite des photos à importer (4
                      images)
                    </h4>
                  </>
                )}
              </div>
              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn"
                  style={{
                    color: 'white',
                    backgroundColor: '#4F46E5',
                    borderRadius: '8px',
                  }}
                  data-bs-dismiss="modal"
                  disabled={
                    dataModalAction?.actionPhoto?.length < 4 ? false : true
                  }>
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal Engagement */}
      <div class="modal fade" id="myModalEngagement">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Engagement
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                const info = document.getElementById('modalEngagement').value
                updateData(
                  {
                    observation:
                      info,
                    _id: dataModalEngagement._id,
                  },
                  'carteEngagement',
                  infoUser.token,
                  infoChantier.planning
                ).then((res) => {
                  console.log(res)
                  if (res.data) {
                    maj(dataMaj,info)
                  } else {
                    addToast("Une erreur s'est produit", {
                      autoDismiss: true,
                    })
                  }
                })
              }}>
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    class="form-control"
                    rows="7"
                    value={
                      dataModalEngagement.observation
                        ? dataModalEngagement.observation
                        : ''
                    }
                    onChange={(e) => {
                      setDataModalEngagement({
                        ...dataModalEngagement,
                        observation: e.target.value,
                      })
                    }}
                    id="modalEngagement"
                    name="text"></textarea>
                </div>
                <div className="mb-3 mt-3">
                  {dataModalEngagement?.actionPhoto?.length > 0 && (
                    <>
                      <div
                        style={{
                          width: '100%',
                          padding: '12px 12px',
                          height: '300px',
                          overflowY: 'scroll',
                        }}>
                        {dataModalEngagement.actionPhoto.map((el) => {
                          return (
                            <>
                              <img
                                src={`${process.env.REACT_APP_URL}/api/v1/ressources/signedFile/${el.accessToken}`}
                                alt=""
                                style={{
                                  width: '100%',
                                  height: 'auto',
                                  marginTop: '5px',
                                }}
                              />
                            </>
                          )
                        })}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn"
                  style={{
                    color: 'white',
                    backgroundColor: '#4F46E5',
                    borderRadius: '8px',
                  }}
                  data-bs-dismiss="modal">
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal Administratif */}
      <div class="modal fade" id="myModalAdministratif">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Administratif
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                const info = document.getElementById('modalAdministratif').value
                updateData(
                  {
                    observation:
                      info,
                    _id: dataModalAdministration._id,
                  },
                  'carteAdmin',
                  infoUser.token,
                  infoChantier.planning
                ).then((res) => {
                  console.log(res)
                  if (res.data) {
                    maj(dataMaj,info)
                  } else {
                    addToast("Une erreur s'est produit", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  }
                })
              }}>
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    class="form-control"
                    rows="7"
                    id="modalAdministratif"
                    value={
                      dataModalAdministration.observation
                        ? dataModalAdministration.observation
                        : ''
                    }
                    onChange={(e) => {
                      setDataModalAdministration({
                        ...dataModalAdministration,
                        observation: e.target.value,
                      })
                    }}
                    name="text"></textarea>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn"
                  style={{
                    color: 'white',
                    backgroundColor: '#4F46E5',
                    borderRadius: '8px',
                  }}
                  data-bs-dismiss="modal">
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ul class="dropdown-menu">
        <li>
          <a class="dropdown-item" href="#">
            Normal
          </a>
        </li>
        <li>
          <a class="dropdown-item active" href="#">
            Active
          </a>
        </li>
        <li>
          <a class="dropdown-item disabled" href="#">
            Disabled
          </a>
        </li>
      </ul>

      {/* Modal document */}
      <ModalDocument
        dataModalDocument={dataModalDocument}
        dataModalSuiviDoc={dataModalSuiviDoc}
        indiceDoc={indiceDoc}
        setDataDocument={setDataDocument}
        setDataModalSuiviDoc={setDataModalSuiviDoc}
        dataDocument={dataDocument}
        typeDoc={2}
        cell={dataMaj}
      />

      {wait && (
        <>
          <LoaderFixed />
        </>
      )}
    </>
  )
}

export default ProfilIntervenant
