import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import imgUser from '../../assets/images/sooda_logo/default.png'
import openMenu from '../../assets/icons/Frame 8.png'
import closeMenu from '../../assets/icons/heroicons-selected.png'
import PageNotFound from '../404/PageNotFound'
const Header = ({ bool, offline }) => {
  const navigate = useNavigate()
  const info = localStorage.getItem('users')
  const infoUser = JSON.parse(info)
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = infoChantiers!='undefined' ? JSON.parse(infoChantiers) : ""
  const [menu, setMenu] = useState(true)
  const [bools, setBools] = useState(true)
  useEffect(() => {
    const style = document.getElementById('root').classList
    if (style.contains('enlarge-menu')) {
      setMenu(false)
    } else {
      setMenu(true)
    }
  }, [bools])
  return (
    <>
      <div
        className="topbar"
        style={{
          backgroundColor: '#F8FAFC',
          borderBottom: 'solid #D9D9D9 1.5px',
        }}>
        <nav
          className="navbar-custom"
          id="navbar-custom"
          style={{ backgroundColor: '#F8FAFC' }}>
          <ul className="list-unstyled topbar-nav float-end mb-0">
            <li className="dropdown">
              <span className="nav-link dropdown-toggle nav-user">
                <div class="d-flex " style={{ alignItems: 'center' }}>
                  <div
                    style={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '8px',
                      cursor: 'pointer',
                      overflow: 'hidden',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'white',
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      if (offline) {
                      } else {
                        navigate('/profil')
                      }
                    }}>
                    <img
                      src={
                        infoUser?.user?.photo
                          ? `${process.env.REACT_APP_URL}/api/v1/users/photo/${infoUser.user.photo}`
                          : imgUser
                      }
                      alt="profile-user"
                      style={{ width: 'auto', height: '100%' }}
                    />
                  </div>
                  <div>
                    <span
                      style={{
                        fontWeight: '600',
                        fontSize: '16PX',
                        lineHeight: '24px',
                        marginLeft: '10px',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => {
                        e.preventDefault()
                        if (offline) {
                        } else {
                          navigate('/profil')
                        }
                      }}>
                      {infoUser?.user?.name && infoUser.user.name}{' '}
                      {infoUser?.user?.lastname && infoUser.user.lastname}
                    </span>
                    <span
                      style={{
                        marginLeft: '5px',
                        marginBottom: '5px',
                        backgroundColor: '#E2E8F0',
                        padding: '8px 8px',
                        borderRadius: '4px',
                        cursor: 'pointer',
                      }}
                      onClick={(ev) => {
                        ev.preventDefault()
                        if (offline) {
                        } else {
                          if (
                            confirm(
                              'Êtes-vous sûr(e) de vouloir vous déconnecter ?'
                            )
                          ) {
                            sessionStorage.clear()
                            localStorage.removeItem('isLogin')
                            localStorage.removeItem('allGroupe')
                            localStorage.removeItem('horsLigne')
                            localStorage.removeItem('_id')
                            localStorage.removeItem('titreLot')
                            localStorage.removeItem('infoChantiers')
                            navigate('/')
                          }
                        }
                      }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#6366F1"
                        style={{ width: '18px', height: '18px' }}>
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5.636 5.636a9 9 0 1012.728 0M12 3v9"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
                <div></div>
              </span>
              <div className="dropdown-menu dropdown-menu-end">
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={(e) => {
                    navigate('/profil')
                  }}>
                  <i class="ti ti-user font-16 me-1 align-text-bottom"></i>{' '}
                  Profil
                </a>
                {/* <a className="dropdown-item" href="#">
                  <i className="ti ti-settings font-16 me-1 align-text-bottom"></i>{" "}
                  Paramettre
                </a> */}
                <div className="dropdown-divider mb-0"></div>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault()
                    sessionStorage.clear()
                    localStorage.removeItem('isLogin')
                    localStorage.removeItem('allGroupe')
                    localStorage.removeItem('horsLigne')
                    localStorage.removeItem('_id')
                    localStorage.removeItem('titreLot')
                    localStorage.removeItem('infoChantiers')
                    navigate('/')
                  }}>
                  <i className="ti ti-power font-16 me-1 align-text-bottom"></i>{' '}
                  Déconnexion
                </a>
              </div>
            </li>
          </ul>

          <ul className="list-unstyled topbar-nav mb-0">
            <li>
              <button
                className="nav-link button-menu-mobile nav-icon"
                id="togglemenu"
                onClick={(e) => {
                 /*  document
                    .getElementById('body')
                    .classList.toggle('enlarge-menu') */
                    document
                    .getElementById('root')
                    .classList.toggle('enlarge-menu')
                  setBools(!bools)
                }}>
                {menu ? (
                  <img
                    src={closeMenu}
                    alt=""
                    style={{ width: '36px', height: '32px' }}
                  />
                ) : (
                  <img
                    src={openMenu}
                    alt=""
                    style={{ width: '36px', height: '32px' }}
                  />
                )}
              </button>
            </li>
            <li
              class="hide-phone app-search"
              style={{ marginTop: '10px', marginLeft: '5px' }}>
              <h4
                className="page-title"
                style={{
                  color: '#475569',
                  fontWeight: '600',
                  fontSize: '16px',
                  lineHeight: '24px',
                  borderLeft: 'solid #D9D9D9 1px',
                  paddingLeft: '20px',
                }}>
                {bool
                  ? infoChantier?.titre
                    ? infoChantier.titre.toUpperCase()
                    : ''
                  : ''}
              </h4>
            </li>
          </ul>
        </nav>
      </div>
    </>
  )
}

export default Header
