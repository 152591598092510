import React, { useState, useEffect } from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import { useNavigate } from 'react-router-dom'
import { db } from './db/db'
import {
  getAllPlan,
  getTacheAssigner,
} from '../../../helpers/observation/observation'
import { getAllData } from '../../../helpers/suivi/suivi'
import ImageMarker from 'react-image-marker'
import Pin from './component/Pin'
import Modal from './component/Modal'
import { useSelector, useDispatch } from 'react-redux'
import LoaderFixed from '../../../components/loader/LoaderFixed'
import axios from 'axios'
import { useToasts } from 'react-toast-notifications'
import PageNotFound from '../../../components/404/PageNotFound'
import { searchOptimizedData } from '../../../helpers/recherche/recherche'
import Board from 'react-trello'
import { GeoAltFill } from 'react-bootstrap-icons'
//page de gestion des observations
const Observation = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  if (
    !localStorage.getItem('isLogin') ||
    (infoUser?.user?.role?.roleType != 'admin' &&
      infoUser?.user?.role?.roleType != 'opc' &&
      infoUser?.user?.role?.roleType != 'moex')
  ) {
    return <PageNotFound />
  }
  const bool = useSelector((state) => state.bool.bool)
  const navigate = useNavigate()
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const [data, setData] = useState([])
  const [trueData, setTrueData] = useState([])
  const [markers, setMarkers] = useState([])
  const [image, setImage] = useState('')
  const [id, setId] = useState()
  const [show, setShow] = useState(false)
  const [filter, setFilter] = useState('')
  const [allLot, setAllLot] = useState([])
  const [nomMedia, setNomMedia] = useState('')
  const [nombrePin, setNombrePin] = useState(0)
  const [wait, setWait] = useState(true)
  const [tacheSd, setTacheSd] = useState([])
  const [tacheSc, setTacheSc] = useState([])
  const [tacheSp, setTacheSp] = useState([])
  const [allType, setAllType] = useState([])
  const [savedPlan, setSavedPlan] = useState(true)
  const [horsLignePret, setHorsLignePret] = useState(false)
  const [dataOffLine, setDataOffline] = useState()
  const [dataPlanTablette, setDataPlanTablette] = useState([])
  const [progress, setProgress] = useState(0)
  const [itemType, setItemType] = useState(
    localStorage.getItem('filtre') ? localStorage.getItem('filtre') : 1
  )
  const [width, setWidth] = useState(100)
  const [item, setItem] = useState(true)
  const [recherche, setRecherche] = useState('')
  const nombrePins = (pins = []) => {
    return pins.filter((el) => el.archive == false)
  }
  const getDate = (date) => {
    const oneDay = 24 * 60 * 60 * 1000
    const dateDuCalcul = date || new Date()
    const currentDayOfWeek = dateDuCalcul.getDay()
    const monday = new Date(date.getTime() - (currentDayOfWeek - 1) * oneDay)

    const friday = new Date(monday.getTime() + 4 * oneDay)

    const previousMonday = new Date(monday.getTime() - 7 * oneDay)

    const previousFriday = new Date(friday.getTime() - 7 * oneDay)

    const nextMonday = new Date(monday.getTime() + 7 * oneDay)

    const nextFriday = new Date(friday.getTime() + 7 * oneDay)

    const dates = {
      currentWeek: {
        start: monday.toISOString().slice(0, 10),
        end: friday.toISOString().slice(0, 10),
      },
      previousWeek: {
        start: previousMonday.toISOString().slice(0, 10),
        end: previousFriday.toISOString().slice(0, 10),
      },
      nextWeek: {
        start: nextMonday.toISOString().slice(0, 10),
        end: nextFriday.toISOString().slice(0, 10),
      },
    }

    return dates
  }
  const calculProgression = (value, total) => {
    return (value * 100) / total
  }
  const suppressionDb = async () => {
    var request = await indexedDB.open('myDataBase')

    request.onsuccess = function (event) {
      var db = event.target.result

      // Ouvrir une transaction en mode de lecture-écriture sur la table "plans"
      var transaction = db.transaction(['plans'], 'readwrite')

      // Récupérer la référence à la table "plans"
      var objectStore = transaction.objectStore('plans')

      // Ouvrir un curseur pour parcourir tous les enregistrements
      var request = objectStore.openCursor()

      request.onsuccess = function (event) {
        var cursor = event.target.result
        if (cursor) {
          // Supprimer l'enregistrement
          objectStore.delete(cursor.primaryKey)
          cursor.continue()
        }
      }

      transaction.oncomplete = function () {
        console.log(
          'Tous les enregistrements ont été supprimés de la table "plans".'
        )
        db.close()
      }
    }
  }
  useEffect(() => {
    setWait(true)
    getAllPlan(infoChantier.planning, infoUser.token).then(async (e) => {
      if (e.name == 'AxiosError') {
        addToast(`Une erreur s'est produit`, {
          appearance: 'error',
          autoDismiss: true,
        })
        setData([])
        setWait(false)
      } else {
        const listPlans = e.data
        const listPlan = listPlans.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        )
        const newData = searchOptimizedData(recherche, listPlan)
        setData(newData)
        setTrueData(listPlan)
        setWait(false)
        e.data.map((media) => {
          if (media.nomMedia == nomMedia) {
            setImage(media.nomMedia)
            if (itemType == 1) {
              setMarkers(media.pins)
            }
            if (itemType == 2) {
              setMarkers(media.pins.filter((el) => el.type == 1))
            }
            if (itemType == 3) {
              setMarkers(media.pins.filter((el) => el.type == 2))
            }
            if (itemType == 4) {
              setMarkers(media.pins.filter((el) => el.type == 3))
            }
            if (itemType == 5) {
              setMarkers(media.pins.filter((el) => el.type == 4))
            }
            if (itemType == 6) {
              setMarkers(media.pins.filter((el) => el.type == 5))
            }
            setAllType(media.pins)
            setId(media._id)
            setShow(true)
          }
        })
        if (savedPlan) {
          const nbPlan = listPlan.length | 0
          let nbSuccess = 0
          setSavedPlan(false)
          setHorsLignePret(false)
          //await suppressionDb()
          await db.plans
            .clear()
            .then(() => {})
            .catch((error) => {
              console.error(
                'Erreur lors de la suppression des enregistrements :',
                error
              )
            })
          for (const plan of listPlan) {
            setProgress(calculProgression(nbSuccess, nbPlan).toFixed(0))
            nbSuccess++
            await axios
              .get(
                `${process.env.REACT_APP_URL}/api/v1/ressources/plan/?planName=${plan.nomMedia}&accessToken=${infoUser.token}&planning=${infoChantier.planning}`,
                { responseType: 'arraybuffer' }
              )
              .then(async (response) => {
                const reader = new FileReader()
                reader.onload = async (event) => {
                  //const dataUrl = event.target.result
                  try {
                    await db.plans.add({
                      _id: plan._id,
                      image: event.target.result,
                      description: plan.description,
                      height: plan.height,
                      width: plan.width,
                      mediaType: plan.mediaType,
                      nomMedia: plan.nomMedia,
                      pins: plan.pins,
                      updatedAt: plan.updatedAt,
                      createdAt: plan.createdAt,
                    })
                  } catch (error) {
                    addToast(
                      `Erreur durant l'enregistrement du plan: ${plan.description}`,
                      {
                        appearance: 'error',
                        autoDismiss: true,
                      }
                    )
                    console.error(
                      "Erreur lors de l'ajout de l'image à la base de données "
                    )
                  }
                }
                reader.readAsDataURL(
                  new Blob([response.data], {
                    type: response.headers['content-type'],
                  })
                )
              })
              .catch((error) => {
                console.error(
                  "Erreur lors du téléchargement de l'image :",
                  error
                )
              })
          }
          setHorsLignePret(true)
          setProgress(0)
        }
      }
    })
  }, [bool, nomMedia])
  useEffect(() => {
    const date = getDate(new Date(Date.now()))
    //S-1
    getTacheAssigner(
      infoChantier.planning,
      'end_date[lte]',
      'end_date[gte]',
      date.previousWeek.end,
      date.previousWeek.start,
      infoUser.token
    ).then((e) => {
      if (e.data) {
        setTacheSd(e.data)
      }
    })
    //S
    getTacheAssigner(
      infoChantier.planning,
      'start_date[lte]',
      'end_date[gte]',
      date.currentWeek.start,
      date.currentWeek.end,
      infoUser.token
    ).then((e1) => {
      getTacheAssigner(
        infoChantier.planning,
        'start_date[gte]',
        'start_date[lte]',
        date.currentWeek.start,
        date.currentWeek.end,
        infoUser.token
      ).then((e2) => {
        getTacheAssigner(
          infoChantier.planning,
          'end_date[gte]',
          'end_date[lte]',
          date.currentWeek.start,
          date.currentWeek.end,
          infoUser.token
        ).then((e3) => {
          if (e1.data && e2.data && e3.data) {
            const tableauFusionner = [...e1.data, ...e2.data, ...e3.data]
            const tableauSansDouble = tableauFusionner.filter((obj, i, tab) => {
              const position = tab.findIndex((item) => item.id === obj.id)
              return position == i
            })
            setTacheSc(tableauSansDouble)
          }
        })
      })
    })
    //S+1
    getTacheAssigner(
      infoChantier.planning,
      'start_date[gte]',
      'start_date[lte]',
      date.nextWeek.start,
      date.nextWeek.end,
      infoUser.token
    ).then((e) => {
      if (e.data) {
        setTacheSp(e.data)
      }
    })
    if (document.querySelector('.image-marker__marker')) {
      document
        .querySelector('.image-marker__marker')
        .setAttribute('draggable', 'true')
    }
  }, [])
  useEffect(() => {
    getAllData(
      `planning=${infoChantier.planning}&sort=titre`,
      'lots/simple',
      infoUser.token
    ).then((e) => {
      if (e.data) {
        setAllLot(e.data)
        localStorage.setItem('allLot', JSON.stringify(e.data))
      } else {
        localStorage.setItem('allLot', JSON.stringify([]))
      }
    })
  }, [])

  useEffect(() => {}, [image, data, filter])

  useEffect(() => {
    if (filter.length > 0) {
      const trueFilter = filter.toLowerCase()
      setData(trueData.filter((e) => e.description.includes(trueFilter)))
    } else {
      setData(trueData)
    }
  }, [filter])

  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={true} active="observation" />
        <Header bool={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          {/* <div className="container-fluid">
            <Board
              editable
              draggable
              data={datas}
              style={{
                backgroundColor: '#F8FAFC',
                height: '600px',
                borderRadius: '8px',
              }}
              laneStyle={{ backgroundColor: '#E2E8F0' }}
            />
          </div> */}
          <div className="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <div
                  class="page-title-box"
                  style={{
                    padding: '16px 5px',
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #E2E8F0',
                    borderRadius: '8px',
                    margin: '16px 0px',
                    boxShadow:
                      '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                    marginBottom: '15px',
                    marginTop: '20px',
                  }}>
                  <div
                    class="float-end"
                    style={{
                      padding: '10px 30px',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    {horsLignePret ? (
                      <>
                        <div
                          style={{
                            backgroundColor: 'rgba(45, 212, 191, 0.15)',
                            color: '#0D9488',
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: '10px',
                            padding: '2px 10px',
                            borderRadius: '8px',
                          }}>
                          {' '}
                          <i class="fa fa-save"></i>
                          <span style={{ marginLeft: '5px' }}>
                            Mode tablette prêt
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            backgroundColor: 'rgba(45, 212, 191, 0.2)',
                            color: '#0D9488',
                            marginRight: '10px',
                            padding: '5px 10px',
                            borderRadius: '8px',
                            width: '300px',
                          }}>
                          {' '}
                          {/* <div style={{ width: '100%', display: 'flex',alignItems:'center' }}>
                            <i class="fa fa-save"></i>
                            <span style={{ marginLeft: '5px' }}>
                              En cours d'enregistrement
                            </span>
                          </div> */}
                          <div style={{ width: '100%' }}>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}>
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight: '400',
                                  color: '#0D9488',
                                }}>
                                <i
                                  class="fa fa-save"
                                  style={{ marginRight: '3px' }}></i>
                                Enregistrement...
                              </span>
                              <span style={{ color: '#0D9488' }}>
                                {progress}%
                              </span>
                            </span>
                            <div
                              style={{
                                width: '100%',
                                position: 'relative',
                                height: '7px',
                                borderRadius: '4px',
                                backgroundColor: 'rgba(45, 212, 191, 0.2)',
                                marginTop: '5px',
                              }}>
                              <span
                                style={{
                                  position: 'absolute',
                                  left: '0',
                                  top: '0',
                                  bottom: '0',
                                  backgroundColor: '#2DD4BF',
                                  width: `${progress}%`,
                                  borderRadius: '4px',
                                }}></span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <button
                      className="btn"
                      style={{ backgroundColor: '#4F46E5', color: '#F8FAFC' }}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal">
                      + Nouveau plan
                    </button>
                    <Modal
                      id={'exampleModal'}
                      setWait={setWait}
                      data={data}
                      setData={setData}
                    />{' '}
                    <button
                      data-bs-toggle="dropdown"
                      className="btn"
                      style={{
                        backgroundColor: '#E2E8F0',
                        color: '#334155',
                        marginLeft: '12px',
                      }}>
                      Mode <i className="ti ti-selector"></i>
                    </button>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1">
                      <li
                        onClick={async (e) => {
                          e.preventDefault()
                          navigate('/Observation_horsLigne')
                        }}>
                        <a
                          class="dropdown-item"
                          href="#"
                          style={{ fontSize: '1vw' }}>
                          Mode Tablette
                        </a>
                      </li>
                      <li
                        onClick={(e) => {
                          e.preventDefault()
                        }}>
                        <a
                          class="dropdown-item"
                          href="#"
                          style={{ fontSize: '1vw' }}>
                          En ligne
                        </a>
                      </li>
                    </ul>
                  </div>
                  <span style={{ marginLeft: '10px' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="#94A3B8"
                      style={{ width: '16px', height: '16px' }}>
                      <path
                        fillRule="evenodd"
                        d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                        clipRule="evenodd"
                      />
                    </svg>{' '}
                    <span
                      style={{
                        color: '#334155',
                        fontSize: '14px',
                        fontWeight: '600',
                      }}>
                      / Observations
                    </span>
                  </span>{' '}
                  <br />
                  <span
                    style={{
                      marginLeft: '15px',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <span
                      style={{
                        backgroundColor: '#E2E8F0',
                        padding: '2px',
                        borderRadius: '6px',
                      }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="#6366F1"
                        style={{
                          width: '20px',
                          height: '20px',
                          cursor: 'pointer',
                        }}
                        onClick={(e) => {
                          e.preventDefault()
                          navigate('/accueil')
                        }}>
                        <path
                          fillRule="evenodd"
                          d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                    <span
                      style={{
                        color: '#334155',
                        fontSize: '20px',
                        fontWeight: '600',
                        marginLeft: '10px',
                      }}>
                      {' '}
                      Observations
                    </span>
                  </span>
                </div>
                <div
                  className="d-flex"
                  style={{
                    border: '1px solid #E2E8F0',
                    boxShadow:
                      '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                    backgroundColor: '#FFFFFF',
                    borderRadius: '8px',
                    position: 'relative',
                  }}>
                  {show && (
                    <>
                      <div
                        className="btn-group dropleft"
                        style={{
                          position: 'absolute',
                          top: '20px',
                          right: '32px',
                          zIndex: '999',
                          display: 'flex',
                        }}>
                        <span
                          data-bs-toggle="dropdown"
                          style={{
                            backgroundColor: '#F8FAFC',
                            border: '1px solid #CBD5E1',
                            boxShadow:
                              '0px 6.15px 12.31px rgba(0, 0, 0, 0.02), 0px 6.15px 6.15px rgba(0, 0, 0, 0.04)',
                            borderRadius: '8px',
                            padding: '6px 8px',
                            height: '36px',
                            width: '40px',
                          }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="#E11D48"
                            style={{ width: '24px', height: '24px' }}>
                            <path
                              fillRule="evenodd"
                              d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                        <ul
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1">
                          <li
                            style={{ display: 'flex', alignItems: 'center' }}
                            onClick={(e) => {
                              e.preventDefault()
                              setItemType(1)
                              setMarkers(allType)
                            }}>
                            <a
                              class="dropdown-item"
                              href="#"
                              style={{ fontSize: '1vw' }}>
                              Tous
                            </a>
                          </li>
                          <li
                            style={{ display: 'flex', alignItems: 'center' }}
                            onClick={(e) => {
                              e.preventDefault()
                              setItemType(2)
                              setMarkers(allType.filter((el) => el.type == 1))
                            }}>
                            <a
                              class="dropdown-item"
                              href="#"
                              style={{ fontSize: '1vw' }}>
                              Visites
                            </a>
                            <GeoAltFill color="#6366F1" size={24} />
                          </li>
                          <li
                            style={{ display: 'flex', alignItems: 'center' }}
                            onClick={(e) => {
                              e.preventDefault()
                              setItemType(3)
                              setMarkers(allType.filter((el) => el.type == 2))
                            }}>
                            <a
                              class="dropdown-item"
                              href="#"
                              style={{ fontSize: '1vw' }}>
                              Réserves
                            </a>
                            <GeoAltFill color="red" size={24} />
                          </li>
                          <li
                            style={{ display: 'flex', alignItems: 'center' }}
                            onClick={(e) => {
                              e.preventDefault()
                              setItemType(4)
                              setMarkers(allType.filter((el) => el.type == 3))
                            }}>
                            <a
                              class="dropdown-item"
                              href="#"
                              style={{ fontSize: '1vw' }}>
                              Réception de supports
                            </a>
                            <GeoAltFill color="#2DD4BF" size={24} />
                          </li>
                          <li
                            style={{ display: 'flex', alignItems: 'center' }}
                            onClick={(e) => {
                              e.preventDefault()
                              setItemType(5)
                              setMarkers(allType.filter((el) => el.type == 4))
                            }}>
                            <a
                              class="dropdown-item"
                              href="#"
                              style={{ fontSize: '1vw' }}>
                              Avancements
                            </a>
                            <GeoAltFill color="orange" size={24} />
                          </li>
                          <li
                            style={{ display: 'flex', alignItems: 'center' }}
                            onClick={(e) => {
                              e.preventDefault()
                              setItemType(6)
                              setMarkers(allType.filter((el) => el.type == 5))
                            }}>
                            <a
                              class="dropdown-item"
                              href="#"
                              style={{ fontSize: '1vw' }}>
                              Bons à fermer
                            </a>
                            <GeoAltFill color="#02A0FC" size={24} />
                          </li>
                        </ul>
                        <span
                          style={{
                            backgroundColor: '#F8FAFC',
                            border: '1px solid #CBD5E1',
                            boxShadow:
                              '0px 6.15px 12.31px rgba(0, 0, 0, 0.02), 0px 6.15px 6.15px rgba(0, 0, 0, 0.04)',
                            borderRadius: '8px',
                            padding: '6px 12px',
                            height: '36px',
                            width: '77px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginLeft: '16px',
                            color: '#475569',
                            fontSize: '24px',
                            fontWeight: '600',
                          }}>
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={(ev) => {
                              ev.preventDefault()
                              if (width < 300) {
                                setWidth(width + 50)
                              }
                            }}>
                            +
                          </span>
                          <span
                            style={{
                              fontSize: '16px',
                              color: '#CBD5E1',
                              fontWeight: '400',
                            }}>
                            |
                          </span>
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={(ev) => {
                              ev.preventDefault()
                              if (width > 50) {
                                setWidth(width - 50)
                              }
                            }}>
                            -
                          </span>
                        </span>
                      </div>
                    </>
                  )}
                  <div
                    style={
                      item
                        ? {
                            width: '326px',
                            borderRight: '1px solid #CBD5E1',
                            padding: '12px 12px',
                            overflow: 'hidden',
                            position: 'relative',
                          }
                        : {
                            width: '20px',
                            borderRight: '1px solid #CBD5E1',
                            padding: '12px 12px',
                            overflow: 'hidden',
                            position: 'relative',
                          }
                    }>
                    {item ? (
                      <>
                        <form
                          id="champ"
                          onSubmit={(e) => {
                            e.preventDefault()
                            e.target.reset()
                            setFilter('')
                            setData(trueData)
                          }}>
                          <div
                            style={{
                              backgroundColor: 'rgba(226, 232, 240, 0.6)',
                              padding: '8px 0px 10px 10px',
                              width: '90%',
                              borderRadius: '8px',
                              display: 'flex',
                            }}>
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="#64748B"
                                style={{ width: '24px', height: '24px' }}>
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                />
                              </svg>
                            </span>
                            <span>
                              <input
                                type="text"
                                id=""
                                style={{
                                  border: 'none',
                                  backgroundColor: 'transparent',
                                  marginLeft: '10px',
                                  height: '100%',
                                }}
                                placeholder="Recherche"
                                defaultValue={filter}
                                onChange={(e) => {
                                  setRecherche(e.target.value)
                                  const newData = searchOptimizedData(
                                    e.target.value,
                                    data
                                  )
                                  setData(newData)
                                  //setFilter(e.target.value)
                                }}
                              />
                            </span>
                          </div>
                        </form>{' '}
                        <div
                          class="table-responsive"
                          style={{ maxHeight: '68vh', overflowY: 'scroll' }}>
                          <table class="table" id="myTable">
                            <tbody>
                              {data.length > 0 && (
                                <>
                                  {data.map((e) => {
                                    return (
                                      <>
                                        <tr
                                          style={{ cursor: 'pointer' }}
                                          onClick={(a) => {
                                            setNomMedia(e.nomMedia)
                                          }}>
                                          <td
                                            style={{
                                              backgroundColor: 'white',
                                              border: 'none',
                                              borderRadius: '8px',
                                              padding: '5px 0px',
                                            }}>
                                            <div
                                              style={{
                                                border: '1px solid  #CBD5E1',
                                                borderRadius: '8px',
                                                padding: '10px 10px',
                                                backgroundColor: `${
                                                  nomMedia == e.nomMedia
                                                    ? 'rgba(226, 232, 240, 0.6)'
                                                    : 'white'
                                                }`,
                                              }}>
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  justifyContent:
                                                    'space-between',
                                                  flexWrap: 'wrap',
                                                }}>
                                                <span>
                                                  <a
                                                    href="#"
                                                    className="nav-item nav-link active activeLocal"
                                                    style={{
                                                      color: `${
                                                        nomMedia == e.nomMedia
                                                          ? '#1E293B'
                                                          : '#1E293B'
                                                      }`,
                                                      fontSize: '18px',
                                                      fontWeight: '600',
                                                    }}>
                                                    {e.description
                                                      ? e.description
                                                      : '---'}
                                                  </a>
                                                </span>
                                                <span
                                                  style={{
                                                    padding: '4px 8px',
                                                    backgroundColor: '#FFF1F2',
                                                    borderRadius: '8px',
                                                    color: '#E11D48',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    fontSize: '14px',
                                                    fontWeight: '400',
                                                  }}>
                                                  {' '}
                                                  <span>
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 20 20"
                                                      fill=" #E11D48"
                                                      style={{
                                                        width: '20px',
                                                        height: '20px',
                                                      }}>
                                                      <path
                                                        fillRule="evenodd"
                                                        d="M9.69 18.933l.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a5.741 5.741 0 00.281-.14c.186-.096.446-.24.757-.433.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7 7 0 103 9c0 3.492 1.698 5.988 3.355 7.584a13.731 13.731 0 002.273 1.765 11.842 11.842 0 00.976.544l.062.029.018.008.006.003zM10 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z"
                                                        clipRule="evenodd"
                                                      />
                                                    </svg>
                                                  </span>
                                                  <span>
                                                    {e.pins?.length > 0
                                                      ? nombrePins(e.pins)
                                                          .length
                                                      : 0}{' '}
                                                    épingle(s)
                                                  </span>
                                                </span>
                                              </div>
                                              <span
                                                style={{
                                                  color: '#64748B',
                                                  fontSize: '14px',
                                                  fontWeight: '400',
                                                  marginLeft: '8px',
                                                }}>
                                                Modifié il y a -- heures
                                              </span>
                                              <hr
                                                style={{
                                                  width: '95%',
                                                  margin: 'auto',
                                                  marginTop: '8px',
                                                }}
                                              />
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  justifyContent: 'flex-end',
                                                  marginTop: '8px',
                                                }}>
                                                <button
                                                  className="btn"
                                                  style={{
                                                    width: '40px',
                                                    height: '36px',
                                                    padding: '0px',
                                                    border: '1px solid #CBD5E1',
                                                  }}
                                                  onClick={(ev) => {
                                                    ev.preventDefault()
                                                    ev.stopPropagation()
                                                    if (
                                                      confirm(
                                                        'Êtes-vous certain(e) de vouloir supprimer ce plan ? '
                                                      )
                                                    ) {
                                                      axios
                                                        .patch(
                                                          `${process.env.REACT_APP_URL}/api/v1/medias/${e._id}?planning=${infoChantier.planning}`,
                                                          {
                                                            archive: true,
                                                          },
                                                          {
                                                            headers: {
                                                              Authorization: `Bearer ${infoUser.token}`,
                                                            },
                                                          }
                                                        )
                                                        .then((res) => {
                                                          addToast(
                                                            'Le plan a été supprimé avec succès.',
                                                            {
                                                              appearance:
                                                                'success',
                                                              autoDismiss: true,
                                                            }
                                                          )
                                                          setShow(false)
                                                          setData(
                                                            data.filter(
                                                              (plan) =>
                                                                plan._id !=
                                                                e._id
                                                            )
                                                          )
                                                        })
                                                        .catch((err) => {
                                                          console.log(err)
                                                          addToast(
                                                            "Une erreur s'est produit",
                                                            {
                                                              appearance:
                                                                'error',
                                                              autoDismiss: true,
                                                            }
                                                          )
                                                        })
                                                    } else {
                                                      ev.preventDefault()
                                                    }
                                                  }}>
                                                  {' '}
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="#E11D48"
                                                    style={{
                                                      width: '20px',
                                                      height: '20px',
                                                    }}>
                                                    <path
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                                    />
                                                  </svg>
                                                </button>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      </>
                                    )
                                  })}
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div
                    style={{
                      width: `${item ? '90%' : '100%'}`,
                      overflow: 'auto',
                      position: 'relative',
                    }}>
                    {item ? (
                      <>
                        <button
                          style={{
                            backgroundColor: '#6366F1',
                            position: 'absolute',
                            left: '-5px',
                            top: '10px',
                            zIndex: '10',
                            border: 'none',
                            borderRadius: '4px',
                            padding: '3px 3px',
                          }}
                          onClick={(e) => {
                            e.preventDefault()
                            setItem(false)
                          }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="white"
                            style={{ width: '20px', height: '20px' }}>
                            <path
                              fillRule="evenodd"
                              d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          style={{
                            backgroundColor: '#6366F1',
                            position: 'absolute',
                            left: '-5px',
                            top: '10px',
                            zIndex: '10',
                            border: 'none',
                            borderRadius: '4px',
                            padding: '3px 3px',
                          }}
                          onClick={(e) => {
                            e.preventDefault()
                            setItem(true)
                          }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="white"
                            style={{ width: '20px', height: '20px' }}>
                            <path
                              fillRule="evenodd"
                              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </>
                    )}
                    {show ? (
                      <>
                        <div
                          className="container-fluid"
                          style={{
                            width: '100%',
                            height: 'auto',
                            maxHeight: '72vh',
                            overflowX: 'auto',
                          }}>
                          <div
                            className="container-fluid planCible"
                            style={{ width: `${width}%` }}>
                            <ImageMarker
                              src={`${process.env.REACT_APP_URL}/api/v1/ressources/plan/?planName=${image}&accessToken=${infoUser.token}&planning=${infoChantier.planning}`}
                              markers={markers}
                              onAddMarker={(marker) =>
                                setMarkers([...markers, marker])
                              }
                              markerComponent={(e) => (
                                <Pin
                                  props={e}
                                  idt={false}
                                  idImage={id}
                                  lot={allLot}
                                  tacheSd={tacheSd}
                                  tacheSc={tacheSc}
                                  tacheSp={tacheSp}
                                  dataOffLine={dataOffLine}
                                />
                              )}
                            />
                          </div>
                        </div>{' '}
                      </>
                    ) : (
                      <>
                        <h1
                          className="text-center text-danger"
                          style={{ marginTop: '12px' }}>
                          {' '}
                          <u>Sélectionner un plan</u>{' '}
                        </h1>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {wait && (
        <>
          <LoaderFixed />
        </>
      )}
    </>
  )
}

export default Observation
