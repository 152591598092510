import React, { useState, useEffect } from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import { useNavigate } from 'react-router-dom'
import LastPlanner2 from './LastPlanner2'
import { useSelector } from 'react-redux'
import PageNotFound from '../../../components/404/PageNotFound'
import 'dhtmlx-gantt/codebase/skins/dhtmlxgantt_material.css'
import './gestionTache.scss'
import 'dhtmlx-gantt/codebase/api'
import 'dhtmlx-gantt/codebase/dhtmlxgantt'
import { getAllTask } from '../../../helpers/planning/gestionTache/dataGantt'
import { dhtmlxGantt02 } from '../../../helpers/planning/gestionTache/gantt'
import { gantt } from 'dhtmlx-gantt'

const LastPlanner = () => {
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  if (
    !localStorage.getItem('isLogin') ||
    (infoUser?.user?.role?.roleType != 'admin' &&
      infoUser?.user?.role?.roleType != 'opc' &&
      infoUser?.user?.role?.roleType != 'moex')
  ) {
    return <PageNotFound />
  }

  const [havePlanning, setHavePlaning] = useState(false)
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const navigate = useNavigate()
  const bool = useSelector((state) => state.bool.bool)

  //configuration gantt
  //plugin de dhtmlx
  gantt.plugins({
    auto_scheduling: true,
    critical_path: true,
    marker: true,
  })
  //configuration
  gantt.config.date_format = '%Y-%m-%d'
  gantt.config.show_errors = false
  gantt.config.show_markers = true
  gantt.serialize('json')
  gantt.config.work_time = true
  //formatage date
  gantt.attachEvent('onTaskLoading', function (task) {
    task.planned_start = gantt.date.parseDate(task.planned_start, 'xml_date')
    task.planned_end = gantt.date.parseDate(task.planned_end, 'xml_date')
    return true
  })
  //zoom to fit
  var scaleConfigs = [
    // minutes
    {
      unit: 'minute',
      step: 1,
      scale_unit: 'hour',
      date_scale: '%H',
      subscales: [{ unit: 'minute', step: 1, date: '%H:%i' }],
    },
    // hours
    {
      unit: 'hour',
      step: 1,
      scale_unit: 'day',
      date_scale: '%j %D',
      //subscales: [{ unit: 'hour', step: 1, date: '%H:%i' }],
    },
    // days
    {
      unit: 'day',
      step: 1,
      scale_unit: 'month',
      date_scale: '%F %Y',
      subscales: [{ unit: 'week', step: 1, date: '%j %D' }],
    },
    // weeks
    {
      unit: 'week',
      step: 1,
      scale_unit: 'month',
      date_scale: '%F',
      subscales: [
        {
          unit: 'week',
          step: 1,
          template: function (date) {
            var dateToStr = gantt.date.date_to_str('%d %M')
            var endDate = gantt.date.add(
              gantt.date.add(date, 1, 'week'),
              -1,
              'day'
            )
            return dateToStr(date) + ' - ' + dateToStr(endDate)
          },
        },
      ],
    },
    // months
    {
      unit: 'month',
      step: 1,
      scale_unit: 'year',
      date_scale: '%Y',
      subscales: [{ unit: 'month', step: 1, date: '%M' }],
    },
    // quarters
    {
      unit: 'month',
      step: 3,
      scale_unit: 'year',
      date_scale: '%Y',
      subscales: [
        {
          unit: 'month',
          step: 3,
          template: function (date) {
            var dateToStr = gantt.date.date_to_str('%M')
            var endDate = gantt.date.add(
              gantt.date.add(date, 3, 'month'),
              -1,
              'day'
            )
            return dateToStr(date) + ' - ' + dateToStr(endDate)
          },
        },
      ],
    },
    // years
    {
      unit: 'year',
      step: 1,
      scale_unit: 'year',
      date_scale: '%Y',
      subscales: [
        {
          unit: 'year',
          step: 5,
          template: function (date) {
            var dateToStr = gantt.date.date_to_str('%Y')
            var endDate = gantt.date.add(
              gantt.date.add(date, 5, 'year'),
              -1,
              'day'
            )
            return dateToStr(date) + ' - ' + dateToStr(endDate)
          },
        },
      ],
    },
    // decades
    {
      unit: 'year',
      step: 10,
      scale_unit: 'year',
      template: function (date) {
        var dateToStr = gantt.date.date_to_str('%Y')
        var endDate = gantt.date.add(
          gantt.date.add(date, 10, 'year'),
          -1,
          'day'
        )
        return dateToStr(date) + ' - ' + dateToStr(endDate)
      },
      subscales: [
        {
          unit: 'year',
          step: 100,
          template: function (date) {
            var dateToStr = gantt.date.date_to_str('%Y')
            var endDate = gantt.date.add(
              gantt.date.add(date, 100, 'year'),
              -1,
              'day'
            )
            return dateToStr(date) + ' - ' + dateToStr(endDate)
          },
        },
      ],
    },
  ]

  // Ajoutez une fonction pour appliquer la configuration en fonction de l'unité de temps
  const applyTimeUnitConfig = (timeUnit) => {
    const selectedConfig = scaleConfigs.find(
      (config) => config.unit === timeUnit
    )
    if (selectedConfig) {
      applyConfig(selectedConfig)
      gantt.render()
    }
  }

  const applyConfig = (config, dates) => {
    gantt.config.scale_unit = config.scale_unit
    if (config.date_scale) {
      gantt.config.date_scale = config.date_scale
      gantt.templates.date_scale = null
    } else {
      gantt.templates.date_scale = config.template
    }

    gantt.config.step = config.step
    gantt.config.subscales = config.subscales

    if (dates) {
      gantt.config.start_date = gantt.date.add(
        dates.start_date,
        -1,
        config.unit
      )
      gantt.config.end_date = gantt.date.add(
        gantt.date[config.unit + '_start'](dates.end_date),
        2,
        config.unit
      )
    } else {
      gantt.config.start_date = gantt.config.end_date = null
    }
  }
 
  //verification plannning
  useEffect(() => {
    if (infoChantier.planning) {
      setHavePlaning(true)
    } else {
      setHavePlaning(false)
    }
  }, [bool])

  //recuperation de tous les taches
 /*  useEffect(() => {
    if (infoChantier?.planning) {
      getAllTask(infoChantier.planning, infoUser.token).then((e) => {
        console.log(e)

        dhtmlxGantt02(gantt, 'gantt1', e)
        gantt.addTaskLayer(function draw_planned(task) {
          if (task.planned_start && task.planned_end) {
            var sizes = gantt.getTaskPosition(
              task,
              task.planned_start,
              task.planned_end
            )
            var el = document.createElement('div')
            el.className = 'baseline'
            el.style.left = sizes.left - 6 + 'px'
            el.style.width = sizes.width + 10 + 'px'
            el.style.height = sizes.height - 13 + 'px'
            el.style.top = sizes.top + 1 + 'px'
            return el
          }
          return false
        })

        gantt.eachTask(function (task) {
          if (+task.duration == 0) {
            task.type = gantt.config.types.milestone
          }
          if (+task.parent > 0) {
            var parent = gantt.getTask(task.parent)
            parent.type = gantt.config.types.project
            parent.color = 'lightgray'
          }
        })
        applyTimeUnitConfig('hour')
       //var currentDate = new Date("17-06-2022");
        var currentDate = new Date();
        gantt.addMarker({
          start_date: currentDate,
          css: 'today',
          text: "Aujourd'hui",
          title: "Aujourd'hui",
        });
        setWait(false)
      })
    } else {
      setHavePlaning(false)
      setWait(false)
    }
  }, []) */
  gantt.config.bar_height = 20
  gantt.config.row_height = 40
  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={true} active="planning" />
        <Header bool={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="float-end"></div>
                  <span style={{ marginLeft: '10px' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="#94A3B8"
                      style={{ width: '16px', height: '16px' }}>
                      <path
                        fillRule="evenodd"
                        d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                        clipRule="evenodd"
                      />
                    </svg>{' '}
                    <span
                      style={{
                        color: '#334155',
                        fontSize: '14px',
                        fontWeight: '600',
                      }}>
                      / Planification
                    </span>
                  </span>{' '}
                  <br />
                  <span
                    style={{
                      marginLeft: '15px',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <span
                      style={{
                        backgroundColor: '#E2E8F0',
                        padding: '2px',
                        borderRadius: '6px',
                      }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="#6366F1"
                        style={{
                          width: '20px',
                          height: '20px',
                          cursor: 'pointer',
                        }}
                        onClick={(e) => {
                          e.preventDefault()
                          navigate('/accueil')
                        }}>
                        <path
                          fillRule="evenodd"
                          d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                    <span
                      style={{
                        color: '#334155',
                        fontSize: '20px',
                        fontWeight: '600',
                        marginLeft: '10px',
                      }}>
                      {' '}
                      Planification
                    </span>
                  </span>
                </div>
              </div>
            </div>
            {/* debut NavBar */}
            <div style={{ display: 'flex', flexWrap: 'nowrap', width: '100%' }}>
              <div className="wrapper">
               {/*  <div
                  className="item"
                  style={{
                    borderLeft: '1px solid #CBD5E1',
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px',
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    navigate('/planning_Dashboard')
                  }}>
                  <span
                    style={{
                      color: '#334155',
                      fontSize: '16px',
                      fontWeight: '400',
                      cursor: 'pointer',
                    }}>
                    Tableau de bord
                  </span>
                </div> */}
                <div
                  className="item"
                  style={{
                    borderLeft: '1px solid #CBD5E1',
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px',
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    navigate('/planning_GestionTache')
                  }}>
                  <span
                    style={{
                      color: '#334155',
                      fontSize: '16px',
                      fontWeight: '400',
                      cursor: 'pointer',
                    }}>
                    Gestion des tâches
                  </span>
                </div>
                <div
                  className="item"
                  onClick={(e) => {
                    e.preventDefault()
                    navigate('/Planning_GestionTache_LastPlanner')
                  }}>
                  <span
                    style={{
                      color: '#F8FAFC',
                      fontSize: '16px',
                      fontWeight: '400',
                      backgroundColor: '#6366F1',
                      padding: '8px 24px',
                      borderRadius: '8px',
                    }}>
                    Glissant
                  </span>
                </div>
                <div
                  className="item"
                  onClick={(e) => {
                    e.preventDefault()
                    navigate('/planning_Favori')
                  }}>
                  <span
                    style={{
                      color: '#334155',
                      fontSize: '16px',
                      fontWeight: '400',
                      cursor: 'pointer',
                    }}>
                    Jalons Critiques
                  </span>
                </div>
                <div
                  className="item"
                  onClick={(e) => {
                    e.preventDefault()
                    navigate('/planning_Retard')
                  }}
                  style={{
                    borderRight: '1px solid  #CBD5E1',
                    borderTopRightRadius: '8px',
                    borderBottomRightRadius: '8px',
                  }}>
                  <span
                    style={{
                      color: '#334155',
                      fontSize: '16px',
                      fontWeight: '400',
                      cursor: 'pointer',
                    }}>
                    Retards
                  </span>
                </div>
              </div>
            </div>
            {/* Fin NavBar */}

            {/** Debut last planner */}
            <div
              className="container-fluid"
              style={{ width: '100%', marginTop: '1vw', position: 'relative' }}>
              {havePlanning ? (
                <>
                  <LastPlanner2
                    zoom={true}
                    planning={infoChantier.planning}
                    width={'100%'}
                  />
                 {/*  <div
                    className="container-fluid"
                    style={{
                      border: '1px solid #E2E8F0',
                      backgroundColor: '#FFFFFF',
                      boxShadow:
                        '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                      borderRadius: '8px',
                      width: '100%',
                      overflow: 'hidden',
                      marginTop: '30px',
                      paddingTop: '20px',
                    }}>
                    <div
                      id="gantt1"
                      style={{
                        width: '100%',
                        height: '70vh',
                        marginTop: '1px',
                      }}></div>
                  </div> */}
                </>
              ) : (
                <>
                  <h1>vous devez importer un planning</h1>
                </>
              )}
            </div>

            {/** Fin last planner */}
          </div>
        </div>
      </div>
    </>
  )
}

export default LastPlanner
