import React, { useState, useEffect, useRef } from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import 'react-tabulator/lib/styles.css'
import 'tabulator-tables/dist/css/tabulator_bootstrap3.min.css'
import { ReactTabulator } from 'react-tabulator'
import LoaderFixed from '../../../components/loader/LoaderFixed'
import {
  updateContact,
  addContact,
  getUser,
  addIntervenant,
} from '../../../helpers/contact/contact'
import { updateData, getAllData, getData } from './../../../helpers/suivi/suivi'
import { getAllRole } from '../../../helpers/role/role'
import PageNotFound from '../../../components/404/PageNotFound'
import { useToasts } from 'react-toast-notifications'
import axios from 'axios'
import trash from '../../../assets/images/Kolos_logo/trash.png'
import { useNavigate } from 'react-router-dom'
import { searchOptimizedData } from '../../../helpers/recherche/recherche'
const Contacte = () => {
  const canvasRef = useRef(null)
  const navigate = useNavigate()
  const { addToast } = useToasts()
  if (!localStorage.getItem('isLogin')) {
    return <PageNotFound />
  }
  const [contact, setContact] = useState([])
  const [bool, setBool] = useState(false)
  const [wait, setWait] = useState(false)
  const [users, setUsers] = useState([])
  const [intervenant, setIntervenant] = useState([])
  const [idIntervenant, setIdIntervenant] = useState()
  const [userToCreate, setUserToCreate] = useState(1)
  const [listRole, setListRole] = useState([])
  const [roleSelected, setRoleSelected] = useState()
  const [listSociete, setListSociete] = useState([])
  const [listEnt, setListEnt] = useState([])
  const [entSelected, setEntSelected] = useState()
  const [societe, setSociete] = useState('')
  const [nom, setNom] = useState('')
  const [prenom, setPrenom] = useState('')
  const [mail, setMail] = useState('')
  const [tel, setTel] = useState('')
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const simpleInput = (cell) => {
    const input = document.createElement('input')
    input.type = 'text'
    input.style =
      'width:100%;border:none;padding:11px 22px;font-size:16px;font-weight:600;color:#334155'
    input.defaultValue = cell.getValue()
    input.addEventListener('blur', function (ev) {
      ev.preventDefault()
      cell.setValue(ev.target.value)
    })
    const container = document.createElement('div')
    container.style = 'display:flex;align-items:center;width:100%'
    container.appendChild(input)
    return container
  }
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_URL}/api/v1/entreprises/parChantier?chantierId=${infoChantier._id}&select=entreprises&planning=${infoChantier.planning}`,
        {
          headers: {
            Authorization: `Bearer ${infoUser.token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.data.entreprises) {
          setListEnt(res.data.data.entreprises)
        }
      })
      .catch((err) => {
        console.log(err)
      })
    axios
      .get(
        `${process.env.REACT_APP_URL}/api/v1/entreprises/parChantier?chantierId=${infoChantier._id}&select=intervenants&planning=${infoChantier.planning}`,
        {
          headers: {
            Authorization: `Bearer ${infoUser.token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.data.intervenants) {
          setListSociete(res.data.data.intervenants)
        }
      })
      .catch((err) => {
        console.log(err)
      })
    axios
      .get(
        `${process.env.REACT_APP_URL}/api/v1/roles?planning=${infoChantier.planning}`,
        {
          headers: {
            Authorization: `Bearer ${infoUser.token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.data) {
          setListRole(res.data.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  useEffect(() => {
    setWait(true)
    axios
      .get(
        `${process.env.REACT_APP_URL}/api/v1/entreprises/intervenantsParChantier?planning=${infoChantier.planning}`,
        {
          headers: {
            Authorization: `Bearer ${infoUser.token}`,
          },
        }
      )
      .then((res) => {
        console.log(res)
        if(res.data.data){
          setContact(res.data.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
    getUser(
      infoChantier.intervenants?._id,
      infoUser.token,
      infoChantier.planning
    )
      .then((e) => {
        setIntervenant(e.data.info.intervenants)
        setIdIntervenant(e.data.info._id)
        setUsers(e.data.result)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => setWait(false))
    /*  getAllData(`planning=${infoChantier.planning}`, 'lots', infoUser.token)
      .then((result) => {
        const contacts = result.data.reduce(
          (accumulator, { _id, titre, entreprise }) => {
            const { responsable } = entreprise
            responsable.entreprise = entreprise
            responsable.lot = titre
            responsable.idLot = _id
            const exist = accumulator.find((res) => res._id === responsable._id)
            accumulator.push(responsable)
            return accumulator
          },
          []
        )
        setContact([...contacts])
      })
      .finally((res) => {
        setWait(false)
      }) */
  }, [bool])
  const delRow = (cell) => {
    const inters = intervenant.filter(
      (e) => e.intervenant != cell.getData()._id
    )
    addIntervenant(
      inters,
      idIntervenant,
      infoUser.token,
      infoChantier.planning
    ).then((el) => {
      if (el.name == 'AxiosError') {
        addToast(
          el.response?.data?.message
            ? el.response.data.message
            : "Une erreur s'est produit",
          {
            appearance: 'error',
            autoDismiss: true,
          }
        )
      }
      setBool(!bool)
    })
  }
  const delRowEnt = async (cell) => {
    const data = cell.getData()
   await axios
   .patch(
     `${process.env.REACT_APP_URL}/api/v1/users/${data._id}?planning=${infoChantier.planning}`,
     {archive:true},
     {
       headers: {
         Authorization: `Bearer ${infoUser.token}`,
       },
     }
   )
   .then((res) => {
    setBool(!bool)
   })
   .catch((err) => {
     console.log(err)
     setBool(!bool)
   })
  }
  const buttons = (cell) => {
    const delIcon = document.createElement('img')
    delIcon.type = 'icon'
    delIcon.src = trash
    delIcon.style = 'width:24px;height:24px'

    //create delete button
    var delBt = document.createElement('button')
    delBt.type = 'button'
    delBt.appendChild(delIcon)
    delBt.classList.add('btn')
    delBt.style = 'padding:8px 10px; border:1px solid #CBD5E1 '
    delBt.addEventListener('click', function () {
      delRow(cell)
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(delBt)

    return buttonHolder
  }
  const buttonsEnt = (cell) => {
    const delIcon = document.createElement('img')
    delIcon.type = 'icon'
    delIcon.src = trash
    delIcon.style = 'width:24px;height:24px'

    //create delete button
    var delBt = document.createElement('button')
    delBt.type = 'button'
    delBt.appendChild(delIcon)
    delBt.classList.add('btn')
    delBt.style = 'padding:8px 10px; border:1px solid #CBD5E1 '
    delBt.addEventListener('click', function () {
      delRowEnt(cell)
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(delBt)

    return buttonHolder
  }

  const columns = [
    { title: 'Lot', field: 'lotTitre', formatter: simpleInput },
    { title: 'Société', field: 'entrepriseNom', formatter: simpleInput },
    {
      title: 'Nom',
      field: 'name',
      formatter: simpleInput,
    },
    {
      title: 'Prénom',
      field: 'lastname',
      formatter: simpleInput,
    },
    {
      title: 'Email',
      field: 'email',
      formatter: simpleInput,
    },
    {
      title: 'Téléphone',
      field: 'telephone',
      formatter: simpleInput,
    },
    {
      title: '',
      field: 'functionButton',
      formatter: buttonsEnt,
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {},
    },
  ]
  const columnUser = [
    {
      title: 'Rôle',
      field: 'role.roleName',
      editor: 'select',
      editorParams: {
        values: {
          'Maitrise d’ouvrage': 'Maitrise d’ouvrage',
          'Maitrise d’œuvre': 'Maitrise d’œuvre',
          'Assistance à maitrise d’ouvrage': 'Assistance à maitrise d’ouvrage',
          Architecte: 'Architecte',
          'Maitrise d’œuvre d’exécution': 'Maitrise d’œuvre d’exécution',
          'D.E.T.': 'D.E.T.',
          'O.P.C.': 'O.P.C.',
          'Bureau d’études techniques': 'Bureau d’études techniques',
          'Bureau de contrôle': 'Bureau de contrôle',
          'Coordonnateur SPS': 'Coordonnateur SPS',
          'Coordonnateur SSI': 'Coordonnateur SSI',
          //ent: 'Entreprise de construction',
          'Utilisateur simple': 'Utilisateur simple',
        },
      },
    },
    {
      title: 'Société',
      field: 'entrepriseName',
      formatter: simpleInput,
    },
    {
      title: 'Nom',
      field: 'name',
      formatter: simpleInput,
    },
    {
      title: 'Prénom',
      field: 'lastname',
      formatter: simpleInput,
    },
    {
      title: 'Email',
      field: 'email',
      formatter: simpleInput,
    },
    {
      title: 'Téléphone',
      field: 'telephone',
      formatter: simpleInput,
    },
    {
      title: '',
      field: 'functionButton',
      formatter: buttons,
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {},
    },
  ]

  const edited = async (cell) => {
    const { ...cellData } = cell.getData()
    var { roleName } = cellData.role
    if (roleName == 'Non Défini') {
      roleName = 'Utilisateur simple'
    }
    const { data } = await getAllRole(
      `roleName=${roleName}`,
      infoUser.token,
      infoChantier.planning
    ).then((res) => {
      return res
    })
    const { idLot } = cellData
    const { lot } = cellData
    const { entreprise } = cellData
    if (lot) {
      await updateData(
        { lot: lot, _id: idLot, planning: infoChantier.planning },
        'lots',
        infoUser.token,
        infoChantier.planning
      ).then((e) => {})
    }
    if (entreprise) {
      await updateData(
        entreprise,
        `entreprises`,
        infoUser.token,
        infoChantier.planning
      ).then((e) => {})
    }
    updateContact(cellData, infoUser.token, infoChantier.planning)
      .then((e) => {
        if (e.name == 'AxiosError') {
          addToast(
            e.response?.data?.message
              ? e.response.data.message
              : "Une erreur s'est produit",
            {
              appearance: 'error',
              autoDismiss: true,
            }
          )
          setBool(!bool)
        } else {
          if (e.data) {
            cell.getRow().update(e.data)
          }
        }
      })
      .catch((err) => setBool(!bool))
  }
  const entEdited = async (cell) => {
    const data = cell.getData()
    console.log(data);
    await axios
      .patch(
        `${process.env.REACT_APP_URL}/api/v1/users/${data._id}?planning=${infoChantier.planning}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${infoUser.token}`,
          },
        }
      )
      .then((res) => {
        if(res.data.data){
          cell.getRow().update(res.data.data)
        }
      })
      .catch((err) => {
        console.log(err)
        setBool(!bool)
      })
  }
  const addRow = async () => {
    setWait(true)
    const { data } = await getAllRole(
      `roleType=user`,
      infoUser.token,
      infoChantier.planning
    )
    if (!data || data.length === 0) {
      alert('Error: No role found')
    }
    await axios
      .get(
        `${process.env.REACT_APP_MDP_API}/create/?include_digits&include_uppercase&include_lowercase&password_length=8&quantity=1&add_custom_characters=!@#%?`
      )
      .then((e) => {
        const formData = new FormData()
        formData.append('email', `newuser${Date.now()}@mail.com`)
        formData.append('role', data[0]._id)
        formData.append('telephone', 'Non défini')
        formData.append('lastname', 'Non défini')
        formData.append('name', 'Non défini')
        formData.append('entrepriseName', 'Non défini')
        formData.append('password', e.data.passwords[0])
        formData.append('passwordConfirm', e.data.passwords[0])
        addContact(formData, infoUser.token, infoChantier.planning)
          .then((e) => {
            const inters = []
            inters.push(...intervenant)
            inters.push({ intervenant: e._id })
            addIntervenant(
              inters,
              idIntervenant,
              infoUser.token,
              infoChantier.planning
            )
              .then((el) => {
                setWait(false)
                setBool(!bool)
              })
              .catch((e) => {
                setWait(false)
              })
          })
          .catch((e) => {
            setWait(false)
          })
      })
  }
  const saveIntervenant = async () => {
    const formData = new FormData()
    formData.append('email', mail)
    formData.append('role', roleSelected)
    formData.append('telephone', tel)
    formData.append('lastname', prenom)
    formData.append('name', nom)
    formData.append('entrepriseName', societe)
    formData.append('chantierId', infoChantier._id)
    await addContact(formData, infoUser.token, infoChantier.planning)
      .then((res) => {
        console.log(res)
        if (res.name == 'AxiosError') {
          addToast(
            res.response?.data?.message
              ? res.response.data.message
              : "Une erreur s'est produit",
            {
              appearance: 'error',
              autoDismiss: true,
            }
          )
        } else {
          addToast('Enregistrement avec succès.', {
            appearance: 'success',
            autoDismiss: true,
          })
        }
        setBool(!bool)
      })
      .catch((err) => {
        if (err.name == 'AxiosError') {
          addToast(
            res.response?.data?.message
              ? res.response.data.message
              : "Une erreur s'est produit",
            {
              appearance: 'error',
              autoDismiss: true,
            }
          )
        } else {
          addToast("une erreur s'est produit", {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      })
  }
  const saveEntreprise = async () => {
    const newRole = listRole.find((el) => el.roleType == 'ent')
    console.log(newRole)
    const formData = new FormData()
    formData.append('email', mail)
    formData.append('role', newRole?._id)
    formData.append('telephone', tel)
    formData.append('lastname', prenom)
    formData.append('name', nom)
    formData.append('entrepriseName', entSelected?.nom)
    formData.append('entrepriseId', entSelected?._id)
    formData.append('chantierId', infoChantier._id)
    await addContact(formData, infoUser.token, infoChantier.planning)
      .then((res) => {
        console.log(res)
        if (res.name == 'AxiosError') {
          addToast(
            res.response?.data?.message
              ? res.response.data.message
              : "Une erreur s'est produit",
            {
              appearance: 'error',
              autoDismiss: true,
            }
          )
        } else {
          addToast('Enregistrement avec succès.', {
            appearance: 'success',
            autoDismiss: true,
          })
        }
        setBool(!bool)
      })
      .catch((err) => {
        if (err.name == 'AxiosError') {
          addToast(
            res.response?.data?.message
              ? res.response.data.message
              : "Une erreur s'est produit",
            {
              appearance: 'error',
              autoDismiss: true,
            }
          )
        } else {
          addToast("une erreur s'est produit", {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      })
  }
  const saveContacSeul = async () => {
    const formData = new FormData()
    formData.append('email', mail)
    formData.append('role', roleSelected)
    formData.append('telephone', tel)
    formData.append('lastname', prenom)
    formData.append('name', nom)
    formData.append('societe', societe)
    formData.append('chantierId', infoChantier._id)
    await addContact(formData, infoUser.token, infoChantier.planning)
      .then((res) => {
        console.log(res)
        if (res.name == 'AxiosError') {
          addToast(
            res.response?.data?.message
              ? res.response.data.message
              : "Une erreur s'est produit",
            {
              appearance: 'error',
              autoDismiss: true,
            }
          )
        } else {
          addToast('Enregistrement avec succès.', {
            appearance: 'success',
            autoDismiss: true,
          })
        }
        setBool(!bool)
      })
      .catch((err) => {
        if (err.name == 'AxiosError') {
          addToast(
            res.response?.data?.message
              ? res.response.data.message
              : "Une erreur s'est produit",
            {
              appearance: 'error',
              autoDismiss: true,
            }
          )
        } else {
          addToast("une erreur s'est produit", {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      })
  }
  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={true} active="contact" />
        <Header bool={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <span style={{ marginLeft: '10px' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="#94A3B8"
                      style={{ width: '16px', height: '16px' }}>
                      <path
                        fillRule="evenodd"
                        d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                        clipRule="evenodd"
                      />
                    </svg>{' '}
                    <span
                      style={{
                        color: '#334155',
                        fontSize: '14px',
                        fontWeight: '600',
                      }}>
                      {' '}
                      / Contacts
                    </span>
                  </span>{' '}
                  <br />
                  <span
                    style={{
                      marginLeft: '15px',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <span
                      style={{
                        backgroundColor: '#E2E8F0',
                        padding: '2px',
                        borderRadius: '6px',
                      }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="#6366F1"
                        style={{
                          width: '20px',
                          height: '20px',
                          cursor: 'pointer',
                        }}
                        onClick={(e) => {
                          e.preventDefault()
                          navigate('/Accueil')
                        }}>
                        <path
                          fillRule="evenodd"
                          d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                    <span
                      style={{
                        color: '#334155',
                        fontSize: '20px',
                        fontWeight: '600',
                        marginLeft: '10px',
                      }}>
                      {' '}
                      Contacts
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div
              style={{
                width: '100%',
                backgroundColor: '#FFFFFF',
                border: '1px solid #E2E8F0',
                borderRadius: '8px',
                boxShadow:
                  '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                marginTop: '12px',
              }}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '16px 24px',
                }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span
                    style={{
                      color: '#334155',
                      fontSize: '20px',
                      fontWeight: '600',
                    }}>
                    Entreprises
                  </span>
                  <span
                    style={{
                      backgroundColor: '#E0E7FF',
                      borderRadius: '24px',
                      padding: '6px 12px',
                      color: '#64748B',
                      fontSize: '14px',
                      fontWeight: '600',
                      marginLeft: '8px',
                      maxHeight: '32px',
                      width: 'auto',
                    }}>
                    {contact.length > 0 ? contact.length : '0'} Entreprises
                  </span>
                </div>
                <div>
                  <span
                    style={{
                      padding: '8px 12px 8px 12px',
                      backgroundColor: 'rgba(226, 232, 240, 0.6)',
                      borderRadius: '8px',
                      height: '40px',
                    }}>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#818CF8"
                        style={{ width: '18px', height: '18px' }}>
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                        />
                      </svg>
                    </span>
                    <input
                      type="text"
                      placeholder="Recherche"
                      style={{
                        border: 'none',
                        backgroundColor: 'transparent',
                      }}
                      onChange={(ev) => {
                        ev.preventDefault()
                        ev.stopPropagation()
                        const newData = searchOptimizedData(
                          ev.target.value,
                          contact
                        )
                        setContact(newData)
                      }}
                    />
                  </span>
                </div>
              </div>
              <div
                style={{
                  width: '100%',
                  borderTop: 'solid #E2E8F0 1px',
                  marginTop: '16px',
                }}>
                <ReactTabulator
                  data={contact}
                  columns={columns}
                  layout={'fitColumns'}
                  events={{ cellEdited: entEdited }}
                  options={{
                    locale: true,
                    pagination: 'local',
                    paginationSize: 5,
                    dataTree: true,
                    langs: {
                      fr: {
                        pagination: {
                          first: 'Premier',
                          first_title: 'Première',
                          last: 'Dernier',
                          last_title: 'Dernière',
                          prev: 'Précédent',
                          prev_title: 'Précédente',
                          next: 'Suivant',
                          next_title: 'Page suivante',
                          page_size: 'Taille de page',
                          page_title: 'Afficher la page',
                          all: 'Tous',
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div
              style={{
                width: '100%',
                backgroundColor: '#FFFFFF',
                border: '1px solid #E2E8F0',
                borderRadius: '8px',
                boxShadow:
                  '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                marginTop: '32px',
              }}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '16px 24px',
                }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span
                    style={{
                      color: '#334155',
                      fontSize: '20px',
                      fontWeight: '600',
                    }}>
                    Intervenants
                  </span>
                  <span
                    style={{
                      backgroundColor: '#E0E7FF',
                      borderRadius: '24px',
                      padding: '6px 12px',
                      color: '#64748B',
                      fontSize: '14px',
                      fontWeight: '600',
                      marginLeft: '8px',
                      maxHeight: '32px',
                      width: 'auto',
                    }}>
                    {users.length > 0 && contact.length > 0
                      ? users.length - contact.length - 1
                      : '0'}{' '}
                    Intervenants
                  </span>
                </div>
                <div>
                  <span
                    style={{
                      padding: '8px 12px 8px 12px',
                      backgroundColor: 'rgba(226, 232, 240, 0.6)',
                      borderRadius: '8px',
                      height: '40px',
                    }}>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#818CF8"
                        style={{ width: '18px', height: '18px' }}>
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                        />
                      </svg>
                    </span>
                    <input
                      type="text"
                      placeholder="Recherche"
                      style={{
                        border: 'none',
                        backgroundColor: 'transparent',
                      }}
                      onChange={(ev) => {
                        ev.preventDefault()
                        ev.stopPropagation()
                        const newData = searchOptimizedData(
                          ev.target.value,
                          users
                        )
                        setUsers(newData)
                      }}
                    />
                  </span>
                  {(infoUser.user?.role?.roleType == 'admin') |
                    (infoUser.user?.role?.roleType == 'opc') |
                    (infoUser.user?.role?.roleType == 'moex') && (
                    <>
                      <button
                        className="btn"
                        data-bs-toggle="modal"
                        data-bs-target="#myModal"
                        style={{
                          backgroundColor: '#4F46E5',
                          color: '#F8FAFC',
                          marginLeft: '24px',
                        }}
                        /* onClick={(e) => addRow()} */
                      >
                        + Nouveau contact
                      </button>
                      <div class="modal" id="myModal">
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div
                              class="modal-header"
                              style={{ backgroundColor: '#EBEFF5' }}>
                              <h4
                                class="modal-title"
                                style={{ color: '#334155' }}>
                                Ajouter un contact
                              </h4>
                              <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"></button>
                            </div>

                            <form
                              onSubmit={(ev) => {
                                ev.preventDefault()
                                if (userToCreate == 1) {
                                  saveIntervenant()
                                }
                                if (userToCreate == 2) {
                                  saveEntreprise()
                                }
                                if (userToCreate == 3) {
                                  saveContacSeul()
                                }
                              }}>
                              <div class="modal-body">
                                <ul class="nav nav-tabs">
                                  <li
                                    class="nav-item"
                                    onClick={(ev) => {
                                      setUserToCreate(1)
                                      setNom('')
                                      setPrenom('')
                                      setTel('')
                                      setMail('')
                                      setRoleSelected('')
                                      setSociete('')
                                    }}>
                                    <a
                                      class="nav-link active"
                                      data-bs-toggle="tab"
                                      href="#home">
                                      Intervenant
                                    </a>
                                  </li>
                                  <li
                                    class="nav-item"
                                    onClick={(ev) => {
                                      setUserToCreate(2)
                                      setNom('')
                                      setPrenom('')
                                      setTel('')
                                      setMail('')
                                      setRoleSelected('')
                                      setSociete('')
                                    }}>
                                    <a
                                      class="nav-link"
                                      data-bs-toggle="tab"
                                      href="#menu1">
                                      Entreprise
                                    </a>
                                  </li>
                                  <li
                                    class="nav-item"
                                    onClick={(ev) => {
                                      setUserToCreate(3)
                                      setNom('')
                                      setPrenom('')
                                      setTel('')
                                      setMail('')
                                      setRoleSelected('')
                                      setSociete('')
                                    }}>
                                    <a
                                      class="nav-link"
                                      data-bs-toggle="tab"
                                      href="#menu2">
                                      Contact seul
                                    </a>
                                  </li>
                                </ul>
                                {userToCreate == 1 && (
                                  <>
                                    <div class="mb-3 mt-3">
                                      <label for="nom" class="form-label">
                                        Nom:
                                      </label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Nom"
                                        defaultValue={nom}
                                        onChange={(ev) =>
                                          setNom(ev.target.value)
                                        }
                                        required
                                      />
                                    </div>
                                    <div class="mb-3">
                                      <label for="nom" class="form-label">
                                        Prénom:
                                      </label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Prénom"
                                        defaultValue={prenom}
                                        onChange={(ev) =>
                                          setPrenom(ev.target.value)
                                        }
                                        required
                                      />
                                    </div>
                                    <div class="mb-3">
                                      <label for="nom" class="form-label">
                                        Téléphone:
                                      </label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Téléphone"
                                        defaultValue={tel}
                                        onChange={(ev) =>
                                          setTel(ev.target.value)
                                        }
                                        required
                                      />
                                    </div>
                                    <div class="mb-3">
                                      <label for="nom" class="form-label">
                                        Société:
                                      </label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Société"
                                        defaultValue={societe}
                                        onChange={(ev) =>
                                          setSociete(ev.target.value)
                                        }
                                        required
                                      />
                                    </div>
                                    <div class="mb-3">
                                      <label for="email" class="form-label">
                                        Email:
                                      </label>
                                      <input
                                        type="email"
                                        class="form-control"
                                        id="email"
                                        placeholder="Email"
                                        defaultValue={mail}
                                        onChange={(ev) =>
                                          setMail(ev.target.value)
                                        }
                                        required
                                      />
                                    </div>
                                    <div class="mb-3">
                                      <label for="nom" class="form-label">
                                        Rôle:
                                      </label>
                                      <select
                                        name=""
                                        id=""
                                        className="form-select"
                                        onChange={(ev) =>
                                          setRoleSelected(ev.target.value)
                                        }
                                        required>
                                        <option value={null}>
                                          Sélectionner un rôle
                                        </option>
                                        {listRole?.length > 0 &&
                                          listRole.map((role) => {
                                            if (role.roleType !== 'ent') {
                                              return (
                                                <>
                                                  <option value={role._id}>
                                                    {role.roleName}
                                                  </option>
                                                </>
                                              )
                                            }
                                          })}
                                      </select>
                                    </div>
                                  </>
                                )}
                                {userToCreate == 2 && (
                                  <>
                                    <div class="mb-3 mt-3">
                                      <label for="nom" class="form-label">
                                        Nom:
                                      </label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Nom"
                                        defaultValue={nom}
                                        onChange={(ev) =>
                                          setNom(ev.target.value)
                                        }
                                        required
                                      />
                                    </div>
                                    <div class="mb-3">
                                      <label for="nom" class="form-label">
                                        Prénom:
                                      </label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Prénom"
                                        defaultValue={prenom}
                                        onChange={(ev) =>
                                          setPrenom(ev.target.value)
                                        }
                                        required
                                      />
                                    </div>
                                    <div class="mb-3">
                                      <label for="nom" class="form-label">
                                        Téléphone:
                                      </label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Téléphone"
                                        defaultValue={tel}
                                        onChange={(ev) =>
                                          setTel(ev.target.value)
                                        }
                                        required
                                      />
                                    </div>
                                    <div class="mb-3">
                                      <label for="email" class="form-label">
                                        Email:
                                      </label>
                                      <input
                                        type="email"
                                        class="form-control"
                                        id="email"
                                        placeholder="Email"
                                        name="email"
                                        defaultValue={mail}
                                        onChange={(ev) =>
                                          setMail(ev.target.value)
                                        }
                                        required
                                      />
                                    </div>
                                    <div class="mb-3">
                                      <label
                                        for="nom"
                                        class="form-label"
                                        required>
                                        Liste entreprise:
                                      </label>
                                      <select
                                        name=""
                                        id=""
                                        className="form-select"
                                        onChange={(ev) => {
                                          const data = JSON.parse(
                                            ev.target.value
                                          )
                                          setEntSelected(data)
                                        }}>
                                        <option value={null}>
                                          Sélectionner une entreprise
                                        </option>
                                        {listEnt?.length > 0 &&
                                          listEnt.map((ent) => {
                                            return (
                                              <>
                                                <option
                                                  value={JSON.stringify(ent)}>
                                                  {ent.nom}
                                                </option>
                                              </>
                                            )
                                          })}
                                      </select>
                                    </div>
                                  </>
                                )}
                                {userToCreate == 3 && (
                                  <>
                                    <div class="mb-3 mt-3">
                                      <label for="nom" class="form-label">
                                        Nom:
                                      </label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Nom"
                                        defaultValue={nom}
                                        onChange={(ev) =>
                                          setNom(ev.target.value)
                                        }
                                        required
                                      />
                                    </div>
                                    <div class="mb-3">
                                      <label for="nom" class="form-label">
                                        Prénom:
                                      </label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Prénom"
                                        defaultValue={prenom}
                                        onChange={(ev) =>
                                          setPrenom(ev.target.value)
                                        }
                                        required
                                      />
                                    </div>
                                    <div class="mb-3">
                                      <label for="nom" class="form-label">
                                        Téléphone:
                                      </label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Téléphone"
                                        defaultValue={tel}
                                        onChange={(ev) =>
                                          setTel(ev.target.value)
                                        }
                                        required
                                      />
                                    </div>
                                    <div class="mb-3">
                                      <label for="email" class="form-label">
                                        Email:
                                      </label>
                                      <input
                                        type="email"
                                        class="form-control"
                                        id="email"
                                        placeholder="Email"
                                        defaultValue={mail}
                                        onChange={(ev) =>
                                          setMail(ev.target.value)
                                        }
                                        required
                                      />
                                    </div>
                                    <div class="mb-3">
                                      <label for="nom" class="form-label">
                                        Liste société:
                                      </label>
                                      <select
                                        name=""
                                        id=""
                                        className="form-select"
                                        defaultValue={societe}
                                        onChange={(ev) =>
                                          setSociete(ev.target.value)
                                        }
                                        required>
                                        <option value={null}>
                                          Sélectionner une société
                                        </option>
                                        {listSociete?.length > 0 &&
                                          listSociete.map((soc) => {
                                            return (
                                              <>
                                                <option value={soc}>
                                                  {soc}
                                                </option>
                                              </>
                                            )
                                          })}
                                      </select>
                                    </div>
                                    <div class="mb-3">
                                      <label for="nom" class="form-label">
                                        Rôle:
                                      </label>
                                      <select
                                        name=""
                                        id=""
                                        onChange={(ev) =>
                                          setRoleSelected(ev.target.value)
                                        }
                                        className="form-select"
                                        required>
                                        <option value={null}>
                                          Sélectionner un rôle
                                        </option>
                                        {listRole?.length > 0 &&
                                          listRole.map((role) => {
                                            if (role.roleType !== 'ent') {
                                              return (
                                                <>
                                                  <option value={role._id}>
                                                    {role.roleName}
                                                  </option>
                                                </>
                                              )
                                            }
                                          })}
                                      </select>
                                    </div>
                                  </>
                                )}
                              </div>
                              <div class="modal-footer">
                                <button
                                  type="submit"
                                  class="btn"
                                  data-bs-dismiss="modal"
                                  style={{
                                    backgroundColor: '#4F46E5',
                                    color: '#F8FAFC',
                                    marginLeft: '24px',
                                  }}>
                                  Enregistrer
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div
                style={{
                  width: '100%',
                  borderTop: 'solid #E2E8F0 1px',
                  marginTop: '16px',
                }}>
                <ReactTabulator
                  data={
                    users
                      ? users.map((el) => {
                          if (
                            el.role?.roleType == 'admin' ||
                            el.role?.roleType == 'ent'
                          ) {
                          } else {
                            return el
                          }
                        })
                      : []
                  }
                  columns={columnUser}
                  layout={'fitColumns'}
                  events={{ cellEdited: edited }}
                  options={{
                    locale: true,
                    pagination: 'local',
                    paginationSize: 5,
                    langs: {
                      fr: {
                        pagination: {
                          first: 'Premier',
                          first_title: 'Première',
                          last: 'Dernier',
                          last_title: 'Dernière',
                          prev: 'Précédent',
                          prev_title: 'Précédente',
                          next: 'Suivant',
                          next_title: 'Page suivante',
                          page_size: 'Taille de page',
                          page_title: 'Afficher la page',
                          all: 'Tous',
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {wait && (
        <>
          <LoaderFixed />
        </>
      )}
    </>
  )
}

export default Contacte
