import React, { useState, useEffect } from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Clock } from '../../../assets/icons/clock.svg'
import imgUser from '../../../assets/images/sooda_logo/default.png'
import LoaderFixed from '../../../components/loader/LoaderFixed'
import { getAllData } from '../../../helpers/suivi/suivi'
import PageNotFound from '../../../components/404/PageNotFound'
import { useToasts } from 'react-toast-notifications'
import axios from 'axios'
//Liste entreprise du suivi opérationnel
const ListeEntreprise = () => {
  const { addToast } = useToasts()
  if (!localStorage.getItem('isLogin')) {
    return <PageNotFound />
  }
  const [wait, setWait] = useState(false)
  //const [penalites, setPenalite] = useState(statut)

  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const [item, setItem] = useState('Entreprises')
  const [item1, setItem1] = useState(false)
  const [listCarte, setListCarte] = useState([])
  const navigate = useNavigate()
  const [lots, setLot] = useState([])
  const [intervenants, setIntervenants] = useState([])
  const [typeCarte, setTypeCarte] = useState('Sélectionner une carte')
  const [carteSelected, setCarteSelected] = useState('')
  const [selected, setSelected] = useState(true)
  const [listResponsable, setListResponsable] = useState([])
  const [responsable, setResponsable] = useState('Sélectionner un responsable')
  const [responsableSelected, setResponsableSelected] = useState()
  const [exist, setExist] = useState(false)
  const [bool, setBool] = useState(true)
  const [nom, setNom] = useState('')
  const [prenom, setPrenom] = useState('')
  const [email, setEmail] = useState('')
  const [tel, setTel] = useState('')
  const [societe, setSociete] = useState('')
  useEffect(() => {
    getAllData(
      `planning=${infoChantier.planning}`,
      'cartes',
      infoUser.token
    ).then((res) => {
      setIntervenants(res.data)
    })
  }, [bool])
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_URL}/api/v1/cartes/getUnselectedCartes/${infoChantier.planning}?planning=${infoChantier.planning}`,
        { headers: { Authorization: `Bearer ${infoUser.token}` } }
      )
      .then((res) => {
        if (res.data.data) {
          const list = res.data.data
          const listFiltered = list.filter((el) => el.roleType !== 'user')
          setListCarte(listFiltered)
        }
      })
  }, [bool])
  useEffect(() => {
    setWait(true)
    if (localStorage.getItem('itemSuiviOp')) {
      setItem(localStorage.getItem('itemSuiviOp'))
    }
    getAllData(
      `planning=${infoChantier.planning}`,
      'lots',
      infoUser.token
    ).then((result) => {
      if (result.data) {
        let listLot = result.data
        const generalite = listLot.find(
          (element) => element.generalite === true
        )
        if (generalite) {
          const sansGeneralite = listLot.filter(
            (element) => element.generalite !== true
          )
          sansGeneralite.unshift(generalite)
          setLot(sansGeneralite)
        }
      }
      setWait(false)
    })
  }, [bool])

  return (
    <>
      <div class="d-flex flex-column">
        <Menu bool={true} active="suivi" />
        <Header bool={true} />
      </div>
      <div class="page-wrapper">
        <div class="page-content-tab">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="float-end"></div>
                  <span style={{ marginLeft: '10px' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="#94A3B8"
                      style={{ width: '16px', height: '16px' }}>
                      <path
                        fillRule="evenodd"
                        d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                        clipRule="evenodd"
                      />
                    </svg>{' '}
                    <span
                      style={{
                        color: '#334155',
                        fontSize: '14px',
                        fontWeight: '600',
                      }}>
                      / Suivi opérationnel
                    </span>
                  </span>{' '}
                  <br />
                  <span
                    style={{
                      marginLeft: '15px',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <span
                      style={{
                        backgroundColor: '#E2E8F0',
                        padding: '2px',
                        borderRadius: '6px',
                      }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="#6366F1"
                        style={{
                          width: '20px',
                          height: '20px',
                          cursor: 'pointer',
                        }}
                        onClick={(e) => {
                          e.preventDefault()
                          navigate('/accueil')
                        }}>
                        <path
                          fillRule="evenodd"
                          d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                    <span
                      style={{
                        color: '#334155',
                        fontSize: '20px',
                        fontWeight: '600',
                        marginLeft: '10px',
                      }}>
                      {' '}
                      Suivi opérationnel
                    </span>
                  </span>
                </div>
                {/* liste debut  */}
                <div
                  className="row"
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '0px 20px',
                  }}>
                  <div style={{ width: '200px' }}>
                    <button
                      data-bs-toggle="dropdown"
                      className="btn"
                      style={{
                        backgroundColor: 'white',
                        color: '#334155',
                        marginLeft: '12px',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}>
                      <span>{item}</span> <i className="ti ti-selector"></i>
                    </button>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1">
                      <li
                        onClick={(ev) => {
                          ev.preventDefault()
                          setItem('Tous')
                          localStorage.setItem('itemSuiviOp', 'Tous')
                        }}>
                        <a className="dropdown-item">Tous</a>
                      </li>
                      <li
                        onClick={(ev) => {
                          ev.preventDefault()
                          setItem('Entreprises')
                          localStorage.setItem('itemSuiviOp', 'Entreprises')
                        }}>
                        <a className="dropdown-item">Entreprises</a>
                      </li>
                      <li
                        onClick={(ev) => {
                          ev.preventDefault()
                          setItem('Intervenants')
                          localStorage.setItem('itemSuiviOp', 'Intervenants')
                        }}>
                        <a className="dropdown-item">Intervenants</a>
                      </li>
                    </ul>
                  </div>
                </div>
                {item == 'Entreprises' && (
                  <>
                    <div className="row">
                      <div
                        class="col-lg-4"
                        style={{ cursor: 'pointer' }}
                        onClick={(el) => {
                          el.preventDefault()
                          navigate('/information_generale')
                        }}>
                        <div
                          class="card"
                          style={{
                            borderRadius: '8px',
                            boxShadow:
                              '0px 6.15px 12.31px rgba(0, 0, 0, 0.02), 0px 6.15px 6.15px rgba(0, 0, 0, 0.04)',
                            padding: '16px 24px',
                            margin: '10px 10px',
                            minHeight: '220px',
                          }}>
                          <div
                            className="container"
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}>
                            <span
                              style={{
                                color: '#334155',
                                fontSize: '18px',
                                fontWeight: '600',
                              }}>
                              Administratif et généralités
                            </span>
                          </div>
                          <div
                            className="container"
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}></div>
                        </div>
                      </div>
                      {lots &&
                        lots.map((lot) => {
                          if (lot) {
                            return (
                              <>
                                <div
                                  class="col-lg-4"
                                  style={{ cursor: 'pointer' }}
                                  onClick={(el) => {
                                    el.preventDefault()
                                    localStorage.setItem(
                                      '_id',
                                      lot.entreprise?._id
                                    )
                                    localStorage.setItem('titreLot', lot.titre)
                                    localStorage.setItem('_idLot', lot._id)
                                    if (
                                      infoUser?.user?.role?.roleType ==
                                        'admin' ||
                                      infoUser?.user?.role?.roleType == 'opc' ||
                                      infoUser?.user?.role?.roleType == 'moex'
                                    ) {
                                      navigate(`/SuiviOperationnel_Profil`)
                                    }
                                  }}>
                                  <div
                                    class="card"
                                    style={{
                                      borderRadius: '8px',
                                      boxShadow:
                                        '0px 6.15px 12.31px rgba(0, 0, 0, 0.02), 0px 6.15px 6.15px rgba(0, 0, 0, 0.04)',
                                      padding: '16px 24px',
                                      margin: '10px 10px',
                                    }}>
                                    <div
                                      className="container"
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                      }}>
                                      <span
                                        style={{
                                          color: '#334155',
                                          fontSize: '18px',
                                          fontWeight: '600',
                                        }}>
                                        Lot : {lot ? lot.titre : '---'}
                                      </span>
                                      <span
                                        style={
                                          lot.globalProgressDetails?.color
                                            ? {
                                                padding: '4px 12px',
                                                borderRadius: '24px',
                                                backgroundColor: `${lot.globalProgressDetails.color}`,
                                                color: 'white',
                                                width: '65px',
                                                textAlign: 'center',
                                                fontWeight: 'bold',
                                                maxHeight: '28px',
                                              }
                                            : {
                                                padding: '4px 12px',
                                                borderRadius: '24px',
                                                backgroundColor: '#FB7185',
                                                color: 'white',
                                                width: '65px',
                                                textAlign: 'center',
                                                fontWeight: 'bold',
                                                maxHeight: '28px',
                                              }
                                        }>
                                        {lot.globalProgressDetails
                                          ?.pourcentageGlobal
                                          ? lot.globalProgressDetails?.pourcentageGlobal.toFixed(
                                              0
                                            )
                                          : 0}
                                        %
                                      </span>
                                    </div>
                                    <div
                                      className="container"
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: '#64748B',
                                        fontSize: '14px',
                                        fontWeight: '600',
                                        marginTop: '16px',
                                        marginBottom: '16px',
                                      }}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="#64748B"
                                        style={{
                                          width: '24px',
                                          height: '24px',
                                        }}>
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                        />
                                      </svg>
                                      Début :{' '}
                                      {lot?.info?.startEnd?.start_date
                                        ? lot.info.startEnd.start_date
                                        : 'JJ/MM/AAAA'}
                                    </div>
                                    <div
                                      className="container"
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                      }}>
                                      <span>Avancements</span>
                                      <span style={{ color: '#4338CA' }}>
                                        {lot?.info?.progress
                                          ? (lot.info.progress * 100).toFixed(0)
                                          : '0'}
                                        %
                                      </span>
                                    </div>
                                    <div className="container">
                                      <div
                                        style={{
                                          width: '100%',
                                          position: 'relative',
                                          height: '8px',
                                          backgroundColor: '#C7D2FE',
                                          borderRadius: '4px',
                                          marginBottom: '32px',
                                        }}>
                                        <div
                                          style={{
                                            position: 'absolute',
                                            color: '#6366F1',
                                            left: '0',
                                            top: '0',
                                            bottom: '0',
                                            backgroundColor: '#6366F1',
                                            width: `${
                                              lot?.info?.progress
                                                ? lot.info.progress * 100
                                                : 0
                                            }%`,
                                            borderRadius: '4px',
                                          }}></div>
                                      </div>
                                    </div>
                                    <div
                                      className="container"
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                      }}>
                                      <span
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}>
                                        {lot.titre != 'Généralités' && (
                                          <>
                                            <span>
                                              <img
                                                src={
                                                  lot.entreprise?.photo
                                                    ? imgUser
                                                    : imgUser
                                                }
                                                alt=""
                                                style={{
                                                  width: '32px',
                                                  height: '32px',
                                                  borderRadius: '50%',
                                                }}
                                              />
                                            </span>
                                            <span
                                              style={{
                                                color: '#334155',
                                                fontSize: '14px',
                                                fontWeight: '600',
                                                marginLeft: '5px',
                                              }}>
                                              {lot.entreprise?.responsable?.name
                                                ? lot.entreprise?.responsable
                                                    ?.name
                                                : ''}{' '}
                                              {lot.entreprise?.responsable
                                                ?.lastname
                                                ? lot.entreprise?.responsable
                                                    ?.lastname
                                                : ''}{' '}
                                              <br />
                                              <span
                                                style={{
                                                  color: '#64748B',
                                                  fontSize: '14px',
                                                  fontWeight: '400',
                                                }}>
                                                Responsable
                                              </span>
                                            </span>
                                          </>
                                        )}
                                      </span>
                                      <span
                                        style={{
                                          padding: '6px 12px',
                                          textAlign: 'center',
                                          backgroundColor: '#FEF3C7',
                                          borderRadius: '4px',
                                          color: '#F59E0B',
                                        }}>
                                        Deadline:
                                        {lot?.info?.startEnd?.end_date
                                          ? lot.info.startEnd.end_date
                                          : 'JJ/MM/AAAA'}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                          }
                        })}
                    </div>
                  </>
                )}
                {item == 'Intervenants' && (
                  <>
                    <div className="row">
                      <div class="col-lg-4" style={{ cursor: 'pointer' }}>
                        <div
                          class="card"
                          style={{
                            borderRadius: '8px',
                            boxShadow:
                              '0px 6.15px 12.31px rgba(0, 0, 0, 0.02), 0px 6.15px 6.15px rgba(0, 0, 0, 0.04)',
                            padding: '26px 24px',
                            margin: '10px 10px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'rgba(226, 232, 240, 0.4)',
                          }}
                          data-bs-toggle="modal"
                          data-bs-target="#myModal">
                          <span
                            style={{
                              fontWeight: '600',
                              fontSize: '18px',
                              color: '#334155',
                            }}>
                            Nouvelle carte
                          </span>
                          <div
                            style={{
                              border: 'dashed 1px #334155',
                              borderRadius: '8px',
                              padding: '10px 10px',
                            }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="#334155"
                              style={{ width: '90px', height: '90px' }}>
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M12 4.5v15m7.5-7.5h-15"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      {intervenants &&
                        intervenants.map((el) => {
                          if (el) {
                            return (
                              <>
                                <div
                                  class="col-lg-4"
                                  style={{ cursor: 'pointer' }}
                                  onClick={(ev) => {
                                    ev.preventDefault()
                                    localStorage.setItem(
                                      '_idIntervenant',
                                      el._id
                                    )
                                    localStorage.setItem('titreLot', el.titre)
                                    navigate(
                                      `/SuiviOperationnel_Profil_Intervenant`
                                    )
                                  }}>
                                  <div
                                    class="card"
                                    style={{
                                      borderRadius: '8px',
                                      boxShadow:
                                        '0px 6.15px 12.31px rgba(0, 0, 0, 0.02), 0px 6.15px 6.15px rgba(0, 0, 0, 0.04)',
                                      padding: '16px 24px',
                                      margin: '10px 10px',
                                    }}>
                                    <div
                                      className="container"
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                      }}>
                                      <span
                                        style={{
                                          color: '#334155',
                                          fontSize: '18px',
                                          fontWeight: '600',
                                        }}>
                                        {el.titre ? el.titre : 'Non défini'}
                                      </span>
                                      <span
                                        style={
                                          el.globalProgressDetails?.color
                                            ? {
                                                padding: '4px 12px',
                                                borderRadius: '24px',
                                                backgroundColor: `${el.globalProgressDetails.color}`,
                                                color: 'white',
                                                width: '65px',
                                                textAlign: 'center',
                                                fontWeight: 'bold',
                                                maxHeight: '28px',
                                              }
                                            : {
                                                padding: '4px 12px',
                                                borderRadius: '24px',
                                                backgroundColor: '#FB7185',
                                                color: 'white',
                                                width: '65px',
                                                textAlign: 'center',
                                                fontWeight: 'bold',
                                                maxHeight: '28px',
                                              }
                                        }>
                                        {el.globalProgressDetails
                                          ?.pourcentageGlobal
                                          ? el.globalProgressDetails?.pourcentageGlobal.toFixed(
                                              0
                                            )
                                          : 0}
                                        %
                                      </span>
                                    </div>
                                    <div
                                      className="container"
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: '#64748B',
                                        fontSize: '14px',
                                        fontWeight: '600',
                                        marginTop: '16px',
                                        marginBottom: '10px',
                                      }}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="#64748B"
                                        style={{
                                          width: '24px',
                                          height: '24px',
                                        }}>
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                        />
                                      </svg>
                                      Début : JJ/MM/AAA
                                    </div>
                                    <hr />
                                    <div
                                      className="container"
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginTop: '5px',
                                      }}>
                                      <span
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}>
                                        <span>
                                          <img
                                            src={imgUser}
                                            alt=""
                                            style={{
                                              width: '32px',
                                              height: '32px',
                                              borderRadius: '50%',
                                            }}
                                          />
                                        </span>
                                        <span
                                          style={{
                                            color: '#334155',
                                            fontSize: '14px',
                                            fontWeight: '600',
                                            marginLeft: '5px',
                                          }}>
                                          {el.responsable?.name
                                            ? el.responsable.name
                                            : 'Non défini'}{' '}
                                          {el.responsable?.lastname &&
                                            el.responsable.lastname}
                                          <br />
                                          <span
                                            style={{
                                              color: '#64748B',
                                              fontSize: '14px',
                                              fontWeight: '400',
                                            }}>
                                            Responsable
                                          </span>
                                        </span>
                                      </span>
                                      <span
                                        style={{
                                          padding: '6px 12px',
                                          textAlign: 'center',
                                          backgroundColor: '#FEF3C7',
                                          borderRadius: '4px',
                                          color: '#F59E0B',
                                        }}>
                                        Deadline: JJ/MM/AAAA
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                          }
                        })}
                    </div>
                  </>
                )}
                {item == 'Tous' && (
                  <>
                    <div className="row">
                      <div className="row">
                        <div
                          class="col-lg-4"
                          style={{ cursor: 'pointer' }}
                          onClick={(el) => {
                            el.preventDefault()
                            navigate('/information_generale')
                          }}>
                          <div
                            class="card"
                            style={{
                              borderRadius: '8px',
                              boxShadow:
                                '0px 6.15px 12.31px rgba(0, 0, 0, 0.02), 0px 6.15px 6.15px rgba(0, 0, 0, 0.04)',
                              padding: '16px 24px',
                              margin: '10px 10px',
                              minHeight: '220px',
                            }}>
                            <div
                              className="container"
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}>
                              <span
                                style={{
                                  color: '#334155',
                                  fontSize: '18px',
                                  fontWeight: '600',
                                }}>
                                Administratif et généralités
                              </span>
                            </div>
                            <div
                              className="container"
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}></div>
                          </div>
                        </div>
                        {lots &&
                          lots.map((lot) => {
                            if (lot) {
                              return (
                                <>
                                  <div
                                    class="col-lg-4"
                                    style={{ cursor: 'pointer' }}
                                    onClick={(el) => {
                                      el.preventDefault()
                                      localStorage.setItem(
                                        '_id',
                                        lot.entreprise?._id
                                      )
                                      localStorage.setItem(
                                        'titreLot',
                                        lot.titre
                                      )
                                      localStorage.setItem('_idLot', lot._id)
                                      if (
                                        infoUser?.user?.role?.roleType ==
                                          'admin' ||
                                        infoUser?.user?.role?.roleType ==
                                          'opc' ||
                                        infoUser?.user?.role?.roleType == 'moex'
                                      ) {
                                        navigate(`/SuiviOperationnel_Profil`)
                                      }
                                    }}>
                                    <div
                                      class="card"
                                      style={{
                                        borderRadius: '8px',
                                        boxShadow:
                                          '0px 6.15px 12.31px rgba(0, 0, 0, 0.02), 0px 6.15px 6.15px rgba(0, 0, 0, 0.04)',
                                        padding: '16px 24px',
                                        margin: '10px 10px',
                                      }}>
                                      <div
                                        className="container"
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                        }}>
                                        <span
                                          style={{
                                            color: '#334155',
                                            fontSize: '18px',
                                            fontWeight: '600',
                                          }}>
                                          Lot : {lot ? lot.titre : '---'}
                                        </span>
                                        <span
                                          style={
                                            lot.globalProgressDetails?.color
                                              ? {
                                                  padding: '4px 12px',
                                                  borderRadius: '24px',
                                                  backgroundColor: `${lot.globalProgressDetails.color}`,
                                                  color: 'white',
                                                  width: '65px',
                                                  textAlign: 'center',
                                                  fontWeight: 'bold',
                                                  maxHeight: '28px',
                                                }
                                              : {
                                                  padding: '4px 12px',
                                                  borderRadius: '24px',
                                                  backgroundColor: '#FB7185',
                                                  color: 'white',
                                                  width: '65px',
                                                  textAlign: 'center',
                                                  fontWeight: 'bold',
                                                  maxHeight: '28px',
                                                }
                                          }>
                                          {lot.globalProgressDetails
                                            ?.pourcentageGlobal
                                            ? lot.globalProgressDetails?.pourcentageGlobal.toFixed(
                                                0
                                              )
                                            : 0}
                                          %
                                        </span>
                                      </div>
                                      <div
                                        className="container"
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          color: '#64748B',
                                          fontSize: '14px',
                                          fontWeight: '600',
                                          marginTop: '16px',
                                          marginBottom: '16px',
                                        }}>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth={1.5}
                                          stroke="#64748B"
                                          style={{
                                            width: '24px',
                                            height: '24px',
                                          }}>
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                          />
                                        </svg>
                                        Début :{' '}
                                        {lot?.info?.startEnd?.start_date
                                          ? lot.info.startEnd.start_date
                                          : 'JJ/MM/AAAA'}
                                      </div>
                                      <div
                                        className="container"
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                        }}>
                                        <span>Avancements</span>
                                        <span style={{ color: '#4338CA' }}>
                                          {lot?.info?.progress
                                            ? (lot.info.progress * 100).toFixed(
                                                0
                                              )
                                            : '0'}
                                          %
                                        </span>
                                      </div>
                                      <div className="container">
                                        <div
                                          style={{
                                            width: '100%',
                                            position: 'relative',
                                            height: '8px',
                                            backgroundColor: '#C7D2FE',
                                            borderRadius: '4px',
                                            marginBottom: '32px',
                                          }}>
                                          <div
                                            style={{
                                              position: 'absolute',
                                              color: '#6366F1',
                                              left: '0',
                                              top: '0',
                                              bottom: '0',
                                              backgroundColor: '#6366F1',
                                              width: `${
                                                lot?.info?.progress
                                                  ? lot.info.progress * 100
                                                  : 0
                                              }%`,
                                              borderRadius: '4px',
                                            }}></div>
                                        </div>
                                      </div>
                                      <div
                                        className="container"
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                        }}>
                                        <span
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}>
                                          {lot.titre != 'Généralités' && (
                                            <>
                                              <span>
                                                <img
                                                  src={
                                                    lot.entreprise?.photo
                                                      ? imgUser
                                                      : imgUser
                                                  }
                                                  alt=""
                                                  style={{
                                                    width: '32px',
                                                    height: '32px',
                                                    borderRadius: '50%',
                                                  }}
                                                />
                                              </span>
                                              <span
                                                style={{
                                                  color: '#334155',
                                                  fontSize: '14px',
                                                  fontWeight: '600',
                                                  marginLeft: '5px',
                                                }}>
                                                {lot.entreprise?.responsable
                                                  ?.name
                                                  ? lot.entreprise?.responsable
                                                      ?.name
                                                  : ''}{' '}
                                                {lot.entreprise?.responsable
                                                  ?.lastname
                                                  ? lot.entreprise?.responsable
                                                      ?.lastname
                                                  : ''}{' '}
                                                <br />
                                                <span
                                                  style={{
                                                    color: '#64748B',
                                                    fontSize: '14px',
                                                    fontWeight: '400',
                                                  }}>
                                                  Responsable
                                                </span>
                                              </span>
                                            </>
                                          )}
                                        </span>
                                        <span
                                          style={{
                                            padding: '6px 12px',
                                            textAlign: 'center',
                                            backgroundColor: '#FEF3C7',
                                            borderRadius: '4px',
                                            color: '#F59E0B',
                                          }}>
                                          Deadline:
                                          {lot?.info?.startEnd?.end_date
                                            ? lot.info.startEnd.end_date
                                            : 'JJ/MM/AAAA'}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )
                            }
                          })}
                      </div>
                      {intervenants &&
                        intervenants.map((el) => {
                          if (el) {
                            return (
                              <>
                                <div
                                  class="col-lg-4"
                                  style={{ cursor: 'pointer' }}
                                  onClick={(ev) => {
                                    ev.preventDefault()
                                    localStorage.setItem(
                                      '_idIntervenant',
                                      el._id
                                    )
                                    localStorage.setItem('titreLot', el.titre)
                                    navigate(
                                      `/SuiviOperationnel_Profil_Intervenant`
                                    )
                                  }}>
                                  <div
                                    class="card"
                                    style={{
                                      borderRadius: '8px',
                                      boxShadow:
                                        '0px 6.15px 12.31px rgba(0, 0, 0, 0.02), 0px 6.15px 6.15px rgba(0, 0, 0, 0.04)',
                                      padding: '16px 24px',
                                      margin: '10px 10px',
                                    }}>
                                    <div
                                      className="container"
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                      }}>
                                      <span
                                        style={{
                                          color: '#334155',
                                          fontSize: '18px',
                                          fontWeight: '600',
                                        }}>
                                        {el.titre ? el.titre : 'Non défini'}
                                      </span>
                                      <span
                                        style={
                                          el.globalProgressDetails?.color
                                            ? {
                                                padding: '4px 12px',
                                                borderRadius: '24px',
                                                backgroundColor: `${el.globalProgressDetails.color}`,
                                                color: 'white',
                                                width: '65px',
                                                textAlign: 'center',
                                                fontWeight: 'bold',
                                                maxHeight: '28px',
                                              }
                                            : {
                                                padding: '4px 12px',
                                                borderRadius: '24px',
                                                backgroundColor: '#FB7185',
                                                color: 'white',
                                                width: '65px',
                                                textAlign: 'center',
                                                fontWeight: 'bold',
                                                maxHeight: '28px',
                                              }
                                        }>
                                        {el.globalProgressDetails
                                          ?.pourcentageGlobal
                                          ? el.globalProgressDetails?.pourcentageGlobal.toFixed(
                                              0
                                            )
                                          : 0}
                                        %
                                      </span>
                                    </div>
                                    <div
                                      className="container"
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: '#64748B',
                                        fontSize: '14px',
                                        fontWeight: '600',
                                        marginTop: '16px',
                                        marginBottom: '10px',
                                      }}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="#64748B"
                                        style={{
                                          width: '24px',
                                          height: '24px',
                                        }}>
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                        />
                                      </svg>
                                      Début : JJ/MM/AAA
                                    </div>
                                    <hr />
                                    <div
                                      className="container"
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginTop: '5px',
                                      }}>
                                      <span
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}>
                                        <span>
                                          <img
                                            src={imgUser}
                                            alt=""
                                            style={{
                                              width: '32px',
                                              height: '32px',
                                              borderRadius: '50%',
                                            }}
                                          />
                                        </span>
                                        <span
                                          style={{
                                            color: '#334155',
                                            fontSize: '14px',
                                            fontWeight: '600',
                                            marginLeft: '5px',
                                          }}>
                                          {el.responsable?.name
                                            ? el.responsable.name
                                            : 'Non défini'}{' '}
                                          {el.responsable?.lastname &&
                                            el.responsable.lastname}
                                          <br />
                                          <span
                                            style={{
                                              color: '#64748B',
                                              fontSize: '14px',
                                              fontWeight: '400',
                                            }}>
                                            Responsable
                                          </span>
                                        </span>
                                      </span>
                                      <span
                                        style={{
                                          padding: '6px 12px',
                                          textAlign: 'center',
                                          backgroundColor: '#FEF3C7',
                                          borderRadius: '4px',
                                          color: '#F59E0B',
                                        }}>
                                        Deadline: JJ/MM/AAAA
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                          }
                        })}
                    </div>
                  </>
                )}
                {/* liste fin */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/**Modal création carte intervenant */}
      <div class="modal" id="myModal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Créer une nouvelle carte
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                onClick={(ev) => {
                  ev.preventDefault()
                  setTypeCarte('Sélectionner une carte')
                  setResponsable('Sélectionner un responsable')
                }}></button>
            </div>

            {selected ? (
              <>
                <div class="modal-body">
                  <span
                    style={{
                      color: '#64748B',
                      fontSize: '14px',
                      fontWeight: '600',
                    }}>
                    Sélectionner une carte:
                  </span>
                  <button
                    data-bs-toggle="dropdown"
                    className="btn"
                    style={{
                      backgroundColor: 'rgba(226, 232, 240, 0.4)',
                      color: '#334155',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}>
                    <span>{typeCarte}</span> <i className="ti ti-selector"></i>
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                    style={{ width: '90%' }}>
                    {listCarte &&
                      listCarte.map((el) => {
                        return (
                          <>
                            <li>
                              {' '}
                              <a
                                className="dropdown-item"
                                onClick={(ev) => {
                                  ev.preventDefault()
                                  setTypeCarte(el.roleName)
                                  setCarteSelected(el._id)
                                }}>
                                {el.roleName}
                              </a>
                            </li>
                          </>
                        )
                      })}
                  </ul>
                </div>

                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn"
                    style={{
                      color: 'white',
                      backgroundColor: '#4F46E5',
                      borderRadius: '8px',
                    }}
                    onClick={(ev) => {
                      ev.preventDefault()
                      axios
                        .get(
                          `${process.env.REACT_APP_URL}/api/v1/users/getUsersByRole/${infoChantier.planning}/${carteSelected}?planning=${infoChantier.planning}`,
                          {
                            headers: {
                              Authorization: `Bearer ${infoUser.token}`,
                            },
                          }
                        )
                        .then((res) => {
                          if (res.data.data) {
                            setListResponsable(res.data.data)
                            setSelected(false)
                          }
                        })
                    }}
                    disabled={
                      typeCarte == 'Sélectionner une carte' ? true : false
                    }>
                    Suivant
                  </button>
                </div>
              </>
            ) : (
              <>
                {listResponsable.length > 0 ? (
                  <>
                    <div class="modal-body">
                      <span
                        style={{
                          color: '#64748B',
                          fontSize: '14px',
                          fontWeight: '600',
                        }}>
                        Sélectionner un responsable:
                      </span>
                      <button
                        data-bs-toggle="dropdown"
                        className="btn"
                        style={{
                          backgroundColor: 'rgba(226, 232, 240, 0.4)',
                          color: '#334155',
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}>
                        <span>{responsable}</span>{' '}
                        <i className="ti ti-selector"></i>
                      </button>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                        style={{ width: '90%' }}>
                        {listResponsable.map((el) => {
                          return (
                            <>
                              <li>
                                {' '}
                                <a
                                  className="dropdown-item"
                                  onClick={(ev) => {
                                    ev.preventDefault()
                                    setResponsableSelected(el.intervenant?._id)
                                    setResponsable(
                                      el.intervenant?.name
                                        ? el.intervenant.name
                                        : 'Non défini'
                                    )
                                  }}>
                                  {el.intervenant?.name
                                    ? el.intervenant?.name
                                    : ''}{' '}
                                  {el.intervenant?.lastname
                                    ? el.intervenant.lastname
                                    : ''}
                                </a>
                              </li>
                            </>
                          )
                        })}
                      </ul>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn"
                        style={{
                          color: '#334155',
                          backgroundColor: '#EBEFF5',
                          borderRadius: '8px',
                        }}
                        onClick={(ev) => {
                          ev.preventDefault()
                          setSelected(true)
                          setExist(false)
                          setResponsable('Sélectionner un responsable')
                        }}>
                        Précedent
                      </button>
                      <button
                        type="button"
                        class="btn"
                        style={{
                          color: 'white',
                          backgroundColor: '#4F46E5',
                          borderRadius: '8px',
                        }}
                        onClick={(ev) => {
                          ev.preventDefault()
                          setResponsable('Sélectionner un responsable')
                          setTypeCarte('Sélectionner une carte')
                          setSelected(true)
                          setExist(false)
                          axios
                            .post(
                              `${process.env.REACT_APP_URL}/api/v1/cartes`,
                              {
                                titre: typeCarte,
                                planning: infoChantier.planning,
                                responsable: responsableSelected,
                              },
                              {
                                headers: {
                                  Authorization: `Bearer ${infoUser.token}`,
                                },
                              }
                            )
                            .then((res) => {
                              setBool(!bool)
                            })
                            .catch((err) => {
                              console.log(err)
                            })
                        }}
                        data-bs-dismiss="modal"
                        disabled={
                          responsable == 'Sélectionner un responsable'
                            ? true
                            : false
                        }>
                        Enregistrer
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <form
                      onSubmit={(ev) => {
                        ev.preventDefault()
                        ev.target.reset()
                        setSelected(true)
                        setExist(false)
                        setResponsable('Sélectionner un responsable')
                        axios
                          .post(
                            `${process.env.REACT_APP_URL}/api/v1/users`,
                            {
                              name: nom,
                              lastname: prenom,
                              telephone: tel,
                              email: email,
                              entrepriseName: societe,
                              password: `${nom}At2023@`,
                              passwordConfirm: `${nom}At2023@`,
                              role: carteSelected,
                              userChantier: infoChantier._id,
                              acces: [
                                {
                                  chantierId: infoChantier._id,
                                  roles: carteSelected,
                                },
                              ],
                              planning: infoChantier.planning,
                            },
                            {
                              headers: {
                                Authorization: `Bearer ${infoUser.token}`,
                              },
                            }
                          )
                          .then((res) => {
                            if (res.data.data) {
                              axios
                                .post(
                                  `${process.env.REACT_APP_URL}/api/v1/cartes`,
                                  {
                                    titre: typeCarte,
                                    planning: infoChantier.planning,
                                    responsable: res.data.data._id,
                                  },
                                  {
                                    headers: {
                                      Authorization: `Bearer ${infoUser.token}`,
                                    },
                                  }
                                )
                                .then((res) => {
                                  addToast('Carte créer avec succes.', {
                                    appearance: 'success',
                                    autoDismiss: true,
                                  })
                                  setBool(!bool)
                                })
                                .catch((err) => {
                                  console.log(err)
                                  addToast(
                                    "Une erreur s'est produit l'hors de l'ajout de la carte",
                                    {
                                      appearance: 'error',
                                      autoDismiss: true,
                                    }
                                  )
                                })
                            } else {
                              addToast(
                                "Une erreur s'est produit l'hors de l'ajout de l'utilisateur",
                                {
                                  appearance: 'error',
                                  autoDismiss: true,
                                }
                              )
                            }
                          })
                          .catch((err) => {
                            console.log(err)
                            addToast(
                              "Une erreur s'est produit l'hors de l'ajout de l'utilisateur",
                              {
                                appearance: 'error',
                                autoDismiss: true,
                              }
                            )
                          })
                      }}>
                      <div className="modal-body">
                        <span
                          style={{
                            color: '#64748B',
                            fontSize: '14px',
                            fontWeight: '600',
                          }}>
                          Aucun responsable trouvé:{' '}
                          <button
                            className="btn btn-sm"
                            style={{
                              color: 'white',
                              backgroundColor: '#4F46E5',
                              borderRadius: '8px',
                              padding: '2px 12px',
                              textAlign: 'center',
                            }}
                            onClick={(ev) => {
                              ev.preventDefault()
                              setExist(true)
                            }}
                            disabled={exist}>
                            ajouter +
                          </button>
                        </span>
                        {exist && (
                          <>
                            <div class="mb-3 mt-3">
                              <label for="email" class="form-label">
                                Nom:
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Nom"
                                onChange={(ev) => setNom(ev.target.value)}
                                required
                              />
                            </div>
                            <div class="mb-3 mt-3">
                              <label for="email" class="form-label">
                                Prénom:
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Prénom"
                                onChange={(ev) => setPrenom(ev.target.value)}
                                required
                              />
                            </div>
                            <div class="mb-3 mt-3">
                              <label for="email" class="form-label">
                                Email:
                              </label>
                              <input
                                type="email"
                                class="form-control"
                                placeholder="Email"
                                onChange={(ev) => setEmail(ev.target.value)}
                                required
                              />
                            </div>
                            <div class="mb-3 mt-3">
                              <label for="email" class="form-label">
                                Téléphone:
                              </label>
                              <input
                                type="tel"
                                class="form-control"
                                placeholder="Téléphone"
                                onChange={(ev) => setTel(ev.target.value)}
                                required
                              />
                            </div>
                            <div class="mb-3 mt-3">
                              <label for="email" class="form-label">
                                Société:
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Société"
                                onChange={(ev) => setSociete(ev.target.value)}
                                required
                              />
                            </div>
                          </>
                        )}
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          class="btn"
                          style={{
                            color: '#334155',
                            backgroundColor: '#EBEFF5',
                            borderRadius: '8px',
                          }}
                          onClick={(ev) => {
                            ev.preventDefault()
                            setSelected(true)
                            setExist(false)
                            setResponsable('Sélectionner un responsable')
                          }}>
                          Précedent
                        </button>
                        <button
                          type="submit"
                          class="btn"
                          style={{
                            color: 'white',
                            backgroundColor: '#4F46E5',
                            borderRadius: '8px',
                          }}>
                          Enregistrer
                        </button>
                      </div>
                    </form>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {wait && (
        <>
          <LoaderFixed />
        </>
      )}
    </>
  )
}

export default ListeEntreprise
