import React, { useState, useEffect } from 'react'
import { ReactTabulator } from 'react-tabulator'
import { useToasts } from 'react-toast-notifications'
import trash from '../../../../assets/images/Kolos_logo/trash.png'
import axios from 'axios'
import Multiselect from 'multiselect-react-dropdown'
import { searchOptimizedData } from '../../../../helpers/recherche/recherche'
import {
  getAllData,
  updateData,
  addData,
  delData,
} from '../../../../helpers/suivi/suivi'
import { useNavigate } from 'react-router-dom'
import AnnotationPlan from './AnnotationPlan'
const Avancement = ({ idPlan, idLot, setWait, dateExport, plan,listPlanAfficher }) => {
  const navigate = useNavigate()
  const { addToast } = useToasts()
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const [dataMaj, setDataMaj] = useState()
  const [dataAvancement, setDataAvancement] = useState([])
  const [dataLot, setDataLot] = useState([])
  const [dataModalLot, setDataModalLot] = useState({})
  const [lotSelected, setLocSelected] = useState([])
  const [bool, setBool] = useState(false)
  const [annotation, setAnnotation] = useState(false)
  const [listAnnotation, setListAnnotation] = useState()
  //Simple input
  const simpleInput = (cell) => {
    const input = document.createElement('input')
    input.type = 'text'
    input.style =
      'width:100%;border:none;padding:11px 22px;font-size:16px;font-weight:600;color:#334155;overflow:visible'
    input.defaultValue = cell.getValue() ? cell.getValue() : ''
    input.addEventListener('blur', function (ev) {
      ev.preventDefault()
      cell.setValue(ev.target.value)
    })
    const container = document.createElement('div')
    container.style = 'display:flex;align-items:center;width:100%'
    container.appendChild(input)
    return container
  }
  //Tandance
  const tendance = (cell) => {
    const field = cell.getField()
    const container = document.createElement('span')
    const contenu = document.createElement('div')
    contenu.innerText = `${cell.getValue()==0 ? 'Constante' : (cell.getValue()==1 ? 'Croissance' : 'Ralentissement')}`
    contenu.style = `padding:8px 8px;text-align:center;min-width:100px;min-height:20px;background-color:${
      cell.getValue() == 0
        ? '#D8D9DA'
        : cell.getValue() == 1
        ? 'rgba(45, 212, 191, 0.15)'
        : '#FFF1F2'
    }; border-radius:8px;margin-top:5px;color:
    ${
      cell.getValue() == 0
        ? 'black'
        : cell.getValue() == 1
        ? '#0D9488'
        : '#FB7185'
    };font-weight:500
    `
    container.appendChild(contenu)
    return container
  }

  //Simple input
  const simpleInputNumber = (cell) => {
    const field = cell.getField()
    const input = document.createElement('input')
    input.type = 'number'
    input.disabled = field == 'cumule' ? false : true
    input.style =
      'width:100%;border:none;padding:11px 22px;font-size:16px;font-weight:600;color:#334155;overflow:visible'
    input.defaultValue = cell.getValue() ? cell.getValue() : 0
    input.addEventListener('blur', function (ev) {
      ev.preventDefault()
      cell.setValue(ev.target.value)
    })
    const container = document.createElement('div')
    container.style = 'display:flex;align-items:center;width:100%'
    container.appendChild(input)
    return container
  }

  //Modal dessin sur plan
  const modalPlan = (value) => (cell) => {
    const data = cell.getData()
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')
    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalPlan')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')  
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      setAnnotation(true)
      setListAnnotation(data.shapes ? data.shapes : {})
      setDataMaj(cell)
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)
    return buttonHolder
  }

  //checkbox
  const checkbox = (cell) => {
    const input = document.createElement('input')
    input.type = 'checkbox'
    input.className = 'form-check-input'
    input.defaultChecked = cell.getValue()
    input.addEventListener('change', function (e) {
      cell.setValue(!cell.getValue())
    })
    const view = document.createElement('div')
    view.className = 'form-check form-switch  mt-2'
    view.style = 'color:#2DD4BF;margin-left:12px'
    view.appendChild(input)
    return view
  }

  //Boutton de suppression
  const button = (delFunction) => (cell) => {
    const delIcons = document.createElement('img')
    delIcons.src = trash
    delIcons.style = 'width:24px;height:24px'

    //create delete button
    var delBt = document.createElement('button')
    delBt.style = 'padding:8px 10px; border:1px solid #CBD5E1 '
    delBt.type = 'button'
    delBt.appendChild(delIcons)
    delBt.classList.add('btn')
    delBt.addEventListener('click', function () {
      delFunction(cell)
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(delBt)
    return buttonHolder
  }
  const rowDataCandidate = {
    media: idPlan,
    planning: infoChantier.planning,
  }
  //ajout avancement
  const addAvancement = async (e) => {
    setWait(true)
    addData(rowDataCandidate, 'avancements', idLot, infoUser.token)
      .then((e) => {
        setBool(!bool)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally((res) => {
        setWait(false)
      })
  }
  //update data
  const anvancementEdited = async (cell) => {
    const data = cell.getData()
    updateData(data, `avancements`, infoUser.token, infoChantier.planning)
      .then((res) => {
        console.log(res);
        if(res.name=='AxiosError'){
          addToast(res.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          })
          setBool(!bool)
        }else{
          if (res.data) {
            cell.getRow().update(res.data)
          }
        }
        
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //suppression
  const delAvancement = async (cell) => {
    try {
      await delData(
        cell.getData(),
        'avancements',
        infoUser.token,
        infoChantier.planning
      )
        .then((res) => {
          console.log(res)
          cell.getRow().delete()
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (err) {
      addToast(err, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }
  const colonneAvancement = [
    { title: 'Pour mémoire', field: 'pourMemoire', formatter: checkbox },
    {
      title: 'Critères',
      field: 'critere',
      formatter: simpleInput,
    },
    {
      title: 'Précédent <br> (%)',
      field: 'precedent',
      formatter: simpleInputNumber,
      
    },{
      title: 'En cours <br> (%)',
      field: 'enCours',
      formatter: simpleInputNumber,
    },
    {
      title: 'Cumulés <br> (%)',
      field: 'cumule',
      formatter: simpleInputNumber,
    },
    
    /* {
      title: 'Planifiés <br> (%)',
      field: '',
    }, */
    {
      title: 'Tendance',
      field: 'tendance',
      formatter: tendance,
    },
    {
      title: 'Annotation',
      field: 'draws',
      formatter: modalPlan(),
    },
    {
      title: 'Supprimer',
      field: 'functionButton',
      width: 100,
      formatter: button(delAvancement),
      align: 'centrer',
      cellClick: (e, cell) => {},
    },
  ]
  useEffect(() => {
    setWait(true)
    if (idPlan && idLot) {
      getAllData(
        `planning=${infoChantier.planning}&lot=${idLot}&media=${idPlan}`,
        'avancements',
        infoUser.token
      )
        .then((res) => {
          if (res.data) {
            setDataAvancement(res.data)
          } else {
            setDataAvancement([])
          }
        })
        .catch((err) => {
          console.log(err)
        })
        .finally((res) => {
          setWait(false)
        })
    }
  }, [idPlan, idLot, bool])
  return (
    <>
      <div
        className="container-fluid"
        style={{
          backgroundColor: '#FFFFFF',
          borderRadius: '8px',
          boxShadow:
            '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
          padding: '16px 0px 24px',
        }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0px 0px 0px 40px',
          }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                color: '#334155',
                fontSize: '20px',
                fontWeight: '600',
              }}>
              Avancement
            </span>
            <span
              style={{
                backgroundColor: '#E0E7FF',
                borderRadius: '24px',
                padding: '6px 12px',
                color: '#64748B',
                fontSize: '14px',
                fontWeight: '600',
                marginLeft: '8px',
                maxHeight: '32px',
                width: 'auto',
              }}>
              {dataAvancement.length > 0 ? dataAvancement.length : '0'}{' '}
              avancement
            </span>
          </div>
          <div>
            <span
              style={{
                padding: '8px 12px 8px 12px',
                backgroundColor: 'rgba(226, 232, 240, 0.6)',
                borderRadius: '8px',
                height: '40px',
              }}>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="#818CF8"
                  style={{ width: '18px', height: '18px' }}>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
              </span>
              <input
                type="text"
                placeholder="Recherche"
                style={{
                  border: 'none',
                  backgroundColor: 'transparent',
                }}
                onChange={(ev) => {
                  ev.preventDefault()
                  ev.stopPropagation()
                  const newData = searchOptimizedData(
                    ev.target.value,
                    dataAvancement
                  )
                  setDataAvancement(newData)
                }}
              />
            </span>
            <button
              className="btn"
              style={{
                backgroundColor: '#2DD4BF',
                color: '#F8FAFC',
                marginLeft: '24px',
                marginRight: '20px',
              }}
              onClick={(e) => {
                addAvancement(e)
              }}>
              + Nouveau critère
            </button>
            <button
              className="btn"
              style={{
                backgroundColor: '#4F46E5',
                color: '#F8FAFC',
                marginLeft: '24px',
              }}
              onClick={(e) => {
                e.preventDefault()
                setWait(true)
                axios
                  .post(
                    `${process.env.REACT_APP_URL}/api/v1/fiches/avancementToPDF`,
                    {
                      planning: infoChantier.planning,
                      lotId: idLot,
                      dateExport: dateExport,
                      listPlanAfficher:listPlanAfficher
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${infoUser.token}`,
                      },
                    }
                  )
                  .then((res) => {
                    console.log(res)
                    localStorage.setItem('typeRapport','avancement')
                    navigate('/Rapport_CR')
                  })
                  .catch((err) => {
                    console.log(err)
                  })
                  .finally((res) => {
                    setWait(false)
                  })
              }}>
              Valider
            </button>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            borderTop: 'solid #E2E8F0 1px',
            marginTop: '16px',
          }}>
          <ReactTabulator
            data={dataAvancement}
            columns={colonneAvancement}
            layout={'fitColumns'}
            events={{ cellEdited: anvancementEdited }}
            options={{
              locale: true,
              pagination: 'local',
              paginationSize: 5,
              langs: {
                fr: {
                  pagination: {
                    first: 'Premier',
                    first_title: 'Première',
                    last: 'Dernier',
                    last_title: 'Dernière',
                    prev: 'Précédent',
                    prev_title: 'Précédente',
                    next: 'Suivant',
                    next_title: 'Page suivante',
                    page_size: 'Taille de page',
                    page_title: 'Afficher la page',
                    all: 'Tous',
                  },
                },
              },
            }}
          />
        </div>
      </div>
      {annotation && (
        <AnnotationPlan
          setAnnotation={setAnnotation}
          plan={plan}
          data={listAnnotation}
          cell={dataMaj}
          setWait={setWait}
        />
      )}
    </>
  )
}

export default Avancement
