import React, { useState, useEffect } from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import { useNavigate } from 'react-router-dom'
import LoaderFixed from '../../../components/loader/LoaderFixed'
import '../../../assets/plugins/datatables/datatable.css'
import {
  getRetard,
  updateRetard,
} from '../../../helpers/planning/retard/retard'
import { getAllData } from '../../../helpers/suivi/suivi'
import { useSelector } from 'react-redux'
import Modal from './Retard/Modal'
import PageNotFound from '../../../components/404/PageNotFound'
import { ReactTabulator } from 'react-tabulator'
//Liste des taches noté en retard
const Retard = () => {
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  if (
    !localStorage.getItem('isLogin') ||
    (infoUser?.user?.role?.roleType != 'admin' &&
      infoUser?.user?.role?.roleType != 'opc' &&
      infoUser?.user?.role?.roleType != 'moex')
  ) {
    return <PageNotFound />
  }
  const bool = useSelector((state) => state.bool.bool)
  const [page, setPage] = useState(1)
  const navigate = useNavigate()
  const [lot, setLot] = useState([])
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const [wait, setWait] = useState(false)
  const [dataRetard, setDataRetard] = useState([])
  const [filtre, setFiltre] = useState('false')
  const date = (cell) => {
    const view = document.createElement('input')
    view.style = 'background-color:rgba(226, 232, 240, 0.4); border:none'
    view.type = 'date'
    view.defaultValue = cell.getValue()?.split('T')[0]
    view.disabled = true
    view.classList.add('form-control')
    const container = document.createElement('span')
    container.appendChild(view)
    return container
  }
  const [item,setItem] =useState('Tous les lots')
  const formatLot = (cell) => {
    const data = cell.getData()
    const lot = data.lotResponsable?.map((el) => {
      return el.titre
    })
    const lotResponsale = lot ? lot.join(',') : []
    const champ = document.createElement('span')
    champ.innerText = lotResponsale
    const conteneur = document.createElement('span')
    conteneur.appendChild(champ)
    return conteneur
  }
  const checkbox = (cell) => {
    const data = cell.getData()
    const input = document.createElement('input')
    input.type = 'checkbox'
    input.className = 'form-check-input'
    input.defaultChecked = data.responsableEngage
      ? data.responsableEngage
      : false
    input.addEventListener('change', function (e) {
      cell.setValue(!cell.getValue())
      if (cell.getValue()) {
        updateRetard(
          cell.getValue(),
          data._id,
          data.lotResponsable,
          infoChantier.planning,
          data.dure_retard,
          data.tache._id,
          infoUser.token
        ).then((res) => {
        })
      } else {
        updateRetard(
          cell.getValue(),
          data._id,
          data.lotResponsable,
          infoChantier.planning,
          data.dure_retard,
          data.tache._id,
          infoUser.token
        ).then((res) => {
        })
      }
    })
    const view = document.createElement('div')
    view.className = 'form-check form-switch mt-2'
    view.style = 'color:#2DD4BF'
    view.appendChild(input)
    return view
  }

  const modalRetard = (delFunction) => (cell) => {
    const data = cell.getData()
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', `#retard${data._id}`)
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      setDataModalAdministration(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }

  const colonneRetards = [
    { title: 'Responsables retards', formatter: formatLot },
    { title: 'Tâches', field: 'tache.text' },
    {
      title: 'Date début',
      field: 'tache.start_date',
      formatter: date,
      width: 150,
    },
    { title: 'Date fin', field: 'tache.end_date', formatter: date, width: 150 },
    { title: 'Retard Consigné', field: 'dure_retard', width: 100 },
    {
      title: 'Pénalité',
      field: 'responsableEngage',
      width: 100,
      formatter: checkbox,
    },
    { title: 'Details', width: 100, formatter: modalRetard() },
  ]

  useEffect(() => {
    setWait(true)
    setDataRetard([])
    getRetard(infoChantier.planning, page, filtre, infoUser.token).then((e) => {
      console.log(e)
      if (e.data?.status == 'error') {
        setDataRetard([])
        setWait(false)
      } else {
        setDataRetard(e.data)
        setWait(false)
      }
    })
  }, [bool, page, filtre])

  useEffect(() => {
    setWait(true)
    getAllData(
      `planning=${infoChantier.planning}`,
      'lots',
      infoUser.token
    ).then((e) => {
      if (e.name == 'AxiosError') {
        setLot([])
        setWait(false)
      } else {
        setLot(e.data)
        setWait(false)
      }
    })
  }, [])
  //formatter de date en fr
  const dateFr = (value) => {
    const dt = value.split('T')[0]
    const dt1 = dt.split('-')
    const result = `${dt1[2]}/${dt1[1]}/${dt1[0]}`
    return result
  }
  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={true} active="planning" />
        <Header bool={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid" style={{ paddingTop: '0.5vw' }}>
            <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="float-end"></div>
                  <span style={{ marginLeft: '10px' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="#94A3B8"
                      style={{ width: '16px', height: '16px' }}
                    >
                      <path
                        fillRule="evenodd"
                        d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                        clipRule="evenodd"
                      />
                    </svg>{' '}
                    <span
                      style={{
                        color: '#334155',
                        fontSize: '14px',
                        fontWeight: '600',
                      }}
                    >
                      / Planification
                    </span>
                  </span>{' '}
                  <br />
                  <span
                    style={{
                      marginLeft: '15px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: '#E2E8F0',
                        padding: '2px',
                        borderRadius: '6px',
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="#6366F1"
                        style={{
                          width: '20px',
                          height: '20px',
                          cursor: 'pointer',
                        }}
                        onClick={(e) => {
                          e.preventDefault()
                          navigate('/accueil')
                        }}
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                    <span
                      style={{
                        color: '#334155',
                        fontSize: '20px',
                        fontWeight: '600',
                        marginLeft: '10px',
                      }}
                    >
                      {' '}
                      Planification
                    </span>
                  </span>
                </div>
              </div>
            </div>
            {/**Début navbar */}
            <div style={{ display: 'flex', flexWrap: 'nowrap', width: '100%' }}>
              <div className="wrapper">
                {/* <div
                  className="item"
                  style={{
                    borderLeft: '1px solid #CBD5E1',
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px',
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    navigate('/planning_Dashboard')
                  }}
                >
                  <span
                    style={{
                      color: '#334155',
                      fontSize: '16px',
                      fontWeight: '400',
                      cursor: 'pointer',
                    }}
                  >
                    Tableau de bord
                  </span>
                </div> */}
                <div
                  className="item"
                  style={{
                    borderLeft: '1px solid #CBD5E1',
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px',
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    navigate('/planning_GestionTache')
                  }}
                >
                  <span
                    style={{
                      color: '#334155',
                      fontSize: '16px',
                      fontWeight: '400',
                      cursor: 'pointer',
                    }}
                  >
                    Gestion des tâches
                  </span>
                </div>
                <div
                  className="item"
                  onClick={(e) => {
                    e.preventDefault()
                    navigate('/Planning_GestionTache_LastPlanner')
                  }}
                >
                  <span
                    style={{
                      color: '#334155',
                      fontSize: '16px',
                      fontWeight: '400',
                      cursor: 'pointer',
                    }}
                  >
                    Glissant
                  </span>
                </div>
                <div
                  className="item"
                  onClick={(e) => {
                    e.preventDefault()
                    navigate('/planning_Favori')
                  }}
                >
                  <span
                    style={{
                      color: '#334155',
                      fontSize: '16px',
                      fontWeight: '400',
                      cursor: 'pointer',
                    }}
                  >
                    Jalons Critiques
                  </span>
                </div>
                <div
                  className="item"
                  onClick={(e) => {
                    e.preventDefault()
                    navigate('/planning_Retard')
                  }}
                  style={{
                    borderRight: '1px solid  #CBD5E1',
                    borderTopRightRadius: '8px',
                    borderBottomRightRadius: '8px',
                  }}
                >
                  <span
                    style={{
                      color: '#F8FAFC',
                      fontSize: '16px',
                      fontWeight: '400',
                      backgroundColor: '#6366F1',
                      padding: '8px 24px',
                      borderRadius: '8px',
                    }}
                  >
                    Retards
                  </span>
                </div>
              </div>
            </div>
            {/* debut retard */}
            <div
              style={{
                width: '100%',
                backgroundColor: '#FFFFFF',
                borderRadius: '8px',
                boxShadow:
                  '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                marginTop: '22px',
                overflow: 'hidden',
                padding: '16px 4px 24px',
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '12px 12px',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span
                    style={{
                      color: '#334155',
                      fontSize: '20px',
                      fontWeight: '600',
                    }}
                  >
                    Retards
                  </span>
                  <span
                    style={{
                      backgroundColor: '#E0E7FF',
                      borderRadius: '24px',
                      padding: '6px 12px',
                      color: '#64748B',
                      fontSize: '14px',
                      fontWeight: '600',
                      marginLeft: '8px',
                      maxHeight: '200px',
                      width: 'auto',
                    }}
                  >
                    {dataRetard.length > 0 ? dataRetard.length : '0'} Tâches en
                    retard
                  </span>
                </div>
                <div style={{ paddingRight: '22px' }}>
                  <button
                    data-bs-toggle="dropdown"
                    className="btn"
                    style={{
                      backgroundColor: 'rgba(226, 232, 240, 0.4)',
                      color: '#334155',
                      marginLeft: '12px',
                      width:'225px',
                      display:'flex',
                      justifyContent:'space-between',
                    }}
                  ><span>{item}</span> <i className="ti ti-selector"></i></button>
                  <ul
                  class="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                  >
                    <li><a className='dropdown-item' onClick={(ev)=>{
                      ev.preventDefault()
                      setItem('Tous les lots')
                      setFiltre('false')
                    }}>Tous les lots</a></li>
                     {lot &&
                      lot.map((e) => {
                        return (
                          <>
                            <li><a className='dropdown-item' onClick={(ev)=>{
                              ev.preventDefault()
                              setItem(e.titre)
                              setFiltre(e._id)
                            }}>{e.titre}</a></li>
                          </>
                        )
                      })}
                  </ul>
                </div>
              </div>
              <div
                style={{
                  width: '100%',
                  borderTop: 'solid #E2E8F0 1px',
                  marginTop: '16px',
                }}
              >
                <ReactTabulator
                  data={dataRetard ? dataRetard : []}
                  columns={colonneRetards}
                  layout={'fitColumns'}
                  options={{
                    locale: true,
                    pagination: 'local',
                    paginationSize: 5,
                    langs: {
                      fr: {
                        pagination: {
                          first: 'Premier',
                          first_title: 'Première',
                          last: 'Dernier',
                          last_title: 'Dernière',
                          prev: 'Précédent',
                          prev_title: 'Précédente',
                          next: 'Suivant',
                          next_title: 'Page suivante',
                          page_size: 'Taille de page',
                          page_title: 'Afficher la page',
                          all: 'Tous',
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>
            {/* fin retard */}
          </div>
        </div>
      </div>
      {wait && (
        <>
          <LoaderFixed />
        </>
      )}
      {dataRetard &&
        dataRetard.map((e) => {
          return (
            <>
              <Modal
                id={`retard${e._id}`}
                data={e}
                lot={lot}
                responsables={e.lotResponsable}
                idTache={e.tache?._id}
              />
            </>
          )
        })}
    </>
  )
}

export default Retard
