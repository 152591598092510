import React, { useState, useEffect } from 'react'
import 'dhtmlx-scheduler/codebase/dhtmlxscheduler_material.css'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import { ReactTabulator } from 'react-tabulator'
import LoaderFixed from '../../../components/loader/LoaderFixed'
import { Button, Upload } from 'antd'
import 'antd/dist/reset.css'
import Presence from './component/Presence'
import LastPlanner2 from '../planning/LastPlanner2'
import {
  getAllEntreprise,
  getAnnexe,
  getAllIntervenant,
} from '../../../helpers/rapport/rapport'
import ModalDocument from '../suivi/component/ModalDocument'
import trash from '../../../assets/images/Kolos_logo/trash.png'
import { useToasts } from 'react-toast-notifications'
import Favori2 from '../planning/Favori/Favori2'
import {
  getAllData,
  updateData,
  addData,
  delData,
  getDataPresence,
  getForfait,
} from './../../../helpers/suivi/suivi'
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import PageNotFound from '../../../components/404/PageNotFound'
import {
  getFavori,
  updateFavori,
} from '../../../helpers/planning/favori/favori'
import Modal from '../planning/Favori/Modal'
import Penalite from './component/Penalite'
import { useSelector } from 'react-redux'
import { getISOWeek, getISOWeekYear } from 'date-fns'
import InformationGeneral from './component/InformationGeneral'
import TableIntervenants from './component/TableIntervenants'
import { searchOptimizedData } from '../../../helpers/recherche/recherche'
//Page pour la création des nouveaus compte rendu
const NewCr = () => {
  const location = useLocation()
  const getDateDuJourSemaine = (numeroJour, semaineSuivante = false) => {
    if (numeroJour < 0 || numeroJour > 6) {
      return 'Le numéro de jour doit être compris entre 0 et 6.'
    }

    const aujourdhui = new Date()
    const jourCourant = aujourdhui.getDay()

    let differenceJours = numeroJour - jourCourant

    if (semaineSuivante) {
      differenceJours += 7
    }
    aujourdhui.setDate(aujourdhui.getDate() + differenceJours)
    return new Date(aujourdhui)
  }

  const searchParams = new URLSearchParams(location.search)
  const etatCR = JSON.parse(searchParams.get('brouillon'))
  const dataBrouillon = JSON.parse(searchParams.get('data'))
  if (!localStorage.getItem('isLogin')) {
    return <PageNotFound />
  }
  const { addToast } = useToasts()
  const [rfLtp, setRfLtp] = useState(false)
  const [btnPartage, setBtnPartage] = useState(
    localStorage.getItem('ltp')
      ? JSON.parse(localStorage.getItem('ltp'))
      : false
  )
  useEffect(() => {
    setBtnPartage(JSON.parse(localStorage.getItem('ltp')))
  }, [rfLtp])
  const [confirmationLtp, setConfirationLtp] = useState(false)
  const bools = useSelector((state) => state.bool.bool)
  const [file, setFile] = useState([])
  const [disabled, setDisabled] = useState(true)
  const navigate = useNavigate()
  const [dataPresence, setDataPresence] = useState([])
  const [administratif, setAdministratif] = useState('')
  const [generalite, setGeneralite] = useState('')
  const [classement, setClassement] = useState([])
  const [allCarte, setAllCarte] = useState([])
  const [entreprise, setEntreprise] = useState([])
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const isDateChantierActive = infoChantier.cache?.active
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const [allCr, setAllCr] = useState([])
  const [allTask, setAllTask] = useState([])
  const [annexe, setAnnexe] = useState([])
  const [dateRendu, setDateRendu] = useState(
    etatCR
      ? dataBrouillon.dateCR
        ? new Date(dataBrouillon.dateCR)
        : isDateChantierActive
        ? new Date(getDateDuJourSemaine(infoChantier.cache?.dayInterval, false))
        : new Date(Date.now())
      : isDateChantierActive
      ? new Date(getDateDuJourSemaine(infoChantier.cache?.dayInterval, false))
      : new Date(Date.now())
  )
  const [dateProchainReunion, setDateProchainReunion] = useState(
    etatCR
      ? dataBrouillon.prochainReunion
        ? new Date(dataBrouillon.prochainReunion)
        : isDateChantierActive
        ? new Date(getDateDuJourSemaine(infoChantier.cache?.dayInterval, true))
        : new Date(dateRendu.getTime() + 7 * 24 * 60 * 60 * 1000)
      : isDateChantierActive
      ? new Date(getDateDuJourSemaine(infoChantier.cache?.dayInterval, true))
      : new Date(dateRendu.getTime() + 7 * 24 * 60 * 60 * 1000)
  )
  const [dataModalAction, setDataModalAction] = useState({})
  const [cibleAction, setCibleAction] = useState('')
  const [imageAction, setImageAction] = useState([])
  const [typeCr, setTypeCr] = useState(
    etatCR ? dataBrouillon.typeCr : 'Présentiel'
  )
  const [wait, setWait] = useState(false)
  const [wait2, setWait2] = useState(false)
  const [bool, setBool] = useState(false)
  const [bool1, setBool1] = useState(false)
  const [page, setPage] = useState(1)
  const [nbPage, setNbPage] = useState(0)
  const [dataEngagement, setDataEngagement] = useState([])
  const [dataAdministratif, setDataAdministratif] = useState([])
  const [dataDocument, setDataDocument] = useState([])
  const [dataSecurite, setDataSecurite] = useState([])
  const [dataCT, setDataCT] = useState([])
  const [dataInstallation, setDataInstallation] = useState([])
  const [dataEffectif, setDataEffectif] = useState([])
  const [dataPlanningSo, setDataPlanningSo] = useState([])
  const [dataModalPlanification, setDataModalPlanification] = useState({})
  const [dataModalEngagement, setDataModalEngagement] = useState({})
  const [dataModalAdministration, setDataModalAdministration] = useState({})
  const [dataModalDocument, setDataModalDocument] = useState({})
  const [dataModalSuiviDoc, setDataModalSuiviDoc] = useState([])
  const [dataModalSecurite, setDataModalSecurite] = useState({})
  const [dataModalCT, setDataModalCT] = useState({})
  const [dataModalInstallation, setDataModalInstallation] = useState({})
  const [dataModalEffectif, setDataModalEffectif] = useState({})
  const [dataMaj, setDataMaj] = useState()
  const [item, setItem] = useState('presence')
  const [item3, setItem3] = useState('plannification')
  const [item2, setItem2] = useState(
    localStorage.getItem('_idLot') ? localStorage.getItem('_idLot') : ''
  )
  const [item4, setItem4] = useState('jalon')
  const [item5, setItem5] = useState('entreprise')
  const [itemInt, setItemInt] = useState(false)
  const [lots, setLots] = useState([])
  const [forfait, setForfait] = useState({})
  const [filtre, setFiltre] = useState([])
  const indiceDoc = [
    { value: 'A' },
    { value: 'B' },
    { value: 'C' },
    { value: 'D' },
    { value: 'E' },
    { value: 'F' },
    { value: 'G' },
    { value: 'H' },
    { value: 'I' },
    { value: 'J' },
    { value: 'K' },
    { value: 'L' },
    { value: 'M' },
    { value: 'N' },
    { value: 'O' },
    { value: 'P' },
    { value: 'Q' },
    { value: 'R' },
    { value: 'S' },
    { value: 'T' },
    { value: 'U' },
    { value: 'V' },
    { value: 'W' },
    { value: 'X' },
    { value: 'Y' },
    { value: 'Z' },
  ]
  const [itemObservation, setItemObservation] = useState(true)

  //fonction qui verifier le filtre des items
  const filtreVerification = (tableFilter, item) => {
    return tableFilter.includes(item)
  }
  //Recuperation de tous les informations concernant les lots
  const [boolPenalite, setBoolPenalite] = useState(false)
  useEffect(() => {
    getForfait(infoChantier.planning, infoUser.token).then((e) => {
      setForfait(e.data[0])
    })
  }, [boolPenalite])
  useEffect(() => {
    getAllData(
      `planning=${infoChantier.planning}`,
      'lots',
      infoUser.token
    ).then((e) => {
      setLots(e.data)
      setWait(false)
    })
  }, [])
  //Recuperation presence et tous les informations d'entreprises
  useEffect(() => {
    getAllEntreprise(infoChantier.planning, infoUser.token).then((e) => {
      if (e.data.length > 0) {
        setClassement(e.data)
        const selectedData = e.data[0]
        setItem2(selectedData._id)
        if (selectedData.profile.planningSo) {
          setDataPlanningSo(selectedData.profile.planningSo)
        }
        if (selectedData.profile.engagements) {
          setDataEngagement(selectedData.profile.engagements)
        }
        if (selectedData.profile.administratifs) {
          setDataAdministratif(selectedData.profile.administratifs)
        }
        if (selectedData.profile.documents) {
          setDataDocument(selectedData.profile.documents)
        }
        if (selectedData.profile.securites) {
          setDataSecurite(selectedData.profile.securites)
        }
        if (selectedData.profile.installationChantier) {
          setDataInstallation(selectedData.profile.installationChantier)
        }
        if (selectedData.profile.effectifs) {
          setDataEffectif(selectedData.profile.effectifs)
        }
        if (selectedData.profile.controleurTechnique) {
          setDataCT(selectedData.profile.controleurTechnique)
        }
        if (selectedData.filter) {
          setFiltre(selectedData.filter)
        }
      }
      setWait(false)
    })
  }, [])

  //Recupération de tous les intervenant
  useEffect(() => {
    getAllIntervenant(infoChantier.planning, infoUser.token)
      .then((res) => {
        if (res.data) {
          setAllCarte(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [itemInt])
  useEffect(() => {
    getAllEntreprise(infoChantier.planning, infoUser.token).then((e) => {
      setClassement(e.data)
      setWait(false)
    })
  }, [bool, itemObservation])
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_URL}/api/v1/crDocs?planning=${infoChantier.planning}`,
        {
          headers: {
            Authorization: `Bearer ${infoUser.token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.data) {
          if (etatCR) {
            setAdministratif(
              dataBrouillon?.administratif ? dataBrouillon?.administratif : ''
            )
            setGeneralite(
              dataBrouillon.generalites ? dataBrouillon.generalites : ''
            )
          } else {
            setAdministratif(res.data.data[0]?.administratif)
            setGeneralite(res.data.data[0]?.generalite)
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  const verifierChantier = (acces) => {
    const rolePourChantier = acces.find(
      (el) => el.chantierId._id == infoChantier._id
    )
    if (rolePourChantier) {
      return {
        role: rolePourChantier.roles.roleType,
        roleName: rolePourChantier.roles.roleName,
      }
    } else {
      return {
        role: 'Non défini',
        roleName: 'Non défini',
      }
    }
  }
  useEffect(() => {
    setDisabled(true)
    setWait(true)
    setWait2(true)
    getAnnexe(infoChantier.planning, infoUser.token).then((e) => {
      setAllCr(e.data)
    })
    getDataPresence(
      infoChantier.planning,
      infoUser.token,
      etatCR ? dataBrouillon._id : ''
    )
      .then((e) => {
        if (e.responsables) {
          /* getnew table */
          const contacts = e.responsables.reduce((accumulator, responsable) => {
            //console.log(responsable);
            const user = {}
            user._id = responsable._id ? responsable._id : '---'
            user.name = responsable.name ? responsable.name : 'Non défini'
            user.lastname = responsable.lastname
              ? responsable.lastname
              : 'Non défini'
            user.email = responsable.email ? responsable.email : 'Non défini'
            user.telephone = responsable.telephone
              ? responsable.telephone
              : 'Non défini'
            user.present = responsable.statut
              ? responsable.statut.present
              : true
            user.lot = responsable.lot ? responsable.lot : ''
            user.retard = false
            user.absent = false
            user.convoque =
              responsable.role?.roleType === 'ent'
                ? responsable.statut
                  ? responsable.statut.convoque
                  : true
                : responsable.statut
                ? responsable.statut.convoque
                : false
            user.diffusionPresence = responsable.statut
              ? responsable.statut.diffusionPresence
              : true
            user.intervenant = responsable._id ? responsable._id : 'Non défini'
            user.role = verifierChantier(responsable.acces).role
            /*   user.role = responsable.role?.roleType
              ? responsable.role.roleType
              : 'Non défini' */
            user.entreprise = responsable.entrepriseName
              ? responsable.entrepriseName
              : responsable.entreprise
              ? responsable.entreprise
              : 'Non défini'
            user.roleName = verifierChantier(responsable.acces).roleName
            /*  user.roleName = responsable.role?.roleName
              ? responsable.role.roleName
              : 'Non défini' */
            const exist = accumulator.find((res) => res._id === responsable._id)
            if (!exist) {
              accumulator.push(user)
            }
            return accumulator
          }, [])
          setDataPresence(contacts)
          setDisabled(false)
          setWait(false)
          setWait2(false)
        }
      })
      .catch((err) => {
        console.log(err)
        addToast("Une erreur s'est produit.", {
          appearance: 'error',
          autoDismiss: true,
        })
      })
      .finally((res) => {
        setWait(false)
      })
  }, [])
  useEffect(() => {
    getFavori(infoChantier.planning, page, infoUser.token).then((e) => {
      setAllTask(e.data)
    })
  }, [bool1, page, bools])
  useEffect(() => {
    getAllData(
      `planning=${infoChantier.planning}`,
      'lots',
      infoUser.token
    ).then((e) => {
      setEntreprise(e.data)
    })
  }, [bool])
  //Create date picker
  const date = (cell) => {
    const view = document.createElement('input')
    view.type = 'date'
    view.style = 'background-color:rgba(226, 232, 240, 0.4);padding:11px 22px'
    view.defaultValue = cell.getValue()?.split('T')[0]
    view.addEventListener('change', function (e) {
      cell.setValue(e.target.value)
    })
    view.classList.add('form-control')
    const container = document.createElement('span')
    container.appendChild(view)
    return container
  }
  //Create date picker
  const datePM = (cell) => {
    const data = cell.getData()
    const view = document.createElement('input')
    view.type = 'date'
    view.style = 'background-color:rgba(226, 232, 240, 0.4);padding:11px 22px'
    view.defaultValue = cell.getValue()?.split('T')[0]
    if (data.pm) {
      view.disabled = true
    }
    view.addEventListener('change', function (e) {
      cell.setValue(e.target.value)
    })
    view.classList.add('form-control')
    const container = document.createElement('span')
    container.appendChild(view)
    return container
  }

  const inputUrgence = (cell) => {
    const ul = document.createElement('ul')
    ul.className = 'dropdown-menu'
    const option1 = document.createElement('li')
    option1.className = 'dropdown-item'
    option1.innerText = 'Faible'
    option1.addEventListener('click', function (e) {
      e.preventDefault()
      cell.setValue(1)
    })
    const option2 = document.createElement('li')
    option2.className = 'dropdown-item'
    option2.innerText = 'Important'
    option2.addEventListener('click', function (e) {
      e.preventDefault()
      cell.setValue(2)
    })
    const option3 = document.createElement('li')
    option3.className = 'dropdown-item'
    option3.innerText = 'Primordial'
    option3.addEventListener('click', function (e) {
      e.preventDefault()
      cell.setValue(3)
    })
    ul.appendChild(option1)
    ul.appendChild(option2)
    ul.appendChild(option3)
    const btn = document.createElement('button')
    btn.className = 'btn'
    btn.type = 'button'
    btn.addEventListener('click', function (e) {
      e.preventDefault()
    })
    btn.setAttribute('data-bs-toggle', 'dropdown')
    const span = document.createElement('span')
    const icon = document.createElement('i')
    icon.className = 'ti ti-selector'
    if (cell.getValue() == 1) {
      btn.style =
        'color:#0D9488;background-color:rgba(45, 212, 191, 0.15);width:150px;display:flex;justify-content:space-between'
      span.innerText = 'Faible'
    }
    if (cell.getValue() == 2) {
      btn.style =
        'color:#F59E0B;background-color:#FEF3C7;width:150px;display:flex;justify-content:space-between'
      span.innerText = 'Important'
    }
    if (cell.getValue() == 3) {
      btn.style =
        'color:#FB7185;background-color:#FFF1F2;width:150px;display:flex;justify-content:space-between'
      span.innerText = 'Primordial'
    }
    if (!cell.getValue()) {
      btn.style =
        'color:#0D9488;background-color:rgba(45, 212, 191, 0.15);width:150px;display:flex;justify-content:space-between'
      span.innerText = 'Faible'
    }
    btn.appendChild(span)
    btn.appendChild(icon)
    const container = document.createElement('div')
    container.className = 'dropdown dropend'
    container.style = 'position:relative;z-index:999'
    container.appendChild(btn)
    container.appendChild(ul)
    return container
  }
  const simpleInput = (cell) => {
    const input = document.createElement('input')
    input.type = 'text'
    input.style =
      'width:100%;border:none;padding:11px 22px;font-size:16px;font-weight:600;color:#334155;overflow:visible'
    input.defaultValue = cell.getValue()
    input.addEventListener('blur', function (ev) {
      ev.preventDefault()
      cell.setValue(ev.target.value)
    })
    const container = document.createElement('div')
    container.style = 'display:flex;align-items:center;width:100%'
    container.appendChild(input)
    return container
  }

  const checkbox = (cell) => {
    const input = document.createElement('input')
    input.type = 'checkbox'
    input.className = 'form-check-input'
    input.defaultChecked = cell.getValue()
    input.addEventListener('change', function (e) {
      cell.setValue(!cell.getValue())
    })
    const view = document.createElement('div')
    view.className = 'form-check form-switch  mt-2'
    view.style = 'color:#2DD4BF;margin-left:12px'
    view.appendChild(input)
    return view
  }

  //formatter de date en fr
  const dateFr = (value) => {
    const dt = value.split('T')[0]
    const dt1 = dt.split('-')
    const result = `${dt1[2]}/${dt1[1]}/${dt1[0]}`
    return result
  }
  //del Function
  const pop = (candidate, data) => {
    return data.filter((d) => d._id !== candidate._id)
  }

  //sauvegarde compte rendu
  const handleSubmit = (etatCr) => {
    localStorage.setItem('typeRapport', 'cr')
    const formData = new FormData()
    if (btnPartage && confirmationLtp) {
      const corps = document.createElement('div')
      corps.style =
        'position:relative;z-index:222222222222222222222;background-color:white;overflow:visible !important;width:100%'
      const buttonHide = document.querySelector('.dhx_cal_today_button')
      buttonHide.style = 'display:none'
      const buttonHide1 = document.querySelector('.dhx_cal_prev_button')
      buttonHide1.style = 'display:none'
      const buttonHide2 = document.querySelector('.dhx_cal_next_button')
      buttonHide2.style = 'display:none'
      const navPlanner = document.querySelector('.dhx_cal_navline')
      const headPlanner = document.querySelector('.dhx_cal_header')
      headPlanner.style =
        'position:absolute !important; top:57px;left:0px;height:57px;width:100%;overflow:visible !important'
      const dataPlanner = document.querySelector('.dhx_timeline_table_wrapper')
      dataPlanner.style ='position:absolute;top:110px;left:0px;background-color:white;width:100%'
      corps.appendChild(navPlanner)
      corps.appendChild(headPlanner)
      corps.appendChild(dataPlanner)
      console.log(corps.innerHTML)
      if (etatCR) {
        formData.append('oldCr', JSON.stringify(dataBrouillon.oldCr))
      }
      formData.append('planningGlissantHtml', corps.innerHTML)
      formData.append('withPg', btnPartage)
      formData.append('administratif', administratif)
      formData.append('generalite', generalite)
      formData.append('planning', infoChantier.planning)
      formData.append('typeCr', typeCr)
      formData.append('presence', JSON.stringify(dataPresence))
      formData.append('annexes', JSON.stringify(annexe))
      formData.append('dateCr', dateRendu)
      formData.append('etatCr', etatCr)
      formData.append('dateProchainCr', dateProchainReunion)
    } else {
      if (etatCR) {
        formData.append('oldCr', JSON.stringify(dataBrouillon.oldCr))
      }
      formData.append('administratif', administratif)
      formData.append('generalite', generalite)
      formData.append('planning', infoChantier.planning)
      formData.append('typeCr', typeCr)
      formData.append('presence', JSON.stringify(dataPresence))
      formData.append('annexes', JSON.stringify(annexe))
      formData.append('dateCr', dateRendu)
      formData.append('dateProchainCr', dateProchainReunion)
      formData.append('withPg', JSON.stringify(false))
      formData.append('etatCr', etatCr)
      file.map((e) => {
        formData.append('annexe', e.originFileObj)
      })
    }
    axios
      .post(
        `${process.env.REACT_APP_URL}/api/v1/comptesrendu/getCr?planning=${infoChantier.planning}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${infoUser.token}`,
          },
        }
      )
      .then((e) => {
        addToast('Le compte rendu a été créé avec succès.', {
          appearance: 'success',
          autoDismiss: true,
        })
        setWait(false)
        setWait2(false)
        navigate('/Rapport_CR')
      })
      .catch((e) => {
        console.log(e)
        addToast("Une erreur s'est produit.", {
          appearance: 'error',
          autoDismiss: true,
        })
        setWait(false)
        setWait2(false)
        setDisabled(false)
      })
  }
  const maj = (cell, info) => {
    cell.setValue(info)
  }
  const majPhoto = (cell, info) => {
    cell.getRow().update(info)
  }

  //Modification de la partie suivi
  const planningEdited = async (cell) => {
    const data = cell.getData()
    if (cell.getField() == 'faitLe') {
      if (
        (data.faitLe == '') |
        (data.faitLe == undefined) |
        (data.faitLe == null)
      ) {
        data.statut = false
        updateData(data, `planningSo`, infoUser.token, infoChantier.planning)
        cell.getRow().update(data)
      } else {
        data.statut = true
        updateData(data, `planningSo`, infoUser.token, infoChantier.planning)
        cell.getRow().update(data)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.faitLe == '') |
            (data.faitLe == undefined) |
            (data.faitLe == null)
          ) {
            data.faitLe = new Date(Date.now()).toISOString()
            updateData(
              data,
              `planningSo`,
              infoUser.token,
              infoChantier.planning
            )
            cell.getRow().update(data)
          }
        } else {
          data.faitLe = ''
          updateData(data, `planningSo`, infoUser.token, infoChantier.planning)
          cell.getRow().update(data)
        }
      } else {
        updateData(data, `planningSo`, infoUser.token, infoChantier.planning)
          .then((res) => {
            if (res.data.data) {
              cell.getRow().update(res.data.data)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }

  const engagementEdited = async (cell) => {
    const data = cell.getData()
    if (cell.getField() == 'faitLe') {
      if (
        (data.faitLe == '') |
        (data.faitLe == undefined) |
        (data.faitLe == null)
      ) {
        data.statut = false
        updateData(data, `engagements`, infoUser.token, infoChantier.planning)
        cell.getRow().update(data)
      } else {
        data.statut = true
        updateData(data, `engagements`, infoUser.token, infoChantier.planning)
        cell.getRow().update(data)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.faitLe == '') |
            (data.faitLe == undefined) |
            (data.faitLe == null)
          ) {
            data.faitLe = new Date(Date.now()).toISOString()
            updateData(
              data,
              `engagements`,
              infoUser.token,
              infoChantier.planning
            )
            cell.getRow().update(data)
          }
        } else {
          data.faitLe = ''
          updateData(data, `engagements`, infoUser.token, infoChantier.planning)
          cell.getRow().update(data)
        }
      } else {
        updateData(data, `engagements`, infoUser.token, infoChantier.planning)
          .then((res) => {
            if (res.data.data) {
              cell.getRow().update(res.data.data)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }

  const administratifEdited = async (cell) => {
    const data = cell.getData()
    if (cell.getField() == 'faitLe') {
      if (
        (data.faitLe == '') |
        (data.faitLe == undefined) |
        (data.faitLe == null)
      ) {
        data.statut = false
        updateData(
          data,
          `administratifs`,
          infoUser.token,
          infoChantier.planning
        )
        cell.getRow().update(data)
      } else {
        data.statut = true
        updateData(
          data,
          `administratifs`,
          infoUser.token,
          infoChantier.planning
        )
        cell.getRow().update(data)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (!data.prevuLe) {
          data.prevuLe = new Date(Date.now()).toISOString()
        }
        if (data.statut) {
          if (
            (data.faitLe == '') |
            (data.faitLe == undefined) |
            (data.faitLe == null)
          ) {
            data.faitLe = new Date(Date.now()).toISOString()
            updateData(
              data,
              `administratifs`,
              infoUser.token,
              infoChantier.planning
            )
            cell.getRow().update(data)
          }
        } else {
          data.faitLe = ''
          updateData(
            data,
            `administratifs`,
            infoUser.token,
            infoChantier.planning
          )
          cell.getRow().update(data)
        }
      } else {
        updateData(
          data,
          'administratifs',
          infoUser.token,
          infoChantier.planning
        )
        cell.getRow().update(data)
      }
    }
  }
  const documentEdited = async (cell) => {
    const data = cell.getData()
    if (cell.getField() == 'diffusion') {
      if (
        (data.diffusion == '') |
        (data.diffusion == undefined) |
        (data.diffusion == null)
      ) {
        data.statut = false
        updateData(data, `documents`, infoUser.token, infoChantier.planning)
        cell.getRow().update(data)
      } else {
        data.statut = true
        updateData(data, `documents`, infoUser.token, infoChantier.planning)
        cell.getRow().update(data)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.diffusion == '') |
            (data.diffusion == undefined) |
            (data.diffusion == null)
          ) {
            data.diffusion = new Date(Date.now()).toISOString()
            updateData(data, `documents`, infoUser.token, infoChantier.planning)
            cell.getRow().update(data)
          }
        } else {
          data.diffusion = ''
          updateData(data, `documents`, infoUser.token, infoChantier.planning)
          cell.getRow().update(data)
        }
      } else {
        updateData(data, `documents`, infoUser.token, infoChantier.planning)
        cell.getRow().update(data)
      }
    }
  }
  const securiteEdited = async (cell) => {
    const data = cell.getData()
    if (cell.getField() == 'faitLe') {
      if (
        (data.faitLe == '') |
        (data.faitLe == undefined) |
        (data.faitLe == null)
      ) {
        data.statut = false
        updateData(data, `securites`, infoUser.token, infoChantier.planning)
        cell.getRow().update(data)
      } else {
        data.statut = true
        updateData(data, `securites`, infoUser.token, infoChantier.planning)
        cell.getRow().update(data)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.faitLe == '') |
            (data.faitLe == undefined) |
            (data.faitLe == null)
          ) {
            data.faitLe = new Date(Date.now()).toISOString()
            updateData(data, `securites`, infoUser.token, infoChantier.planning)
            cell.getRow().update(data)
          }
        } else {
          data.faitLe = ''
          updateData(data, `securites`, infoUser.token, infoChantier.planning)
          cell.getRow().update(data)
        }
      } else {
        updateData(data, `securites`, infoUser.token, infoChantier.planning)
          .then((res) => {
            if (res.data.data) {
              cell.getRow().update(res.data.data)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }
  const ctEdited = async (cell) => {
    const data = cell.getData()
    if (cell.getField() == 'faitLe') {
      if (
        (data.faitLe == '') |
        (data.faitLe == undefined) |
        (data.faitLe == null)
      ) {
        data.statut = false
        updateData(
          data,
          `controleurTechniques`,
          infoUser.token,
          infoChantier.planning
        )
        cell.getRow().update(data)
      } else {
        data.statut = true
        updateData(
          data,
          `controleurTechniques`,
          infoUser.token,
          infoChantier.planning
        )
        cell.getRow().update(data)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.faitLe == '') |
            (data.faitLe == undefined) |
            (data.faitLe == null)
          ) {
            data.faitLe = new Date(Date.now()).toISOString()
            updateData(
              data,
              `controleurTechniques`,
              infoUser.token,
              infoChantier.planning
            )
            cell.getRow().update(data)
          }
        } else {
          data.faitLe = ''
          updateData(
            data,
            `controleurTechniques`,
            infoUser.token,
            infoChantier.planning
          )
          cell.getRow().update(data)
        }
      } else {
        updateData(
          data,
          `controleurTechniques`,
          infoUser.token,
          infoChantier.planning
        )
          .then((res) => {
            if (res.data.data) {
              cell.getRow().update(res.data.data)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }
  const installationEdited = async (cell) => {
    const data = cell.getData()
    if (cell.getField() == 'faitLe') {
      if (
        (data.faitLe == '') |
        (data.faitLe == undefined) |
        (data.faitLe == null)
      ) {
        data.statut = false
        updateData(
          data,
          `installationchantiers`,
          infoUser.token,
          infoChantier.planning
        )
        cell.getRow().update(data)
      } else {
        data.statut = true
        updateData(
          data,
          `installationchantiers`,
          infoUser.token,
          infoChantier.planning
        )
        cell.getRow().update(data)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.faitLe == '') |
            (data.faitLe == undefined) |
            (data.faitLe == null)
          ) {
            data.faitLe = new Date(Date.now()).toISOString()
            updateData(
              data,
              `installationchantiers`,
              infoUser.token,
              infoChantier.planning
            )
            cell.getRow().update(data)
          }
        } else {
          data.faitLe = ''
          updateData(
            data,
            `installationchantiers`,
            infoUser.token,
            infoChantier.planning
          )
          cell.getRow().update(data)
        }
      } else {
        updateData(
          data,
          `installationchantiers`,
          infoUser.token,
          infoChantier.planning
        )
          .then((res) => {
            if (res.data.data) {
              cell.getRow().update(res.data.data)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }
  const effectifEdited = async (cell) => {
    const data = cell.getData()
    updateData(data, `effectifs`, infoUser.token, infoChantier.planning).then(
      (e) => {
        console.log(e)
      }
    )
  }

  //Ajout partie suivi
  const addPlanning = async (id, lot) => {
    setWait(true)
    addData(
      {
        titre: 'New',
        noteLe: Date.now(),
        acteLe: Date.now(),
        entreprise: id,
        planning: infoChantier.planning,
      },
      'planningSo',
      lot,
      infoUser.token
    ).then((e) => {
      setBool(!bool)
      setDataPlanningSo([e, ...dataPlanningSo])
    })
  }

  const addEngagementRow = async (id, lot) => {
    setWait(true)
    addData(
      {
        titre: 'New',
        noteLe: Date.now(),
        entreprise: id,
        planning: infoChantier.planning,
      },
      'engagements',
      lot,
      infoUser.token
    ).then((e) => {
      setBool(!bool)
      setDataEngagement([e, ...dataEngagement])
    })
  }
  const addAdministratifRow = async (_id, lot) => {
    setWait(true)
    addData(
      {
        titre: 'New',
        noteLe: Date.now(),
        entreprise: _id,
        planning: infoChantier.planning,
      },
      'administratifs',
      lot,
      infoUser.token
    ).then((e) => {
      setBool(!bool)
      setDataAdministratif([e, ...dataAdministratif])
    })
  }
  const addDocumentRow = async (_id, lot) => {
    setWait(true)
    addData(
      {
        titre: 'New',
        noteLe: Date.now(),
        entreprise: _id,
        planning: infoChantier.planning,
      },
      'documents',
      lot,
      infoUser.token
    ).then((e) => {
      if (e.name == 'AxiosError' || e.message == 'erreur') {
        setWait(false)
        alert("Une erreur s'est produit")
      } else {
        setBool(!bool)
        setDataDocument([e, ...dataDocument])
      }
    })
  }
  const addSecuriteRow = async (_id, lot) => {
    setWait(true)
    addData(
      {
        titre: 'New',
        noteLe: Date.now(),
        entreprise: _id,
        planning: infoChantier.planning,
      },
      'securites',
      lot,
      infoUser.token
    ).then((e) => {
      setBool(!bool)
      setDataSecurite([e, ...dataSecurite])
    })
  }
  const addCTRow = async (_id, lot) => {
    setWait(true)
    addData(
      {
        titre: 'New',
        noteLe: Date.now(),
        entreprise: _id,
        planning: infoChantier.planning,
      },
      'controleurTechniques',
      lot,
      infoUser.token
    ).then((e) => {
      setBool(!bool)
      setDataCT([e, ...dataCT])
    })
  }
  const addInstallationRow = async (_id, lot) => {
    setWait(true)
    addData(
      {
        titre: 'New',
        noteLe: Date.now(),
        entreprise: _id,
        planning: infoChantier.planning,
      },
      'installationchantiers',
      lot,
      infoUser.token
    ).then((e) => {
      setBool(!bool)
      setDataInstallation([e, ...dataInstallation])
    })
  }
  const addEffectifRow = async (_id, lot) => {
    setWait(true)
    const result = await addData(
      {
        formattedSemaine: `${getISOWeekYear(Date.now())}-W${getISOWeek(
          new Date()
        )}`,
        noteLe: Date.now(),
        prevu: 0,
        entreprise: _id,
        planning: infoChantier.planning,
      },
      'effectifs',
      lot,
      infoUser.token
    ).then((e) => {
      console.log(e)
      setBool(!bool)
      return e
    })
    setDataEffectif([{ ...result }, ...dataEffectif])
  }

  //suppression partie suivi
  const delPlanning = async (cell) => {
    setWait(true)
    try {
      await delData(
        cell.getData(),
        'planningSo',
        infoUser.token,
        infoChantier.planning
      ).then((e) => {
        cell.getRow().delete()
        setWait(false)
      })
    } catch (err) {
      alert('le serveur ne répond pas')
    }
  }

  const delEngagements = async (cell) => {
    setWait(true)
    try {
      await delData(
        cell.getData(),
        'engagements',
        infoUser.token,
        infoChantier.planning
      ).then((e) => {
        cell.getRow().delete()
        setWait(false)
      })
    } catch (err) {
      alert('le serveur ne répond pas')
    }
  }

  const delAdministratifs = async (cell) => {
    setWait(true)
    try {
      await delData(
        cell.getData(),
        'administratifs',
        infoUser.token,
        infoChantier.planning
      ).then((e) => {
        cell.getRow().delete()
        setWait(false)
      })
    } catch (err) {
      alert('le serveur ne répond pas')
    }
  }

  const delDocuments = async (cell) => {
    setWait(true)
    try {
      await delData(
        cell.getData(),
        'documents',
        infoUser.token,
        infoChantier.planning
      ).then((e) => {
        cell.getRow().delete()
        setWait(false)
      })
    } catch (err) {
      alert('le serveur ne répond pas')
    }
  }

  const delSecurites = async (cell) => {
    setWait(true)
    try {
      await delData(
        cell.getData(),
        'securites',
        infoUser.token,
        infoChantier.planning
      ).then((e) => {
        cell.getRow().delete()
        setWait(false)
      })
    } catch (err) {
      alert('le serveur ne répond pas')
    }
  }
  const delCT = async (cell) => {
    setWait(true)
    try {
      await delData(
        cell.getData(),
        'controleurTechniques',
        infoUser.token,
        infoChantier.planning
      ).then((e) => {
        cell.getRow().delete()
        setWait(false)
      })
    } catch (err) {
      alert('le serveur ne répond pas')
    }
  }
  const delInstallationChantier = async (cell) => {
    setWait(true)
    try {
      await delData(
        cell.getData(),
        'installationchantiers',
        infoUser.token,
        infoChantier.planning
      ).then((e) => {
        cell.getRow().delete()
        setWait(false)
      })
    } catch (err) {
      alert('le serveur ne répond pas')
    }
  }
  const delEffectif = async (cell) => {
    setWait(true)
    try {
      await delData(
        cell.getData(),
        'effectifs',
        infoUser.token,
        infoChantier.planning
      ).then((e) => {
        cell.getRow().delete()
        setWait(false)
      })
    } catch (err) {
      addToast(err, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  //input semaine
  const semaine = (cell) => {
    const data = cell.getData()
    const div = document.createElement('div')
    const week = document.createElement('input')
    week.classList = 'form-control'
    week.type = 'week'
    week.defaultValue = data.formattedSemaine ? data.formattedSemaine : ''
    week.addEventListener('change', function (e) {
      updateData(
        { ...data, formattedSemaine: e.target.value },
        `effectifs`,
        infoUser.token,
        infoChantier.planning
      ).then((e) => {
        e.data
          ? setDataEffectif(
              dataEffectif.map((el) => {
                if (el._id == e.data._id) {
                  return e.data
                } else {
                  return el
                }
              })
            )
          : console.log(e)
      })
    })
    div.appendChild(week)
    return div
  }
  //Effectif pers
  const pers = (cell) => {
    const data = cell.getData()
    const div = document.createElement('div')
    div.style = 'display:flex;align-items:center'
    const inputPers = document.createElement('input')
    inputPers.type = 'number'
    inputPers.className = 'form-control'
    inputPers.style = 'width:70px;text-align:rigth'
    inputPers.defaultValue = data.prevu ? data.prevu : 0
    inputPers.addEventListener('change', function (e) {
      updateData(
        { ...data, prevu: e.target.value },
        `effectifs`,
        infoUser.token,
        infoChantier.planning
      ).then((e) => {
        e.data
          ? setDataEffectif(
              dataEffectif.map((el) => {
                if (el._id == e.data._id) {
                  return e.data
                } else {
                  return el
                }
              })
            )
          : console.log(e)
      })
    })
    const unite = document.createElement('sapan')
    unite.innerText = 'per(s)'
    div.appendChild(inputPers)
    div.appendChild(unite)
    return div
  }
  //Effectif pers
  const pers2 = (cell) => {
    const data = cell.getData()
    const div = document.createElement('div')
    div.style = 'display:flex;align-items:center'
    const inputPers = document.createElement('input')
    inputPers.type = 'number'
    inputPers.className = 'form-control'
    inputPers.style = 'width:70px;text-align:rigth'
    inputPers.defaultValue = data.reel?.present ? data.reel.present : 0
    inputPers.addEventListener('change', function (e) {
      updateData(
        { ...data, reel: { ...data.reel, present: e.target.value } },
        `effectifs`,
        infoUser.token,
        infoChantier.planning
      ).then((e) => {
        e.data
          ? setDataEffectif(
              dataEffectif.map((el) => {
                if (el._id == e.data._id) {
                  return e.data
                } else {
                  return el
                }
              })
            )
          : console.log(e)
      })
    })
    const unite = document.createElement('sapan')
    unite.innerHTML = `<span>per(s) <span style='color:${
      data.reel?.color ? data.reel.color : 'blue'
    };font-weight:bold'>(${
      data.reel?.diff ? data.reel.diff : 0
    })</span> </span>`
    div.appendChild(inputPers)
    div.appendChild(unite)
    return div
  }

  //format boutton
  const button = (delFunction) => (cell) => {
    const delIcon = document.createElement('img')
    delIcon.src = trash
    delIcon.style = 'width:24px;height:24px'

    //create delete button
    var delBt = document.createElement('button')
    delBt.style = 'padding:8px 10px; border:1px solid #CBD5E1 '
    delBt.type = 'button'
    delBt.appendChild(delIcon)
    delBt.classList.add('btn')
    delBt.addEventListener('click', function () {
      delFunction(cell)
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(delBt)

    return buttonHolder
  }
  //Modal document
  const modalDocument = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')
    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalDoc')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      setDataModalDocument(cell.getData())
      setDataModalSuiviDoc(
        cell.getData().suiviDocs ? cell.getData().suiviDocs : []
      )
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)
    return buttonHolder
  }
  //Modal document
  const modalAction = (value) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-download')
    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalA')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      setCibleAction(value)
      setDataMaj(cell)
      setDataModalAction(cell.getData())
      setImageAction(null)
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)
    return buttonHolder
  }

  //Modal Plannification
  const modalPlannification = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalPlannification')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      setDataMaj(cell)
      setDataModalPlanification(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }
  //Modal Engagement
  const modalEngagement = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalEngagement')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      setDataMaj(cell)
      setDataModalEngagement(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }
  //Modal Administratif
  const modalAdministratif = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalAdministratif')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      setDataMaj(cell)
      setDataModalAdministration(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }
  //Modal Securite
  const modalSecurite = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalSecurite')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      setDataMaj(cell)
      setDataModalSecurite(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }
  //Modal Controle technique CT
  const modalCT = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalCT')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      setDataMaj(cell)
      setDataModalCT(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }
  //Modal Installation
  const modalInstallation = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalInstallation')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      setDataMaj(cell)
      setDataModalInstallation(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }
  //Modal Effectif
  const modalEffectif = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalEffectif')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      setDataMaj(cell)
      setDataModalEffectif(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }
  const getItem = (value) => {
    return value
  }
  //Assigenment nouvelle donner action
  const assignDataAction = (item, data) => {
    switch (item) {
      case 'planningSo':
        setDataPlanningSo(
          dataPlanningSo.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
      case 'installationchantiers':
        setDataInstallation(
          dataInstallation.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
      case 'securites':
        setDataSecurite(
          dataSecurite.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
      case 'controleurTechniques':
        setDataCT(
          dataCT.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
      case 'engagements':
        setDataEngagement(
          dataEngagement.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
    }
  }
  const colonneEngagement = [
    {
      title: 'Pour mémoire',
      field: 'pourMemoire',
      width: 100,
      formatter: checkbox,
    },
    { title: 'Engagements', field: 'titre', formatter: simpleInput },
    { title: 'Noté Le', field: 'noteLe', formatter: date },
    { title: 'Prévu Le', field: 'prevuLe', formatter: datePM },
    { title: 'Fait Le', field: 'faitLe', formatter: date },
    {
      title: 'Urgence',
      minWidth: 170,
      field: 'urgence',
      formatter: inputUrgence,
    },
    { title: 'PM', field: 'pm', width: 100, formatter: checkbox },
    { title: 'DQP', field: 'dqp', width: 100, formatter: checkbox },
    { title: 'Tenue', field: 'statut', width: 100, formatter: checkbox },
    {
      title: 'Details',
      field: 'observation',
      width: 100,
      formatter: modalEngagement(),
    },
    {
      title: 'Action',
      width: 100,
      formatter: modalAction(getItem('engagements')),
    },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delEngagements),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {},
    },
  ]
  const colonneAdministratif = [
    {
      title: 'Pour mémoire',
      field: 'pourMemoire',
      width: 100,
      formatter: checkbox,
    },
    { title: 'Titre', field: 'titre', formatter: simpleInput },
    { title: 'Noté Le', field: 'noteLe', formatter: date },
    { title: 'Prévu Le', field: 'prevuLe', formatter: date },
    { title: 'Fait Le', field: 'faitLe', formatter: date },
    { title: 'Statut', field: 'statut', width: 100, formatter: checkbox },
    {
      title: 'Details',
      field: 'observation',
      width: 100,
      formatter: modalAdministratif(),
    },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delAdministratifs),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {},
    },
  ]
  const colonneDocument = [
    {
      title: 'Pour mémoire',
      field: 'pourMemoire',
      width: 100,
      formatter: checkbox,
    },
    { title: 'Titre', field: 'titre', formatter: simpleInput },
    { title: 'Phase', field: 'phase', formatter: simpleInput },
    { title: 'Type', field: 'type', formatter: simpleInput },
    { title: 'Niveau', field: 'niveau', formatter: simpleInput },
    { title: 'Zone', field: 'zone', formatter: simpleInput },
    { title: 'Indice', field: 'indice', formatter: simpleInput },
    { title: 'Date BPE', field: 'dateBPE', formatter: date },
    { title: 'Prévu Le', field: 'prevision', formatter: date },
    { title: 'Diffusé Le', field: 'diffusion', formatter: date },
    { title: 'Visa', field: 'visa' },
    { title: 'Statut', field: 'statut', width: 100, formatter: checkbox },
    {
      title: 'Details',
      field: 'functionButton',
      formatter: modalDocument(),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {},
    },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delDocuments),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {},
    },
  ]
  const colonnePlanning = [
    {
      title: 'Pour mémoire',
      field: 'pourMemoire',
      width: 100,
      formatter: checkbox,
    },
    { title: 'Tâches', field: 'titre', formatter: simpleInput },
    { title: 'Noté Le', field: 'noteLe', formatter: date },
    { title: 'Planifié Le', field: 'prevuLe', formatter: datePM },
    { title: 'Réalisé Le', field: 'faitLe', formatter: date },
    {
      title: 'Urgence',
      minWidth: 170,
      field: 'urgence',
      formatter: inputUrgence,
    },
    { title: 'PM', field: 'pm', width: 100, formatter: checkbox },
    { title: 'DQP', field: 'dqp', width: 100, formatter: checkbox },
    { title: 'Tenue', field: 'statut', width: 100, formatter: checkbox },
    {
      title: 'Details',
      field: 'observation',
      width: 100,
      formatter: modalPlannification(),
    },
    {
      title: 'Action',
      width: 100,
      formatter: modalAction(getItem('planningSo')),
    },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delPlanning),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {},
    },
  ]
  const colonneSecurite = [
    {
      title: 'Pour mémoire',
      field: 'pourMemoire',
      width: 100,
      formatter: checkbox,
    },
    { title: 'Remarques', field: 'titre', formatter: simpleInput },
    { title: 'Noté Le', field: 'noteLe', formatter: date },
    { title: 'Prévu Le', field: 'prevuLe', formatter: datePM },
    { title: 'Fait Le', field: 'faitLe', formatter: date },
    {
      title: 'Urgence',
      minWidth: 170,
      field: 'urgence',
      formatter: inputUrgence,
    },
    { title: 'PM', field: 'pm', width: 100, formatter: checkbox },
    { title: 'DQP', field: 'dqp', width: 100, formatter: checkbox },
    { title: 'Statut', field: 'statut', width: 100, formatter: checkbox },
    {
      title: 'Details',
      field: 'observation',
      width: 100,
      formatter: modalSecurite(),
    },
    {
      title: 'Action',
      width: 100,
      formatter: modalAction(getItem('securites')),
    },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delSecurites),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {},
    },
  ]
  const colonneCT = [
    { title: 'Remarques', field: 'titre', formatter: simpleInput },
    { title: 'Noté Le', field: 'noteLe', formatter: date },
    { title: 'Prévu Le', field: 'prevuLe', formatter: datePM },
    { title: 'Fait Le', field: 'faitLe', formatter: date },
    {
      title: 'Urgence',
      minWidth: 170,
      field: 'urgence',
      formatter: inputUrgence,
    },
    { title: 'PM', field: 'pm', width: 100, formatter: checkbox },
    { title: 'DQP', field: 'dqp', width: 100, formatter: checkbox },
    { title: 'Statut', field: 'statut', width: 100, formatter: checkbox },
    {
      title: 'Details',
      field: 'observation',
      width: 100,
      formatter: modalCT(),
    },
    {
      title: 'Action',
      width: 100,
      formatter: modalAction(getItem('controleurTechniques')),
    },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delCT),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {},
    },
  ]
  const colonneInstallation = [
    {
      title: 'Pour mémoire',
      field: 'pourMemoire',
      width: 100,
      formatter: checkbox,
    },
    { title: 'Remarques', field: 'titre', formatter: simpleInput },
    { title: 'Noté Le', field: 'noteLe', formatter: date },
    { title: 'Prévu Le', field: 'prevuLe', formatter: datePM },
    { title: 'Fait Le', field: 'faitLe', formatter: date },
    {
      title: 'Urgence',
      minWidth: 170,
      field: 'urgence',
      formatter: inputUrgence,
    },
    { title: 'PM', field: 'pm', width: 100, formatter: checkbox },
    { title: 'DQP', field: 'dqp', width: 100, formatter: checkbox },
    { title: 'Statut', field: 'statut', width: 100, formatter: checkbox },
    {
      title: 'Details',
      field: 'observation',
      width: 100,
      formatter: modalInstallation(),
    },
    {
      title: 'Action',
      width: 100,
      formatter: modalAction(getItem('installationchantiers')),
    },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delInstallationChantier),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {},
    },
  ]
  const colonneEffectif = [
    {
      title: 'Pour mémoire',
      field: 'pourMemoire',
      width: 100,
      formatter: checkbox,
    },
    { title: 'Semaine(s)', field: '', formatter: semaine },
    { title: 'Noté le', field: 'noteLe', formatter: date },
    { title: 'Prévu', field: 'prevu', formatter: pers },
    { title: 'Réel (delta)', field: 'reel.present', formatter: pers2 },
    {
      title: 'Details',
      field: 'observation',
      width: 100,
      formatter: modalEffectif(),
    },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delEffectif),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {},
    },
  ]
  //fonction pour le nav bar scroll horizontal
  const rights = () => {
    const element = document.querySelector('.wrappers')
    const item = document.querySelector('.items')
    element.scrollLeft = element.scrollLeft + item.clientWidth + 10
  }

  const lefts = () => {
    const element = document.querySelector('.wrappers')
    const item = document.querySelector('.items')
    element.scrollLeft = element.scrollLeft - item.clientWidth + 10
  }
  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={true} active="rapport" />
        <Header bool={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fuild">
            <div class="page-title-box">
              <div class="float-end"></div>
              <span style={{ marginLeft: '10px' }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="#94A3B8"
                  style={{ width: '16px', height: '16px' }}>
                  <path
                    fillRule="evenodd"
                    d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                    clipRule="evenodd"
                  />
                </svg>{' '}
                <span
                  style={{
                    color: '#94A3B8',
                    fontSize: '14px',
                    fontWeight: '400',
                  }}>
                  / Rapports
                </span>
                <span
                  style={{
                    color: '#334155',
                    fontSize: '14px',
                    fontWeight: '600',
                  }}>
                  {' '}
                  / Nouveau compte rendu
                </span>
              </span>{' '}
              <br />
              <span
                style={{
                  marginLeft: '15px',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <span
                  style={{
                    backgroundColor: '#E2E8F0',
                    padding: '2px',
                    borderRadius: '6px',
                  }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="#6366F1"
                    style={{
                      width: '20px',
                      height: '20px',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      navigate('/Rapport_CR')
                    }}>
                    <path
                      fillRule="evenodd"
                      d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
                <span
                  style={{
                    color: '#334155',
                    fontSize: '20px',
                    fontWeight: '600',
                    marginLeft: '10px',
                  }}>
                  {' '}
                  Nouveau compte rendu
                </span>
                <div
                  class="form-floating"
                  style={{ width: '200px', marginLeft: '1vw' }}>
                  <input
                    type="date"
                    class="form-control"
                    id="floatingInput"
                    defaultValue={dateRendu.toISOString().substring(0, 10)}
                    onChange={(e) => {
                      if (e.target.value) {
                        setDateRendu(new Date(e.target.value))
                      }
                    }}
                  />
                  <label for="floatingInput">Date du compte rendu</label>
                </div>
                <div
                  class="form-floating"
                  style={{ width: '200px', marginLeft: '1vw' }}>
                  <input
                    type="date"
                    class="form-control"
                    id="floatingInput"
                    defaultValue={dateProchainReunion
                      .toISOString()
                      .substring(0, 10)}
                    onChange={(e) => {
                      if (e.target.value) {
                        setDateProchainReunion(new Date(e.target.value))
                      }
                    }}
                  />
                  <label for="floatingInput">Date du prochain réunion</label>
                </div>
                <div
                  style={{
                    width: '200px',
                    marginLeft: '1vw',
                    backgroundColor: 'white',
                    padding: '5px 10px',
                    border: 'solid #D9D9D9 0.5px',
                    borderRadius: '4px',
                  }}>
                  <label
                    for="floatingInput"
                    style={{
                      fontSize: '12px',
                      fontWeight: '400',
                      color: '#64748B',
                    }}>
                    Type du CR
                  </label>
                  <button
                    data-bs-toggle="dropdown"
                    className="btn"
                    style={{
                      backgroundColor: 'white',
                      display: 'flex',
                      justifyContent: 'space-between',
                      color: '#334155',
                      width: '100%',
                      padding: '0px 0px',
                    }}>
                    <span>{typeCr}</span>
                    <i className="ti ti-selector"></i>
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1">
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={(ev) => {
                          ev.preventDefault()
                          setTypeCr('Présentiel')
                        }}>
                        Présentiel
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={(ev) => {
                          ev.preventDefault()
                          setTypeCr('Visio')
                        }}>
                        Visio
                      </a>
                    </li>
                  </ul>
                </div>
              </span>
            </div>
          </div>
          {/* wizard pour compte rendu et fiche de visite */}
          <div class="row">
            <div class="col-12">
              <div
                class="card"
                style={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  boxShadow: 'none',
                }}>
                <div class="card-body">
                  <form action="" method="post" id="custom-step">
                    <nav>
                      <div class="nav nav-tabs" id="nav-tab">
                        <a
                          class="nav-link"
                          id="step1-tab"
                          data-bs-toggle="tab"
                          href="#step1"
                          style={
                            item == 'presence'
                              ? {
                                  color: '#334155',
                                  fontSize: '16px',
                                  fontWeight: '600',
                                  backgroundColor: 'transparent',
                                  borderBottom: 'solid #6366F1 3px',
                                }
                              : {
                                  fontSize: '16px',
                                  fontWeight: '400',
                                  color: '#64748B',
                                  backgroundColor: 'transparent',
                                }
                          }
                          onClick={(e) => {
                            setItem('presence')
                          }}>
                          Présence
                        </a>
                        <a
                          class="nav-link"
                          id="step2-tab"
                          data-bs-toggle="tab"
                          href="#step2"
                          style={
                            item == 'administratif'
                              ? {
                                  color: '#334155',
                                  fontSize: '16px',
                                  fontWeight: '600',
                                  backgroundColor: 'transparent',
                                  borderBottom: 'solid #6366F1 3px',
                                }
                              : {
                                  fontSize: '16px',
                                  fontWeight: '400',
                                  color: '#64748B',
                                  backgroundColor: 'transparent',
                                }
                          }
                          onClick={(e) => {
                            setItem('administratif')
                          }}>
                          Administratif et Généralité
                        </a>
                        <a
                          class="nav-link"
                          id="step4-tab"
                          data-bs-toggle="tab"
                          href="#step4"
                          style={
                            item == 'planning'
                              ? {
                                  color: '#334155',
                                  fontSize: '16px',
                                  fontWeight: '600',
                                  backgroundColor: 'transparent',
                                  borderBottom: 'solid #6366F1 3px',
                                }
                              : {
                                  fontSize: '16px',
                                  fontWeight: '400',
                                  color: '#64748B',
                                  backgroundColor: 'transparent',
                                }
                          }
                          onClick={(e) => {
                            setItem('planning')
                          }}>
                          Suivi Planning
                        </a>
                        <a
                          class="nav-link"
                          id="step3-tab"
                          data-bs-toggle="tab"
                          href="#step3"
                          style={
                            item == 'suivi'
                              ? {
                                  color: '#334155',
                                  fontSize: '16px',
                                  fontWeight: '600',
                                  backgroundColor: 'transparent',
                                  borderBottom: 'solid #6366F1 3px',
                                }
                              : {
                                  fontSize: '16px',
                                  fontWeight: '400',
                                  color: '#64748B',
                                  backgroundColor: 'transparent',
                                }
                          }
                          onClick={(e) => {
                            setItem('suivi')
                          }}>
                          Suivi Opérationnel
                        </a>
                        <a
                          class="nav-link"
                          id="step5-tab"
                          data-bs-toggle="tab"
                          href="#step5"
                          style={
                            item == 'penalite'
                              ? {
                                  color: '#334155',
                                  fontSize: '16px',
                                  fontWeight: '600',
                                  backgroundColor: 'transparent',
                                  borderBottom: 'solid #6366F1 3px',
                                }
                              : {
                                  fontSize: '16px',
                                  fontWeight: '400',
                                  color: '#64748B',
                                  backgroundColor: 'transparent',
                                }
                          }
                          onClick={(e) => {
                            setItem('penalite')
                          }}>
                          Pénalités
                        </a>
                        <a
                          class="nav-link"
                          id="step6-tab"
                          data-bs-toggle="tab"
                          href="#step6"
                          style={
                            item == 'annexe'
                              ? {
                                  color: '#334155',
                                  fontSize: '16px',
                                  fontWeight: '600',
                                  backgroundColor: 'transparent',
                                  borderBottom: 'solid #6366F1 3px',
                                }
                              : {
                                  fontSize: '16px',
                                  fontWeight: '400',
                                  color: '#64748B',
                                  backgroundColor: 'transparent',
                                }
                          }
                          onClick={(e) => {
                            setItem('annexe')
                          }}>
                          Annexes
                        </a>
                      </div>
                    </nav>
                    <div class="tab-content" id="nav-tabContent">
                      {/* Présnece */}
                      <div class="tab-pane active" id="step1">
                        <div style={{ width: '100%' }}>
                          {dataPresence.length > 0 && (
                            <Presence
                              data={dataPresence}
                              updateData={setDataPresence}
                            />
                          )}
                        </div>
                      </div>

                      {/* Administratif et généralités */}
                      <div
                        class="tab-pane "
                        id="step2"
                        style={{
                          marginTop: '22px',
                        }}>
                        <InformationGeneral
                          administratif={administratif}
                          setAdministratif={setAdministratif}
                          generalite={generalite}
                          setGeneralite={setGeneralite}
                          intervenant={dataPresence}
                          isSuiviOp={false}
                        />
                      </div>

                      {/* Suivi Opérationnel */}
                      <div class="tab-pane" id="step3">
                        {/* wizard 2 */}
                        {/* wizard pour compte rendu et fiche de visite */}
                        {item5 == 'entreprise' && (
                          <>
                            <div class="row">
                              <div class="col-12">
                                <div
                                  class="card"
                                  style={{
                                    backgroundColor: 'transparent',
                                    boxShadow: 'none',
                                  }}>
                                  <div
                                    class="card-body"
                                    style={{ padding: '0px' }}>
                                    <form
                                      action=""
                                      method="post"
                                      id="custom-step">
                                      <nav>
                                        <div
                                          style={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: '32px',
                                          }}>
                                          <div
                                            style={{
                                              width: '60%',
                                              display: 'flex',
                                              flexWrap: 'nowrap',
                                            }}>
                                            <button
                                              className="btn"
                                              style={{
                                                width: '10px',
                                                height: '52px',
                                                backgroundColor:
                                                  'rgba(226, 232, 240, 0.6)',
                                                borderRadius: '0px',
                                                borderTopLeftRadius: '8px',
                                                borderBottomLeftRadius: '8px',
                                                borderTop: '1px solid #CBD5E1',
                                                borderBottom:
                                                  '1px solid #CBD5E1',
                                                borderLeft: '1px solid #CBD5E1',
                                              }}
                                              onClick={(e) => {
                                                e.preventDefault()
                                                lefts()
                                              }}>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                style={{
                                                  width: '20px',
                                                  height: '20px',
                                                }}>
                                                <path
                                                  fillRule="evenodd"
                                                  d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                                                  clipRule="evenodd"
                                                />
                                              </svg>
                                            </button>
                                            <div className="wrappers">
                                              {classement &&
                                                classement.map((e) => {
                                                  return (
                                                    <>
                                                      <div
                                                        className="items"
                                                        onClick={(el) => {
                                                          el.preventDefault()
                                                          setItem2(e._id)
                                                          if (
                                                            e.profile.planningSo
                                                          ) {
                                                            setDataPlanningSo(
                                                              e.profile
                                                                .planningSo
                                                            )
                                                          }
                                                          if (
                                                            e.profile
                                                              .engagements
                                                          ) {
                                                            setDataEngagement(
                                                              e.profile
                                                                .engagements
                                                            )
                                                          }
                                                          if (
                                                            e.profile
                                                              .administratifs
                                                          ) {
                                                            setDataAdministratif(
                                                              e.profile
                                                                .administratifs
                                                            )
                                                          }
                                                          if (
                                                            e.profile.documents
                                                          ) {
                                                            setDataDocument(
                                                              e.profile
                                                                .documents
                                                            )
                                                          }
                                                          if (
                                                            e.profile.securites
                                                          ) {
                                                            setDataSecurite(
                                                              e.profile
                                                                .securites
                                                            )
                                                          }
                                                          if (
                                                            e.profile
                                                              .installationChantier
                                                          ) {
                                                            setDataInstallation(
                                                              e.profile
                                                                .installationChantier
                                                            )
                                                          }
                                                          if (
                                                            e.profile.effectifs
                                                          ) {
                                                            setDataEffectif(
                                                              e.profile
                                                                .effectifs
                                                            )
                                                          }
                                                          if (
                                                            e.profile
                                                              .controleurTechnique
                                                          ) {
                                                            setDataCT(
                                                              e.profile
                                                                .controleurTechnique
                                                            )
                                                          }
                                                          if (e.filter) {
                                                            setFiltre(e.filter)
                                                          }
                                                          setBool(!bool)
                                                        }}>
                                                        <span
                                                          style={
                                                            item2 == e._id
                                                              ? {
                                                                  color:
                                                                    '#F8FAFC',
                                                                  fontSize:
                                                                    '16px',
                                                                  fontWeight:
                                                                    '400',
                                                                  backgroundColor:
                                                                    '#6366F1',
                                                                  padding:
                                                                    '8px 16px',
                                                                  borderRadius:
                                                                    '8px',
                                                                }
                                                              : {
                                                                  color:
                                                                    '#334155',
                                                                  fontSize:
                                                                    '16px',
                                                                  fontWeight:
                                                                    '400',
                                                                  cursor:
                                                                    'pointer',
                                                                }
                                                          }>
                                                          {e.titre
                                                            ? e.titre
                                                            : '---'}
                                                        </span>
                                                      </div>
                                                    </>
                                                  )
                                                })}
                                            </div>
                                            <button
                                              className="btn"
                                              style={{
                                                width: '10px',
                                                height: '52px',
                                                backgroundColor:
                                                  'rgba(226, 232, 240, 0.6)',
                                                borderRadius: '0px',
                                                borderTopRightRadius: '8px',
                                                borderBottomRightRadius: '8px',
                                                textAlign: 'center',
                                                position: 'relative',
                                                borderTop: '1px solid #CBD5E1',
                                                borderBottom:
                                                  '1px solid #CBD5E1',
                                                borderRight:
                                                  '1px solid #CBD5E1',
                                              }}
                                              onClick={(e) => {
                                                e.preventDefault()
                                                rights()
                                              }}>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                style={{
                                                  width: '20px',
                                                  height: '20px',
                                                  position: 'absolute',
                                                  left: '0',
                                                  top: '17',
                                                }}>
                                                <path
                                                  fillRule="evenodd"
                                                  d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                                  clipRule="evenodd"
                                                />
                                              </svg>
                                            </button>
                                          </div>
                                          <div
                                            style={{
                                              width: '30%',
                                              marginBottom: '16px',
                                              display: 'flex',
                                              justifyContent: 'right',
                                              alignItems: 'center',
                                            }}>
                                            <button
                                              style={{
                                                border: '1px solid #CBD5E1',
                                                borderRadius: '8px',
                                                padding: '8px',
                                                width: '45px',
                                                height: '45px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                cursor: 'pointer',
                                                backgroundColor: 'transparent',
                                                marginRight: '8px',
                                              }}
                                              id="dropdownMenuButton5"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth={1.5}
                                                stroke="#6366F1"
                                                style={{
                                                  width: '20px',
                                                  height: '20px',
                                                }}>
                                                <path
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
                                                />
                                              </svg>
                                            </button>

                                            <ul
                                              class="dropdown-menu"
                                              aria-labelledby="dropdownMenuButton5"
                                              onClick={(e) => {
                                                e.stopPropagation()
                                              }}>
                                              <li
                                                style={
                                                  item5 == 'entreprise'
                                                    ? {
                                                        backgroundColor:
                                                          'lightblue',
                                                      }
                                                    : {}
                                                }>
                                                <a
                                                  href="#"
                                                  class="dropdown-item">
                                                  Entreprises
                                                </a>
                                              </li>
                                              <li
                                                onClick={(ev) => {
                                                  ev.preventDefault()
                                                  setItem5('intervenant')
                                                }}>
                                                <a
                                                  href="#"
                                                  class="dropdown-item">
                                                  Intervenants
                                                </a>
                                              </li>
                                            </ul>
                                            <span
                                              style={{
                                                display: 'flex',
                                                backgroundColor: '#EBEFF5',
                                                borderRadius: '8px',
                                                padding: '8px 10px',
                                                alignItems: 'center',
                                              }}>
                                              <span>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 24 24"
                                                  fill="#6366F1"
                                                  style={{
                                                    height: '24px',
                                                    width: '24px',
                                                  }}>
                                                  <path
                                                    fillRule="evenodd"
                                                    d="M1.5 5.625c0-1.036.84-1.875 1.875-1.875h17.25c1.035 0 1.875.84 1.875 1.875v12.75c0 1.035-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 011.5 18.375V5.625zM21 9.375A.375.375 0 0020.625 9h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h7.5a.375.375 0 00.375-.375v-1.5zm0 3.75a.375.375 0 00-.375-.375h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h7.5a.375.375 0 00.375-.375v-1.5zm0 3.75a.375.375 0 00-.375-.375h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h7.5a.375.375 0 00.375-.375v-1.5zM10.875 18.75a.375.375 0 00.375-.375v-1.5a.375.375 0 00-.375-.375h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h7.5zM3.375 15h7.5a.375.375 0 00.375-.375v-1.5a.375.375 0 00-.375-.375h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375zm0-3.75h7.5a.375.375 0 00.375-.375v-1.5A.375.375 0 0010.875 9h-7.5A.375.375 0 003 9.375v1.5c0 .207.168.375.375.375z"
                                                    clipRule="evenodd"
                                                  />
                                                </svg>
                                              </span>
                                              <select
                                                className="form-select"
                                                style={{
                                                  backgroundColor:
                                                    'transparent',
                                                }}
                                                onChange={(ev) => {
                                                  ev.preventDefault()
                                                  setItem3(ev.target.value)
                                                }}>
                                                {filtre.includes(
                                                  'planningSo'
                                                ) && (
                                                  <>
                                                    <option
                                                      value="plannification"
                                                      selected>
                                                      {' '}
                                                      Planification
                                                    </option>
                                                  </>
                                                )}
                                                {filtre.includes(
                                                  'engagement'
                                                ) && (
                                                  <>
                                                    <option value="engagement">
                                                      Engagements
                                                    </option>
                                                  </>
                                                )}
                                                {filtre.includes(
                                                  'administratif'
                                                ) && (
                                                  <>
                                                    <option value="administratif">
                                                      Administratifs
                                                    </option>
                                                  </>
                                                )}
                                                {filtre.includes(
                                                  'document'
                                                ) && (
                                                  <>
                                                    <option value="document">
                                                      Documents
                                                    </option>
                                                  </>
                                                )}
                                                {filtre.includes(
                                                  'securite'
                                                ) && (
                                                  <>
                                                    <option value="securite">
                                                      Sécurités
                                                    </option>
                                                  </>
                                                )}
                                                {filtre.includes('ic') && (
                                                  <>
                                                    <option value="installation">
                                                      Installation chantier
                                                    </option>
                                                  </>
                                                )}
                                                {filtre.includes(
                                                  'effectif'
                                                ) && (
                                                  <>
                                                    <option value="effectif">
                                                      Effectifs
                                                    </option>
                                                  </>
                                                )}
                                                {filtre.includes(
                                                  'controleurTechnique'
                                                ) && (
                                                  <>
                                                    <option value="controleTechnique">
                                                      Contrôleur technique
                                                    </option>
                                                  </>
                                                )}
                                              </select>
                                            </span>
                                          </div>
                                        </div>
                                      </nav>
                                      <div>
                                        {classement &&
                                          classement.map((e, i) => {
                                            return (
                                              <>
                                                {item3 == 'plannification' &&
                                                  item2 == e._id && (
                                                    <div
                                                      style={{
                                                        backgroundColor:
                                                          '#FFFFFF',
                                                        borderRadius: '8px',
                                                        boxShadow:
                                                          '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                                                        padding:
                                                          '16px 0px 24px',
                                                      }}>
                                                      <div
                                                        style={{
                                                          width: '100%',
                                                          display: 'flex',
                                                          justifyContent:
                                                            'space-between',
                                                          padding:
                                                            '0px 0px 0px 40px',
                                                        }}>
                                                        <div
                                                          style={{
                                                            display: 'flex',
                                                            alignItems:
                                                              'center',
                                                          }}>
                                                          <span
                                                            style={{
                                                              color: '#334155',
                                                              fontSize: '20px',
                                                              fontWeight: '600',
                                                            }}>
                                                            Planification
                                                          </span>
                                                          <span
                                                            style={{
                                                              backgroundColor:
                                                                '#E0E7FF',
                                                              borderRadius:
                                                                '24px',
                                                              padding:
                                                                '6px 12px',
                                                              color: '#64748B',
                                                              fontSize: '14px',
                                                              fontWeight: '600',
                                                              marginLeft: '8px',
                                                              maxHeight: '32px',
                                                              width: 'auto',
                                                            }}>
                                                            {dataPlanningSo.length >
                                                            0
                                                              ? dataPlanningSo.length
                                                              : '0'}{' '}
                                                            tâches
                                                          </span>
                                                        </div>
                                                        <div>
                                                          <span
                                                            style={{
                                                              padding:
                                                                '8px 12px 8px 12px',
                                                              backgroundColor:
                                                                'rgba(226, 232, 240, 0.6)',
                                                              borderRadius:
                                                                '8px',
                                                              height: '40px',
                                                            }}>
                                                            <span>
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth={
                                                                  1.5
                                                                }
                                                                stroke="#818CF8"
                                                                style={{
                                                                  width: '18px',
                                                                  height:
                                                                    '18px',
                                                                }}>
                                                                <path
                                                                  strokeLinecap="round"
                                                                  strokeLinejoin="round"
                                                                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                                                />
                                                              </svg>
                                                            </span>
                                                            <input
                                                              type="text"
                                                              placeholder="Recherche"
                                                              style={{
                                                                border: 'none',
                                                                backgroundColor:
                                                                  'transparent',
                                                              }}
                                                              onChange={(
                                                                ev
                                                              ) => {
                                                                ev.preventDefault()
                                                                ev.stopPropagation()
                                                                const newData =
                                                                  searchOptimizedData(
                                                                    ev.target
                                                                      .value,
                                                                    dataPlanningSo
                                                                  )
                                                                setDataPlanningSo(
                                                                  newData
                                                                )
                                                              }}
                                                            />
                                                          </span>
                                                          <button
                                                            className="btn"
                                                            style={{
                                                              backgroundColor:
                                                                '#4F46E5',
                                                              color: '#F8FAFC',
                                                              marginLeft:
                                                                '24px',
                                                            }}
                                                            onClick={(ev) => {
                                                              ev.preventDefault()
                                                              addPlanning(
                                                                e.entreprise,
                                                                e._id
                                                              )
                                                            }}>
                                                            + Nouvelle tâche
                                                          </button>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="cibleTabulatorCr"
                                                        style={{
                                                          width: '100%',
                                                          borderTop:
                                                            'solid #E2E8F0 1px',
                                                          marginTop: '16px',
                                                        }}>
                                                        <ReactTabulator
                                                          data={
                                                            dataPlanningSo
                                                              ? dataPlanningSo
                                                              : []
                                                          }
                                                          columns={
                                                            colonnePlanning
                                                          }
                                                          layout={'fitColumns'}
                                                          events={{
                                                            cellEdited:
                                                              planningEdited,
                                                          }}
                                                          options={{
                                                            locale: true,
                                                            pagination: 'local',
                                                            paginationSize: 5,
                                                            langs: {
                                                              fr: {
                                                                pagination: {
                                                                  first:
                                                                    'Premier',
                                                                  first_title:
                                                                    'Première',
                                                                  last: 'Dernier',
                                                                  last_title:
                                                                    'Dernière',
                                                                  prev: 'Précédent',
                                                                  prev_title:
                                                                    'Précédente',
                                                                  next: 'Suivant',
                                                                  next_title:
                                                                    'Page suivante',
                                                                  page_size:
                                                                    'Taille de page',
                                                                  page_title:
                                                                    'Afficher la page',
                                                                  all: 'Tous',
                                                                },
                                                              },
                                                            },
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                {item3 == 'engagement' &&
                                                  item2 == e._id && (
                                                    <div
                                                      style={{
                                                        backgroundColor:
                                                          '#FFFFFF',
                                                        borderRadius: '8px',
                                                        boxShadow:
                                                          '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                                                        padding:
                                                          '16px 0px 24px',
                                                      }}>
                                                      <div
                                                        style={{
                                                          width: '100%',
                                                          display: 'flex',
                                                          justifyContent:
                                                            'space-between',
                                                          padding:
                                                            '0px 0px 0px 40px',
                                                        }}>
                                                        <div
                                                          style={{
                                                            display: 'flex',
                                                            alignItems:
                                                              'center',
                                                          }}>
                                                          <span
                                                            style={{
                                                              color: '#334155',
                                                              fontSize: '20px',
                                                              fontWeight: '600',
                                                            }}>
                                                            Engagements
                                                          </span>
                                                          <span
                                                            style={{
                                                              backgroundColor:
                                                                '#E0E7FF',
                                                              borderRadius:
                                                                '24px',
                                                              padding:
                                                                '6px 12px',
                                                              color: '#64748B',
                                                              fontSize: '14px',
                                                              fontWeight: '600',
                                                              marginLeft: '8px',
                                                              maxHeight: '32px',
                                                              width: 'auto',
                                                            }}>
                                                            {dataEngagement.length >
                                                            0
                                                              ? dataEngagement.length
                                                              : '0'}{' '}
                                                            tâches
                                                          </span>
                                                        </div>
                                                        <div>
                                                          <span
                                                            style={{
                                                              padding:
                                                                '8px 12px 8px 12px',
                                                              backgroundColor:
                                                                'rgba(226, 232, 240, 0.6)',
                                                              borderRadius:
                                                                '8px',
                                                              height: '40px',
                                                            }}>
                                                            <span>
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth={
                                                                  1.5
                                                                }
                                                                stroke="#818CF8"
                                                                style={{
                                                                  width: '18px',
                                                                  height:
                                                                    '18px',
                                                                }}>
                                                                <path
                                                                  strokeLinecap="round"
                                                                  strokeLinejoin="round"
                                                                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                                                />
                                                              </svg>
                                                            </span>
                                                            <input
                                                              type="text"
                                                              placeholder="Recherche"
                                                              style={{
                                                                border: 'none',
                                                                backgroundColor:
                                                                  'transparent',
                                                              }}
                                                              onChange={(
                                                                ev
                                                              ) => {
                                                                ev.preventDefault()
                                                                ev.stopPropagation()
                                                                const newData =
                                                                  searchOptimizedData(
                                                                    ev.target
                                                                      .value,
                                                                    dataEngagement
                                                                  )
                                                                setDataEngagement(
                                                                  newData
                                                                )
                                                              }}
                                                            />
                                                          </span>
                                                          <button
                                                            className="btn"
                                                            style={{
                                                              backgroundColor:
                                                                '#4F46E5',
                                                              color: '#F8FAFC',
                                                              marginLeft:
                                                                '24px',
                                                            }}
                                                            onClick={(ev) => {
                                                              ev.preventDefault()
                                                              addEngagementRow(
                                                                e.entreprise,
                                                                e._id
                                                              )
                                                            }}>
                                                            + Nouvelle tâche
                                                          </button>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="cibleTabulatorCr"
                                                        style={{
                                                          width: '100%',
                                                          borderTop:
                                                            'solid #E2E8F0 1px',
                                                          marginTop: '16px',
                                                        }}>
                                                        <ReactTabulator
                                                          data={
                                                            dataEngagement
                                                              ? dataEngagement
                                                              : []
                                                          }
                                                          columns={
                                                            colonneEngagement
                                                          }
                                                          layout={'fitColumns'}
                                                          events={{
                                                            cellEdited:
                                                              engagementEdited,
                                                          }}
                                                          options={{
                                                            locale: true,
                                                            pagination: 'local',
                                                            paginationSize: 5,
                                                            langs: {
                                                              fr: {
                                                                pagination: {
                                                                  first:
                                                                    'Premier',
                                                                  first_title:
                                                                    'Première',
                                                                  last: 'Dernier',
                                                                  last_title:
                                                                    'Dernière',
                                                                  prev: 'Précédent',
                                                                  prev_title:
                                                                    'Précédente',
                                                                  next: 'Suivant',
                                                                  next_title:
                                                                    'Page suivante',
                                                                  page_size:
                                                                    'Taille de page',
                                                                  page_title:
                                                                    'Afficher la page',
                                                                  all: 'Tous',
                                                                },
                                                              },
                                                            },
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                {item3 == 'administratif' &&
                                                  item2 == e._id && (
                                                    <div
                                                      style={{
                                                        backgroundColor:
                                                          '#FFFFFF',
                                                        borderRadius: '8px',
                                                        boxShadow:
                                                          '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                                                        padding:
                                                          '16px 0px 24px',
                                                      }}>
                                                      <div
                                                        style={{
                                                          width: '100%',
                                                          display: 'flex',
                                                          justifyContent:
                                                            'space-between',
                                                          padding:
                                                            '0px 0px 0px 40px',
                                                        }}>
                                                        <div
                                                          style={{
                                                            display: 'flex',
                                                            alignItems:
                                                              'center',
                                                          }}>
                                                          <span
                                                            style={{
                                                              color: '#334155',
                                                              fontSize: '20px',
                                                              fontWeight: '600',
                                                            }}>
                                                            Administratifs
                                                          </span>
                                                          <span
                                                            style={{
                                                              backgroundColor:
                                                                '#E0E7FF',
                                                              borderRadius:
                                                                '24px',
                                                              padding:
                                                                '6px 12px',
                                                              color: '#64748B',
                                                              fontSize: '14px',
                                                              fontWeight: '600',
                                                              marginLeft: '8px',
                                                              maxHeight: '32px',
                                                              width: 'auto',
                                                            }}>
                                                            {dataAdministratif.length >
                                                            0
                                                              ? dataAdministratif.length
                                                              : '0'}{' '}
                                                            tâches
                                                          </span>
                                                        </div>
                                                        <div>
                                                          <span
                                                            style={{
                                                              padding:
                                                                '8px 12px 8px 12px',
                                                              backgroundColor:
                                                                'rgba(226, 232, 240, 0.6)',
                                                              borderRadius:
                                                                '8px',
                                                              height: '40px',
                                                            }}>
                                                            <span>
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth={
                                                                  1.5
                                                                }
                                                                stroke="#818CF8"
                                                                style={{
                                                                  width: '18px',
                                                                  height:
                                                                    '18px',
                                                                }}>
                                                                <path
                                                                  strokeLinecap="round"
                                                                  strokeLinejoin="round"
                                                                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                                                />
                                                              </svg>
                                                            </span>
                                                            <input
                                                              type="text"
                                                              placeholder="Recherche"
                                                              style={{
                                                                border: 'none',
                                                                backgroundColor:
                                                                  'transparent',
                                                              }}
                                                              onChange={(
                                                                ev
                                                              ) => {
                                                                ev.preventDefault()
                                                                ev.stopPropagation()
                                                                const newData =
                                                                  searchOptimizedData(
                                                                    ev.target
                                                                      .value,
                                                                    dataAdministratif
                                                                  )
                                                                setDataAdministratif(
                                                                  newData
                                                                )
                                                              }}
                                                            />
                                                          </span>
                                                          <button
                                                            className="btn"
                                                            style={{
                                                              backgroundColor:
                                                                '#4F46E5',
                                                              color: '#F8FAFC',
                                                              marginLeft:
                                                                '24px',
                                                            }}
                                                            onClick={(ev) => {
                                                              ev.preventDefault()
                                                              addAdministratifRow(
                                                                e.entreprise,
                                                                e._id
                                                              )
                                                            }}>
                                                            + Nouvelle tâche
                                                          </button>
                                                        </div>
                                                      </div>
                                                      <div
                                                        style={{
                                                          width: '100%',
                                                          borderTop:
                                                            'solid #E2E8F0 1px',
                                                          marginTop: '16px',
                                                        }}>
                                                        <ReactTabulator
                                                          data={
                                                            dataAdministratif
                                                              ? dataAdministratif
                                                              : []
                                                          }
                                                          columns={
                                                            colonneAdministratif
                                                          }
                                                          layout={'fitColumns'}
                                                          events={{
                                                            cellEdited:
                                                              administratifEdited,
                                                          }}
                                                          options={{
                                                            locale: true,
                                                            pagination: 'local',
                                                            paginationSize: 5,
                                                            langs: {
                                                              fr: {
                                                                pagination: {
                                                                  first:
                                                                    'Premier',
                                                                  first_title:
                                                                    'Première',
                                                                  last: 'Dernier',
                                                                  last_title:
                                                                    'Dernière',
                                                                  prev: 'Précédent',
                                                                  prev_title:
                                                                    'Précédente',
                                                                  next: 'Suivant',
                                                                  next_title:
                                                                    'Page suivante',
                                                                  page_size:
                                                                    'Taille de page',
                                                                  page_title:
                                                                    'Afficher la page',
                                                                  all: 'Tous',
                                                                },
                                                              },
                                                            },
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                {item3 == 'document' &&
                                                  item2 == e._id && (
                                                    <div
                                                      style={{
                                                        backgroundColor:
                                                          '#FFFFFF',
                                                        borderRadius: '8px',
                                                        boxShadow:
                                                          '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                                                        padding:
                                                          '16px 0px 24px',
                                                      }}>
                                                      <div
                                                        style={{
                                                          width: '100%',
                                                          display: 'flex',
                                                          justifyContent:
                                                            'space-between',
                                                          padding:
                                                            '0px 0px 0px 40px',
                                                        }}>
                                                        <div
                                                          style={{
                                                            display: 'flex',
                                                            alignItems:
                                                              'center',
                                                          }}>
                                                          <span
                                                            style={{
                                                              color: '#334155',
                                                              fontSize: '20px',
                                                              fontWeight: '600',
                                                            }}>
                                                            Documents
                                                          </span>
                                                          <span
                                                            style={{
                                                              backgroundColor:
                                                                '#E0E7FF',
                                                              borderRadius:
                                                                '24px',
                                                              padding:
                                                                '6px 12px',
                                                              color: '#64748B',
                                                              fontSize: '14px',
                                                              fontWeight: '600',
                                                              marginLeft: '8px',
                                                              maxHeight: '32px',
                                                              width: 'auto',
                                                            }}>
                                                            {dataDocument.length >
                                                            0
                                                              ? dataDocument.length
                                                              : '0'}{' '}
                                                            tâches
                                                          </span>
                                                        </div>
                                                        <div>
                                                          <span
                                                            style={{
                                                              padding:
                                                                '8px 12px 8px 12px',
                                                              backgroundColor:
                                                                'rgba(226, 232, 240, 0.6)',
                                                              borderRadius:
                                                                '8px',
                                                              height: '40px',
                                                            }}>
                                                            <span>
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth={
                                                                  1.5
                                                                }
                                                                stroke="#818CF8"
                                                                style={{
                                                                  width: '18px',
                                                                  height:
                                                                    '18px',
                                                                }}>
                                                                <path
                                                                  strokeLinecap="round"
                                                                  strokeLinejoin="round"
                                                                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                                                />
                                                              </svg>
                                                            </span>
                                                            <input
                                                              type="text"
                                                              placeholder="Recherche"
                                                              style={{
                                                                border: 'none',
                                                                backgroundColor:
                                                                  'transparent',
                                                              }}
                                                              onChange={(
                                                                ev
                                                              ) => {
                                                                ev.preventDefault()
                                                                ev.stopPropagation()
                                                                const newData =
                                                                  searchOptimizedData(
                                                                    ev.target
                                                                      .value,
                                                                    dataDocument
                                                                  )
                                                                setDataDocument(
                                                                  newData
                                                                )
                                                              }}
                                                            />
                                                          </span>
                                                          <button
                                                            className="btn"
                                                            style={{
                                                              backgroundColor:
                                                                '#4F46E5',
                                                              color: '#F8FAFC',
                                                              marginLeft:
                                                                '24px',
                                                            }}
                                                            onClick={(ev) => {
                                                              ev.preventDefault()
                                                              addDocumentRow(
                                                                e.entreprise,
                                                                e._id
                                                              )
                                                            }}>
                                                            + Nouvelle tâche
                                                          </button>
                                                        </div>
                                                      </div>
                                                      <div
                                                        style={{
                                                          width: '100%',
                                                          borderTop:
                                                            'solid #E2E8F0 1px',
                                                          marginTop: '16px',
                                                        }}>
                                                        <ReactTabulator
                                                          data={
                                                            dataDocument
                                                              ? dataDocument
                                                              : []
                                                          }
                                                          columns={
                                                            colonneDocument
                                                          }
                                                          layout={'fitColumns'}
                                                          events={{
                                                            cellEdited:
                                                              documentEdited,
                                                          }}
                                                          options={{
                                                            locale: true,
                                                            pagination: 'local',
                                                            paginationSize: 5,
                                                            langs: {
                                                              fr: {
                                                                pagination: {
                                                                  first:
                                                                    'Premier',
                                                                  first_title:
                                                                    'Première',
                                                                  last: 'Dernier',
                                                                  last_title:
                                                                    'Dernière',
                                                                  prev: 'Précédent',
                                                                  prev_title:
                                                                    'Précédente',
                                                                  next: 'Suivant',
                                                                  next_title:
                                                                    'Page suivante',
                                                                  page_size:
                                                                    'Taille de page',
                                                                  page_title:
                                                                    'Afficher la page',
                                                                  all: 'Tous',
                                                                },
                                                              },
                                                            },
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                {item3 == 'securite' &&
                                                  item2 == e._id && (
                                                    <div
                                                      style={{
                                                        backgroundColor:
                                                          '#FFFFFF',
                                                        borderRadius: '8px',
                                                        boxShadow:
                                                          '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                                                        padding:
                                                          '16px 0px 24px',
                                                      }}>
                                                      <div
                                                        style={{
                                                          width: '100%',
                                                          display: 'flex',
                                                          justifyContent:
                                                            'space-between',
                                                          padding:
                                                            '0px 0px 0px 40px',
                                                        }}>
                                                        <div
                                                          style={{
                                                            display: 'flex',
                                                            alignItems:
                                                              'center',
                                                          }}>
                                                          <span
                                                            style={{
                                                              color: '#334155',
                                                              fontSize: '20px',
                                                              fontWeight: '600',
                                                            }}>
                                                            Sécurités
                                                          </span>
                                                          <span
                                                            style={{
                                                              backgroundColor:
                                                                '#E0E7FF',
                                                              borderRadius:
                                                                '24px',
                                                              padding:
                                                                '6px 12px',
                                                              color: '#64748B',
                                                              fontSize: '14px',
                                                              fontWeight: '600',
                                                              marginLeft: '8px',
                                                              maxHeight: '32px',
                                                              width: 'auto',
                                                            }}>
                                                            {dataSecurite.length >
                                                            0
                                                              ? dataSecurite.length
                                                              : '0'}{' '}
                                                            tâches
                                                          </span>
                                                        </div>
                                                        <div>
                                                          <span
                                                            style={{
                                                              padding:
                                                                '8px 12px 8px 12px',
                                                              backgroundColor:
                                                                'rgba(226, 232, 240, 0.6)',
                                                              borderRadius:
                                                                '8px',
                                                              height: '40px',
                                                            }}>
                                                            <span>
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth={
                                                                  1.5
                                                                }
                                                                stroke="#818CF8"
                                                                style={{
                                                                  width: '18px',
                                                                  height:
                                                                    '18px',
                                                                }}>
                                                                <path
                                                                  strokeLinecap="round"
                                                                  strokeLinejoin="round"
                                                                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                                                />
                                                              </svg>
                                                            </span>
                                                            <input
                                                              type="text"
                                                              placeholder="Recherche"
                                                              style={{
                                                                border: 'none',
                                                                backgroundColor:
                                                                  'transparent',
                                                              }}
                                                              onChange={(
                                                                ev
                                                              ) => {
                                                                ev.preventDefault()
                                                                ev.stopPropagation()
                                                                const newData =
                                                                  searchOptimizedData(
                                                                    ev.target
                                                                      .value,
                                                                    dataSecurite
                                                                  )
                                                                setDataSecurite(
                                                                  newData
                                                                )
                                                              }}
                                                            />
                                                          </span>
                                                          <button
                                                            className="btn"
                                                            style={{
                                                              backgroundColor:
                                                                '#4F46E5',
                                                              color: '#F8FAFC',
                                                              marginLeft:
                                                                '24px',
                                                            }}
                                                            onClick={(ev) => {
                                                              ev.preventDefault()
                                                              addSecuriteRow(
                                                                e.entreprise,
                                                                e._id
                                                              )
                                                            }}>
                                                            + Nouvelle tâche
                                                          </button>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="cibleTabulatorCr"
                                                        style={{
                                                          width: '100%',
                                                          borderTop:
                                                            'solid #E2E8F0 1px',
                                                          marginTop: '16px',
                                                        }}>
                                                        <ReactTabulator
                                                          data={
                                                            dataSecurite
                                                              ? dataSecurite
                                                              : []
                                                          }
                                                          columns={
                                                            colonneSecurite
                                                          }
                                                          layout={'fitColumns'}
                                                          events={{
                                                            cellEdited:
                                                              securiteEdited,
                                                          }}
                                                          options={{
                                                            locale: true,
                                                            pagination: 'local',
                                                            paginationSize: 5,
                                                            langs: {
                                                              fr: {
                                                                pagination: {
                                                                  first:
                                                                    'Premier',
                                                                  first_title:
                                                                    'Première',
                                                                  last: 'Dernier',
                                                                  last_title:
                                                                    'Dernière',
                                                                  prev: 'Précédent',
                                                                  prev_title:
                                                                    'Précédente',
                                                                  next: 'Suivant',
                                                                  next_title:
                                                                    'Page suivante',
                                                                  page_size:
                                                                    'Taille de page',
                                                                  page_title:
                                                                    'Afficher la page',
                                                                  all: 'Tous',
                                                                },
                                                              },
                                                            },
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                {item3 == 'controleTechnique' &&
                                                  item2 == e._id && (
                                                    <div
                                                      style={{
                                                        backgroundColor:
                                                          '#FFFFFF',
                                                        borderRadius: '8px',
                                                        boxShadow:
                                                          '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                                                        padding:
                                                          '16px 0px 24px',
                                                      }}>
                                                      <div
                                                        style={{
                                                          width: '100%',
                                                          display: 'flex',
                                                          justifyContent:
                                                            'space-between',
                                                          padding:
                                                            '0px 0px 0px 40px',
                                                        }}>
                                                        <div
                                                          style={{
                                                            display: 'flex',
                                                            alignItems:
                                                              'center',
                                                          }}>
                                                          <span
                                                            style={{
                                                              color: '#334155',
                                                              fontSize: '20px',
                                                              fontWeight: '600',
                                                            }}>
                                                            Contrôleur technique
                                                          </span>
                                                          <span
                                                            style={{
                                                              backgroundColor:
                                                                '#E0E7FF',
                                                              borderRadius:
                                                                '24px',
                                                              padding:
                                                                '6px 12px',
                                                              color: '#64748B',
                                                              fontSize: '14px',
                                                              fontWeight: '600',
                                                              marginLeft: '8px',
                                                              maxHeight: '32px',
                                                              width: 'auto',
                                                            }}>
                                                            {dataCT.length > 0
                                                              ? dataCT.length
                                                              : '0'}{' '}
                                                            tâches
                                                          </span>
                                                        </div>
                                                        <div>
                                                          <span
                                                            style={{
                                                              padding:
                                                                '8px 12px 8px 12px',
                                                              backgroundColor:
                                                                'rgba(226, 232, 240, 0.6)',
                                                              borderRadius:
                                                                '8px',
                                                              height: '40px',
                                                            }}>
                                                            <span>
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth={
                                                                  1.5
                                                                }
                                                                stroke="#818CF8"
                                                                style={{
                                                                  width: '18px',
                                                                  height:
                                                                    '18px',
                                                                }}>
                                                                <path
                                                                  strokeLinecap="round"
                                                                  strokeLinejoin="round"
                                                                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                                                />
                                                              </svg>
                                                            </span>
                                                            <input
                                                              type="text"
                                                              placeholder="Recherche"
                                                              style={{
                                                                border: 'none',
                                                                backgroundColor:
                                                                  'transparent',
                                                              }}
                                                              onChange={(
                                                                ev
                                                              ) => {
                                                                ev.preventDefault()
                                                                ev.stopPropagation()
                                                                const newData =
                                                                  searchOptimizedData(
                                                                    ev.target
                                                                      .value,
                                                                    dataCT
                                                                  )
                                                                setDataCT(
                                                                  newData
                                                                )
                                                              }}
                                                            />
                                                          </span>
                                                          <button
                                                            className="btn"
                                                            style={{
                                                              backgroundColor:
                                                                '#4F46E5',
                                                              color: '#F8FAFC',
                                                              marginLeft:
                                                                '24px',
                                                            }}
                                                            onClick={(ev) => {
                                                              ev.preventDefault()
                                                              addCTRow(
                                                                e.entreprise,
                                                                e._id
                                                              )
                                                            }}>
                                                            + Nouvelle tâche
                                                          </button>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="cibleTabulatorCr"
                                                        style={{
                                                          width: '100%',
                                                          borderTop:
                                                            'solid #E2E8F0 1px',
                                                          marginTop: '16px',
                                                        }}>
                                                        <ReactTabulator
                                                          data={
                                                            dataCT ? dataCT : []
                                                          }
                                                          columns={colonneCT}
                                                          layout={'fitColumns'}
                                                          events={{
                                                            cellEdited:
                                                              ctEdited,
                                                          }}
                                                          options={{
                                                            locale: true,
                                                            pagination: 'local',
                                                            paginationSize: 5,
                                                            langs: {
                                                              fr: {
                                                                pagination: {
                                                                  first:
                                                                    'Premier',
                                                                  first_title:
                                                                    'Première',
                                                                  last: 'Dernier',
                                                                  last_title:
                                                                    'Dernière',
                                                                  prev: 'Précédent',
                                                                  prev_title:
                                                                    'Précédente',
                                                                  next: 'Suivant',
                                                                  next_title:
                                                                    'Page suivante',
                                                                  page_size:
                                                                    'Taille de page',
                                                                  page_title:
                                                                    'Afficher la page',
                                                                  all: 'Tous',
                                                                },
                                                              },
                                                            },
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                {item3 == 'installation' &&
                                                  item2 == e._id && (
                                                    <div
                                                      style={{
                                                        backgroundColor:
                                                          '#FFFFFF',
                                                        borderRadius: '8px',
                                                        boxShadow:
                                                          '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                                                        padding:
                                                          '16px 0px 24px',
                                                      }}>
                                                      <div
                                                        style={{
                                                          width: '100%',
                                                          display: 'flex',
                                                          justifyContent:
                                                            'space-between',
                                                          padding:
                                                            '0px 0px 0px 40px',
                                                        }}>
                                                        <div
                                                          style={{
                                                            display: 'flex',
                                                            alignItems:
                                                              'center',
                                                          }}>
                                                          <span
                                                            style={{
                                                              color: '#334155',
                                                              fontSize: '20px',
                                                              fontWeight: '600',
                                                            }}>
                                                            Installation de
                                                            chantier
                                                          </span>
                                                          <span
                                                            style={{
                                                              backgroundColor:
                                                                '#E0E7FF',
                                                              borderRadius:
                                                                '24px',
                                                              padding:
                                                                '6px 12px',
                                                              color: '#64748B',
                                                              fontSize: '14px',
                                                              fontWeight: '600',
                                                              marginLeft: '8px',
                                                              maxHeight: '32px',
                                                              width: 'auto',
                                                            }}>
                                                            {dataInstallation.length >
                                                            0
                                                              ? dataInstallation.length
                                                              : '0'}{' '}
                                                            tâches
                                                          </span>
                                                        </div>
                                                        <div>
                                                          <span
                                                            style={{
                                                              padding:
                                                                '8px 12px 8px 12px',
                                                              backgroundColor:
                                                                'rgba(226, 232, 240, 0.6)',
                                                              borderRadius:
                                                                '8px',
                                                              height: '40px',
                                                            }}>
                                                            <span>
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth={
                                                                  1.5
                                                                }
                                                                stroke="#818CF8"
                                                                style={{
                                                                  width: '18px',
                                                                  height:
                                                                    '18px',
                                                                }}>
                                                                <path
                                                                  strokeLinecap="round"
                                                                  strokeLinejoin="round"
                                                                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                                                />
                                                              </svg>
                                                            </span>
                                                            <input
                                                              type="text"
                                                              placeholder="Recherche"
                                                              style={{
                                                                border: 'none',
                                                                backgroundColor:
                                                                  'transparent',
                                                              }}
                                                              onChange={(
                                                                ev
                                                              ) => {
                                                                ev.preventDefault()
                                                                ev.stopPropagation()
                                                                const newData =
                                                                  searchOptimizedData(
                                                                    ev.target
                                                                      .value,
                                                                    dataInstallation
                                                                  )
                                                                setDataInstallation(
                                                                  newData
                                                                )
                                                              }}
                                                            />
                                                          </span>
                                                          <button
                                                            className="btn"
                                                            style={{
                                                              backgroundColor:
                                                                '#4F46E5',
                                                              color: '#F8FAFC',
                                                              marginLeft:
                                                                '24px',
                                                            }}
                                                            onClick={(ev) => {
                                                              ev.preventDefault()
                                                              addInstallationRow(
                                                                e.entreprise,
                                                                e._id
                                                              )
                                                            }}>
                                                            + Nouvelle tâche
                                                          </button>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="cibleTabulatorCr"
                                                        style={{
                                                          width: '100%',
                                                          borderTop:
                                                            'solid #E2E8F0 1px',
                                                          marginTop: '16px',
                                                        }}>
                                                        <ReactTabulator
                                                          data={
                                                            dataInstallation
                                                              ? dataInstallation
                                                              : []
                                                          }
                                                          columns={
                                                            colonneInstallation
                                                          }
                                                          layout={'fitColumns'}
                                                          events={{
                                                            cellEdited:
                                                              installationEdited,
                                                          }}
                                                          options={{
                                                            locale: true,
                                                            pagination: 'local',
                                                            paginationSize: 5,
                                                            langs: {
                                                              fr: {
                                                                pagination: {
                                                                  first:
                                                                    'Premier',
                                                                  first_title:
                                                                    'Première',
                                                                  last: 'Dernier',
                                                                  last_title:
                                                                    'Dernière',
                                                                  prev: 'Précédent',
                                                                  prev_title:
                                                                    'Précédente',
                                                                  next: 'Suivant',
                                                                  next_title:
                                                                    'Page suivante',
                                                                  page_size:
                                                                    'Taille de page',
                                                                  page_title:
                                                                    'Afficher la page',
                                                                  all: 'Tous',
                                                                },
                                                              },
                                                            },
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                {item3 == 'effectif' &&
                                                  item2 == e._id && (
                                                    <div
                                                      style={{
                                                        backgroundColor:
                                                          '#FFFFFF',
                                                        borderRadius: '8px',
                                                        boxShadow:
                                                          '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                                                        padding:
                                                          '16px 0px 24px',
                                                      }}>
                                                      <div
                                                        style={{
                                                          width: '100%',
                                                          display: 'flex',
                                                          justifyContent:
                                                            'space-between',
                                                          padding:
                                                            '0px 0px 0px 40px',
                                                        }}>
                                                        <div
                                                          style={{
                                                            display: 'flex',
                                                            alignItems:
                                                              'center',
                                                          }}>
                                                          <span
                                                            style={{
                                                              color: '#334155',
                                                              fontSize: '20px',
                                                              fontWeight: '600',
                                                            }}>
                                                            Effectifs
                                                          </span>
                                                          <span
                                                            style={{
                                                              backgroundColor:
                                                                '#E0E7FF',
                                                              borderRadius:
                                                                '24px',
                                                              padding:
                                                                '6px 12px',
                                                              color: '#64748B',
                                                              fontSize: '14px',
                                                              fontWeight: '600',
                                                              marginLeft: '8px',
                                                              maxHeight: '32px',
                                                              width: 'auto',
                                                            }}>
                                                            {dataEffectif.length >
                                                            0
                                                              ? dataEffectif.length
                                                              : '0'}{' '}
                                                            tâches
                                                          </span>
                                                        </div>
                                                        <div>
                                                          <span
                                                            style={{
                                                              padding:
                                                                '8px 12px 8px 12px',
                                                              backgroundColor:
                                                                'rgba(226, 232, 240, 0.6)',
                                                              borderRadius:
                                                                '8px',
                                                              height: '40px',
                                                            }}>
                                                            <span>
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth={
                                                                  1.5
                                                                }
                                                                stroke="#818CF8"
                                                                style={{
                                                                  width: '18px',
                                                                  height:
                                                                    '18px',
                                                                }}>
                                                                <path
                                                                  strokeLinecap="round"
                                                                  strokeLinejoin="round"
                                                                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                                                />
                                                              </svg>
                                                            </span>
                                                            <input
                                                              type="text"
                                                              placeholder="Recherche"
                                                              style={{
                                                                border: 'none',
                                                                backgroundColor:
                                                                  'transparent',
                                                              }}
                                                              onChange={(
                                                                ev
                                                              ) => {
                                                                ev.preventDefault()
                                                                ev.stopPropagation()
                                                                const newData =
                                                                  searchOptimizedData(
                                                                    ev.target
                                                                      .value,
                                                                    dataEffectif
                                                                  )
                                                                setDataEffectif(
                                                                  newData
                                                                )
                                                              }}
                                                            />
                                                          </span>
                                                          <button
                                                            className="btn"
                                                            style={{
                                                              backgroundColor:
                                                                '#4F46E5',
                                                              color: '#F8FAFC',
                                                              marginLeft:
                                                                '24px',
                                                            }}
                                                            onClick={(ev) => {
                                                              ev.preventDefault()
                                                              addEffectifRow(
                                                                e.entreprise,
                                                                e._id
                                                              )
                                                            }}>
                                                            + Nouvelle tâche
                                                          </button>
                                                        </div>
                                                      </div>
                                                      <div
                                                        style={{
                                                          width: '100%',
                                                          borderTop:
                                                            'solid #E2E8F0 1px',
                                                          marginTop: '16px',
                                                        }}>
                                                        <ReactTabulator
                                                          data={
                                                            dataEffectif
                                                              ? dataEffectif
                                                              : []
                                                          }
                                                          columns={
                                                            colonneEffectif
                                                          }
                                                          layout={'fitColumns'}
                                                          events={{
                                                            cellEdited:
                                                              effectifEdited,
                                                          }}
                                                          options={{
                                                            locale: true,
                                                            pagination: 'local',
                                                            paginationSize: 5,
                                                            langs: {
                                                              fr: {
                                                                pagination: {
                                                                  first:
                                                                    'Premier',
                                                                  first_title:
                                                                    'Première',
                                                                  last: 'Dernier',
                                                                  last_title:
                                                                    'Dernière',
                                                                  prev: 'Précédent',
                                                                  prev_title:
                                                                    'Précédente',
                                                                  next: 'Suivant',
                                                                  next_title:
                                                                    'Page suivante',
                                                                  page_size:
                                                                    'Taille de page',
                                                                  page_title:
                                                                    'Afficher la page',
                                                                  all: 'Tous',
                                                                },
                                                              },
                                                            },
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                              </>
                                            )
                                          })}
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        {item5 == 'intervenant' && (
                          <>
                            <TableIntervenants
                              classement={allCarte}
                              item5={item5}
                              setItem5={setItem5}
                              itemInt={itemInt}
                              setItemInt={setItemInt}
                            />
                          </>
                        )}
                        {/* fin wizard */}
                        {/* fin wizard 2 */}
                      </div>

                      {/* Suivi Planning */}
                      <div
                        class="tab-pane "
                        id="step4"
                        style={{
                          backgroundColor: 'transparent',
                        }}>
                        {/* wizard pour compte rendu et fiche de visite */}
                        <div class="row">
                          <div class="col-12" style={{ padding: '0px' }}>
                            <div
                              class="card"
                              style={{
                                backgroundColor: 'transparent',
                                boxShadow: 'none',
                              }}>
                              <div class="card-body" style={{ padding: '0px' }}>
                                <form id="custom-step">
                                  <nav>
                                    <div
                                      class="wrapper"
                                      style={{ marginTop: '20px' }}>
                                      <div
                                        className="item"
                                        onClick={(e) => {
                                          e.preventDefault()
                                          setItem4('jalon')
                                        }}
                                        style={{
                                          borderLeft: '1px solid #CBD5E1',
                                          borderTopLeftRadius: '8px',
                                          borderBottomLeftRadius: '8px',
                                        }}>
                                        <span
                                          style={
                                            item4 == 'jalon'
                                              ? {
                                                  color: '#F8FAFC',
                                                  fontSize: '16px',
                                                  fontWeight: '400',
                                                  backgroundColor: '#6366F1',
                                                  padding: '8px 24px',
                                                  borderRadius: '8px',
                                                }
                                              : {
                                                  color: '#334155',
                                                  fontSize: '16px',
                                                  fontWeight: '400',
                                                  cursor: 'pointer',
                                                }
                                          }>
                                          Jalons critiques
                                        </span>
                                      </div>
                                      <div
                                        className="item"
                                        onClick={(e) => {
                                          e.preventDefault()
                                          setItem4('glissant')
                                          setConfirationLtp(true)
                                        }}
                                        style={{
                                          borderRight: '1px solid #CBD5E1',
                                          borderTopRightRadius: '8px',
                                          borderBottomRightRadius: '8px',
                                        }}>
                                        <span
                                          style={
                                            item4 == 'glissant'
                                              ? {
                                                  color: '#F8FAFC',
                                                  fontSize: '16px',
                                                  fontWeight: '400',
                                                  backgroundColor: '#6366F1',
                                                  padding: '8px 24px',
                                                  borderRadius: '8px',
                                                  marginRight: '15px',
                                                }
                                              : {
                                                  color: '#334155',
                                                  fontSize: '16px',
                                                  fontWeight: '400',
                                                  cursor: 'pointer',
                                                }
                                          }>
                                          Planning à 3 semaines
                                        </span>
                                      </div>
                                    </div>
                                  </nav>
                                  <div class="tab-content" id="nav-tabContent">
                                    {item4 == 'jalon' && (
                                      <>
                                        <div style={{ width: '100%' }}>
                                          <Favori2
                                            allTask={allTask}
                                            allLot={entreprise}
                                            bool={bool1}
                                            setBool={setBool1}
                                          />
                                        </div>
                                      </>
                                    )}
                                    {item4 == 'glissant' && (
                                      <>
                                        <div
                                          style={{
                                            width: '100%',
                                            marginTop: '22px',
                                          }}>
                                          {btnPartage && (
                                            <LastPlanner2
                                              btnPartage={true}
                                              updateBtnPartage={setBtnPartage}
                                              width={'800px'}
                                              btnPartageValue={btnPartage}
                                              rfltp={rfLtp}
                                              setRfLtp={setRfLtp}
                                            />
                                          )}
                                          {!btnPartage && (
                                            <LastPlanner2
                                              btnPartage={true}
                                              updateBtnPartage={setBtnPartage}
                                              width={'100%'}
                                              btnPartageValue={btnPartage}
                                              rfltp={rfLtp}
                                              setRfLtp={setRfLtp}
                                            />
                                          )}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* fin wizard */}
                      </div>

                      {/* Penalite */}
                      <div class="tab-pane" id="step5">
                        {/* wizard pour compte rendu et fiche de visite */}
                        <div class="row">
                          <div class="col-12">
                            <Penalite
                              entreprise={lots}
                              forfait={forfait}
                              setForfait={setForfait}
                              bool={boolPenalite}
                              setBool={setBoolPenalite}
                              itemPenalite={lots[0]?._id ? lots[0]._id : ''}
                            />
                          </div>
                        </div>
                        {/* fin wizard */}
                      </div>

                      {/* Annexe */}
                      <div
                        class="tab-pane"
                        id="step6"
                        style={{
                          padding: '16px 26px',
                          backgroundColor: '#FFFFFF',
                          border: '1px solid #E2E8F0',
                          borderRadius: '8px',
                          boxShadow:
                            '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                          marginTop: '12px',
                        }}>
                        <div className="d-flex flex-row-reverse">
                          <div className="mt-2">
                            <button
                              className="btn"
                              style={{
                                color: '#334155',
                                backgroundColor: '#E2E8F0',
                                marginRight: '5px',
                              }}
                              onClick={(ev) => {
                                ev.preventDefault()
                                setDisabled(true)
                                setWait(true)
                                handleSubmit('brouillon')
                              }}
                              disabled={disabled}>
                              Enregistrer en tant que brouillon
                            </button>
                            <button
                              className="btn"
                              onClick={(e) => {
                                e.preventDefault()
                                setDisabled(true)
                                setWait(true)
                                handleSubmit('valide')
                              }}
                              disabled={disabled}
                              style={{
                                color: 'white',
                                backgroundColor: '#4F46E5',
                              }}>
                              Valider
                            </button>
                          </div>
                        </div>
                        <div className="container-fluid mt-2">
                          <Upload.Dragger
                            multiple={true}
                            maxCount={5}
                            beforeUpload={(files) => {
                              setFile(files)
                              return false
                            }}
                            showUploadList={{ showRemoveIcon: true }}
                            accept=".pdf"
                            listType="picture"
                            onChange={(e) => {
                              setFile(e.fileList)
                            }}>
                            Importer des annexes ou <br />
                            <Button>Parcourir Fichier</Button>
                          </Upload.Dragger>
                        </div>
                        <table class="table" style={{ marginTop: '1vw' }}>
                          <thead
                            style={{
                              borderBottom: '1px solid #CBD5E1',
                            }}>
                            <tr>
                              <th
                                style={{
                                  color: '#334155',
                                  fontSize: '16px',
                                  fontWeight: '600',
                                }}>
                                N° du Compte Rendu
                              </th>
                              <th
                                style={{
                                  color: '#334155',
                                  fontSize: '16px',
                                  fontWeight: '600',
                                }}>
                                Nom de la pièce
                              </th>
                              <th
                                style={{
                                  color: '#334155',
                                  fontSize: '16px',
                                  fontWeight: '600',
                                }}>
                                Fichier
                              </th>
                              <th
                                style={{
                                  color: '#334155',
                                  fontSize: '16px',
                                  fontWeight: '600',
                                }}>
                                Actif
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {allCr &&
                              allCr.map((e) => {
                                return (
                                  <>
                                    <tr
                                      style={{
                                        borderBottom: '1px solid #CBD5E1',
                                      }}>
                                      <td>{e.idCr ? e.idCr : <></>}</td>
                                      <td>{e.filename ? e.filename : <></>}</td>
                                      <td>
                                        <a
                                          href={`${process.env.REACT_APP_URL}/api/v1/comptesrendu/getAnnexe/annexe/${e.fileToken}`}
                                          target="_blank"
                                          class="download-icon-link">
                                          <i class="las la-download file-download-icon"></i>
                                        </a>
                                      </td>
                                      <td>
                                        <div
                                          class="form-check form-switch"
                                          style={{ color: '#2DD4BF' }}>
                                          <input
                                            class="form-check-input"
                                            type="checkbox"
                                            defaultChecked={
                                              e.active ? e.active : false
                                            }
                                            onChange={(el) => {
                                              axios.patch(
                                                `${process.env.REACT_APP_URL}/api/v1/annexes/${e._id}`,
                                                { active: el.target.checked },
                                                {
                                                  headers: {
                                                    Authorization: `Bearer ${infoUser.token}`,
                                                  },
                                                }
                                              )
                                            }}
                                          />
                                          <label
                                            class="form-check-label"
                                            for="customSwitchSuccess"></label>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                )
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* fin wizard */}
        </div>
      </div>
      {/* Modal Action */}
      <div class="modal fade" id="myModalA">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Importer des photos
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                e.target.reset()
                addToast('Enregistrement en cours.', {
                  appearance: 'success',
                  autoDismiss: true,
                })
                const formData = new FormData()
                imageAction.map((item) => {
                  formData.append('item', item.originFileObj)
                })
                axios
                  .patch(
                    `${process.env.REACT_APP_URL}/api/v1/${cibleAction}/${dataModalAction._id}?planning=${infoChantier.planning}`,
                    formData,
                    {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${infoUser.token}`,
                      },
                    }
                  )
                  .then((res) => {
                    addToast('Enregistrement avec succès.', {
                      appearance: 'success',
                      autoDismiss: true,
                    })
                    if (res.data.data.data) {
                      majPhoto(dataMaj, res.data.data.data)
                    }
                  })
                  .catch((err) => {
                    console.log(err)
                    addToast("Une erreur s'est produit.", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  })
              }}>
              {' '}
              <div class="modal-body">
                {dataModalAction?.actionPhoto?.length < 4 ? (
                  <>
                    <div class="mb-3 mt-3">
                      <label for="comment">
                        (4 images max, Taille 10Mo max, type:".png,.jpeg,.jpg"):
                      </label>
                      <Upload.Dragger
                        multiple={true}
                        maxCount={4}
                        beforeUpload={(files) => {
                          return false
                        }}
                        showUploadList={{ showRemoveIcon: true }}
                        accept=".png,.jpeg,.jpg"
                        listType="picture"
                        onChange={(ev) => {
                          setImageAction(ev.fileList)
                        }}>
                        <Button>Parcourir Fichier</Button>
                      </Upload.Dragger>
                    </div>
                  </>
                ) : (
                  <>
                    <h4
                      class="modal-title"
                      style={{ color: '#334155', textAlign: 'center' }}>
                      Vous avez atteint la limite des photos à importer (4
                      images)
                    </h4>
                  </>
                )}
              </div>
              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn"
                  style={{
                    color: 'white',
                    backgroundColor: '#4F46E5',
                    borderRadius: '8px',
                  }}
                  data-bs-dismiss="modal"
                  disabled={
                    dataModalAction?.actionPhoto?.length < 4 ? false : true
                  }>
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal Planification */}
      <div class="modal fade" id="myModalPlannification">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Planification
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                const info = document.getElementById('modalPlanification').value
                updateData(
                  {
                    observation: info,
                    _id: dataModalPlanification._id,
                  },
                  'planningSo',
                  infoUser.token,
                  infoChantier.planning
                ).then((res) => {
                  if (res.data.data) {
                    maj(dataMaj, info)
                  } else {
                    addToast("Une erreur s'est produit.", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  }
                })
              }}>
              {' '}
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    id="modalPlanification"
                    value={
                      dataModalPlanification.observation
                        ? dataModalPlanification.observation
                        : ''
                    }
                    onChange={(e) => {
                      setDataModalPlanification({
                        ...dataModalPlanification,
                        observation: e.target.value,
                      })
                    }}
                    class="form-control"
                    rows="7"
                    name="text"></textarea>
                </div>
                <div className="mb-3 mt-3">
                  {dataModalPlanification?.actionPhoto?.length > 0 && (
                    <>
                      <div
                        style={{
                          width: '100%',
                          padding: '12px 12px',
                          height: '300px',
                          overflowY: 'scroll',
                        }}>
                        {dataModalPlanification.actionPhoto.map((el) => {
                          return (
                            <>
                              <div
                                className="container-fluid"
                                style={{ position: 'relative' }}
                                id={`${el._id}`}>
                                <img
                                  src={`${process.env.REACT_APP_URL}/api/v1/ressources/signedFile/${el.accessToken}`}
                                  alt=""
                                  style={{
                                    width: '100%',
                                    height: 'auto',
                                    marginTop: '5px',
                                  }}
                                />
                                <button
                                  className="btn btn-danger"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    const cible = document.getElementById(
                                      `${el._id}`
                                    )
                                    cible.style = 'display:none'
                                    const image =
                                      dataModalPlanification.actionPhoto
                                    updateData(
                                      {
                                        actionPhoto: image.filter(
                                          (img) => img._id != el._id
                                        ),
                                        _id: dataModalPlanification._id,
                                      },
                                      'planningSo',
                                      infoUser.token,
                                      infoChantier.planning
                                    ).then((res) => {
                                      if (res.data.data) {
                                        dataMaj.getRow().update(res.data.data)
                                      } else {
                                        addToast("Une erreur s'est produit", {
                                          appearance: 'error',
                                          autoDismiss: true,
                                        })
                                      }
                                    })
                                  }}
                                  style={{
                                    position: 'absolute',
                                    top: '3px',
                                    right: '15px',
                                  }}>
                                  <i className="ti ti-trash"></i>
                                </button>
                              </div>
                            </>
                          )
                        })}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn"
                  data-bs-dismiss="modal"
                  style={{ backgroundColor: '#4F46E5', color: 'white' }}>
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>{' '}
      {/* Modal Engagement */}
      <div class="modal fade" id="myModalEngagement">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: 'gray' }}>
                Engagement
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                const info = document.getElementById('modalEngagement').value
                updateData(
                  {
                    observation: info,
                    _id: dataModalEngagement._id,
                  },
                  'engagements',
                  infoUser.token,
                  infoChantier.planning
                ).then((res) => {
                  if (res.data.data) {
                    maj(dataMaj, info)
                  } else {
                    addToast("Une erreur s'est produit.", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  }
                })
              }}>
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    class="form-control"
                    rows="7"
                    value={
                      dataModalEngagement.observation
                        ? dataModalEngagement.observation
                        : ''
                    }
                    onChange={(e) => {
                      setDataModalEngagement({
                        ...dataModalEngagement,
                        observation: e.target.value,
                      })
                    }}
                    id="modalEngagement"
                    name="text"></textarea>
                </div>
                <div className="mb-3 mt-3">
                  {dataModalEngagement?.actionPhoto?.length > 0 && (
                    <>
                      <div
                        style={{
                          width: '100%',
                          padding: '12px 12px',
                          height: '300px',
                          overflowY: 'scroll',
                        }}>
                        {dataModalEngagement.actionPhoto.map((el) => {
                          return (
                            <>
                              <div
                                className="container-fluid"
                                style={{ position: 'relative' }}
                                id={`${el._id}`}>
                                <img
                                  src={`${process.env.REACT_APP_URL}/api/v1/ressources/signedFile/${el.accessToken}`}
                                  alt=""
                                  style={{
                                    width: '100%',
                                    height: 'auto',
                                    marginTop: '5px',
                                  }}
                                />
                                <button
                                  className="btn btn-danger"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    const cible = document.getElementById(
                                      `${el._id}`
                                    )
                                    cible.style = 'display:none'
                                    const image =
                                      dataModalEngagement.actionPhoto
                                    updateData(
                                      {
                                        actionPhoto: image.filter(
                                          (img) => img._id != el._id
                                        ),
                                        _id: dataModalEngagement._id,
                                      },
                                      'engagements',
                                      infoUser.token,
                                      infoChantier.planning
                                    ).then((res) => {
                                      if (res.data.data) {
                                        dataMaj.getRow().update(res.data.data)
                                      } else {
                                        addToast("Une erreur s'est produit", {
                                          appearance: 'error',
                                          autoDismiss: true,
                                        })
                                      }
                                    })
                                  }}
                                  style={{
                                    position: 'absolute',
                                    top: '3px',
                                    right: '15px',
                                  }}>
                                  <i className="ti ti-trash"></i>
                                </button>
                              </div>
                            </>
                          )
                        })}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn"
                  data-bs-dismiss="modal"
                  style={{ backgroundColor: '#4F46E5', color: 'white' }}>
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal Administratif */}
      <div class="modal fade" id="myModalAdministratif">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Administratif
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                const info = document.getElementById('modalAdministratif').value
                updateData(
                  {
                    observation: info,
                    _id: dataModalAdministration._id,
                  },
                  'administratifs',
                  infoUser.token,
                  infoChantier.planning
                ).then((res) => {
                  if (res.data.data) {
                    maj(dataMaj, info)
                  } else {
                    addToast("Une erreur s'est produit.", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  }
                })
              }}>
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    class="form-control"
                    rows="7"
                    id="modalAdministratif"
                    value={
                      dataModalAdministration.observation
                        ? dataModalAdministration.observation
                        : ''
                    }
                    onChange={(e) => {
                      setDataModalAdministration({
                        ...dataModalAdministration,
                        observation: e.target.value,
                      })
                    }}
                    name="text"></textarea>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn"
                  data-bs-dismiss="modal"
                  style={{ backgroundColor: '#4F46E5', color: 'white' }}>
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal Securite */}
      <div class="modal fade" id="myModalSecurite">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Sécurité
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                const info = document.getElementById('modalSecurite').value
                updateData(
                  {
                    observation: info,
                    _id: dataModalSecurite._id,
                  },
                  'securites',
                  infoUser.token,
                  infoChantier.planning
                ).then((res) => {
                  if (res.data.data) {
                    maj(dataMaj, info)
                  } else {
                    addToast("Une erreur s'est produit.", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  }
                })
              }}>
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    class="form-control"
                    rows="7"
                    id="modalSecurite"
                    value={
                      dataModalSecurite.observation
                        ? dataModalSecurite.observation
                        : ''
                    }
                    onChange={(e) => {
                      setDataModalSecurite({
                        ...dataModalSecurite,
                        observation: e.target.value,
                      })
                    }}
                    name="text"></textarea>
                </div>
                <div className="mb-3 mt-3">
                  {dataModalSecurite?.actionPhoto?.length > 0 && (
                    <>
                      <div
                        style={{
                          width: '100%',
                          padding: '12px 12px',
                          height: '300px',
                          overflowY: 'scroll',
                        }}>
                        {dataModalSecurite.actionPhoto.map((el) => {
                          return (
                            <>
                              <div
                                className="container-fluid"
                                style={{ position: 'relative' }}
                                id={`${el._id}`}>
                                <img
                                  src={`${process.env.REACT_APP_URL}/api/v1/ressources/signedFile/${el.accessToken}`}
                                  alt=""
                                  style={{
                                    width: '100%',
                                    height: 'auto',
                                    marginTop: '5px',
                                  }}
                                />
                                <button
                                  className="btn btn-danger"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    const cible = document.getElementById(
                                      `${el._id}`
                                    )
                                    cible.style = 'display:none'
                                    const image = dataModalSecurite.actionPhoto
                                    updateData(
                                      {
                                        actionPhoto: image.filter(
                                          (img) => img._id != el._id
                                        ),
                                        _id: dataModalSecurite._id,
                                      },
                                      'securites',
                                      infoUser.token,
                                      infoChantier.planning
                                    ).then((res) => {
                                      if (res.data.data) {
                                        dataMaj.getRow().update(res.data.data)
                                      } else {
                                        addToast("Une erreur s'est produit", {
                                          appearance: 'error',
                                          autoDismiss: true,
                                        })
                                      }
                                    })
                                  }}
                                  style={{
                                    position: 'absolute',
                                    top: '3px',
                                    right: '15px',
                                  }}>
                                  <i className="ti ti-trash"></i>
                                </button>
                              </div>
                            </>
                          )
                        })}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn"
                  data-bs-dismiss="modal"
                  style={{ backgroundColor: '#4F46E5', color: 'white' }}>
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal Controle technique */}
      <div class="modal fade" id="myModalCT">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Contrôleur technique
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                const info = document.getElementById('modalCT').value
                updateData(
                  {
                    observation: info,
                    _id: dataModalCT._id,
                  },
                  'controleurTechniques',
                  infoUser.token,
                  infoChantier.planning
                ).then((res) => {
                  if (res.data.data) {
                    maj(dataMaj, info)
                  } else {
                    addToast("Une erreur s'est produit", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  }
                })
              }}>
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    class="form-control"
                    rows="7"
                    id="modalCT"
                    value={
                      dataModalCT.observation ? dataModalCT.observation : ''
                    }
                    onChange={(e) => {
                      setDataModalCT({
                        ...dataModalCT,
                        observation: e.target.value,
                      })
                    }}
                    name="text"></textarea>
                </div>
                <div className="mb-3 mt-3">
                  {dataModalCT?.actionPhoto?.length > 0 && (
                    <>
                      <div
                        style={{
                          width: '100%',
                          padding: '12px 12px',
                          height: '300px',
                          overflowY: 'scroll',
                        }}>
                        {dataModalCT.actionPhoto.map((el) => {
                          return (
                            <>
                              <div
                                className="container-fluid"
                                style={{ position: 'relative' }}
                                id={`${el._id}`}>
                                <img
                                  src={`${process.env.REACT_APP_URL}/api/v1/ressources/signedFile/${el.accessToken}`}
                                  alt=""
                                  style={{
                                    width: '100%',
                                    height: 'auto',
                                    marginTop: '5px',
                                  }}
                                />
                                <button
                                  className="btn btn-danger"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    const cible = document.getElementById(
                                      `${el._id}`
                                    )
                                    cible.style = 'display:none'
                                    const image = dataModalCT.actionPhoto
                                    updateData(
                                      {
                                        actionPhoto: image.filter(
                                          (img) => img._id != el._id
                                        ),
                                        _id: dataModalCT._id,
                                      },
                                      'controleurTechniques',
                                      infoUser.token,
                                      infoChantier.planning
                                    ).then((res) => {
                                      if (res.data.data) {
                                        dataMaj.getRow().update(res.data.data)
                                      } else {
                                        addToast("Une erreur s'est produit", {
                                          appearance: 'error',
                                          autoDismiss: true,
                                        })
                                      }
                                    })
                                  }}
                                  style={{
                                    position: 'absolute',
                                    top: '3px',
                                    right: '15px',
                                  }}>
                                  <i className="ti ti-trash"></i>
                                </button>
                              </div>
                            </>
                          )
                        })}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn"
                  style={{
                    color: 'white',
                    backgroundColor: '#4F46E5',
                    borderRadius: '8px',
                  }}
                  data-bs-dismiss="modal">
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal Installation */}
      <div class="modal fade" id="myModalInstallation">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Installation chantier
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                const info = document.getElementById('modalInstallation').value
                updateData(
                  {
                    observation: info,
                    _id: dataModalInstallation._id,
                  },
                  'installationchantiers',
                  infoUser.token,
                  infoChantier.planning
                ).then((res) => {
                  if (res.data.data) {
                    maj(dataMaj, info)
                  } else {
                    addToast("Une erreur s'est produit.", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  }
                })
              }}>
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    class="form-control"
                    rows="7"
                    id="modalInstallation"
                    value={
                      dataModalInstallation.observation
                        ? dataModalInstallation.observation
                        : ''
                    }
                    onChange={(e) => {
                      setDataModalInstallation({
                        ...dataModalInstallation,
                        observation: e.target.value,
                      })
                    }}
                    name="text"></textarea>
                </div>
                <div className="mb-3 mt-3">
                  {dataModalInstallation?.actionPhoto?.length > 0 && (
                    <>
                      <div
                        style={{
                          width: '100%',
                          padding: '12px 12px',
                          height: '300px',
                          overflowY: 'scroll',
                        }}>
                        {dataModalInstallation.actionPhoto.map((el) => {
                          return (
                            <>
                              <div
                                className="container-fluid"
                                style={{ position: 'relative' }}
                                id={`${el._id}`}>
                                <img
                                  src={`${process.env.REACT_APP_URL}/api/v1/ressources/signedFile/${el.accessToken}`}
                                  alt=""
                                  style={{
                                    width: '100%',
                                    height: 'auto',
                                    marginTop: '5px',
                                  }}
                                />
                                <button
                                  className="btn btn-danger"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    const cible = document.getElementById(
                                      `${el._id}`
                                    )
                                    cible.style = 'display:none'
                                    const image =
                                      dataModalInstallation.actionPhoto
                                    updateData(
                                      {
                                        actionPhoto: image.filter(
                                          (img) => img._id != el._id
                                        ),
                                        _id: dataModalInstallation._id,
                                      },
                                      'installationchantiers',
                                      infoUser.token,
                                      infoChantier.planning
                                    ).then((res) => {
                                      if (res.data.data) {
                                        dataMaj.getRow().update(res.data.data)
                                      } else {
                                        addToast("Une erreur s'est produit", {
                                          appearance: 'error',
                                          autoDismiss: true,
                                        })
                                      }
                                    })
                                  }}
                                  style={{
                                    position: 'absolute',
                                    top: '3px',
                                    right: '15px',
                                  }}>
                                  <i className="ti ti-trash"></i>
                                </button>
                              </div>
                            </>
                          )
                        })}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn"
                  data-bs-dismiss="modal"
                  style={{ backgroundColor: '#4F46E5', color: 'white' }}>
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal Effectif */}
      <div class="modal fade" id="myModalEffectif">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Effectifs
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                const info = document.getElementById('modalEffectif').value
                updateData(
                  {
                    observation: info,
                    _id: dataModalEffectif._id,
                  },
                  'effectifs',
                  infoUser.token,
                  infoChantier.planning
                ).then((res) => {
                  if (res.data) {
                    maj(dataMaj, info)
                  } else {
                    addToast("Une erreur s'est produit.", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  }
                })
              }}>
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    class="form-control"
                    rows="7"
                    id="modalEffectif"
                    value={
                      dataModalEffectif.observation
                        ? dataModalEffectif.observation
                        : ''
                    }
                    onChange={(e) => {
                      setDataModalEffectif({
                        ...dataModalEffectif,
                        observation: e.target.value,
                      })
                    }}
                    name="text"></textarea>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn"
                  data-bs-dismiss="modal"
                  style={{ backgroundColor: '#4F46E5', color: 'white' }}>
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal document */}
      <ModalDocument
        dataModalDocument={dataModalDocument}
        dataModalSuiviDoc={dataModalSuiviDoc}
        indiceDoc={indiceDoc}
        setDataDocument={setDataDocument}
        setDataModalSuiviDoc={setDataModalSuiviDoc}
        dataDocument={dataDocument}
      />
      {wait | wait2 && (
        <>
          <LoaderFixed />
        </>
      )}
    </>
  )
}

export default NewCr
