//premier commentaire
import React, { useState, useEffect } from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import { useNavigate } from 'react-router-dom'
import { gantt } from 'dhtmlx-gantt'
import { getLastPointage } from '../../../helpers/planning/pointage/pointage'
import { dhtmlxGantt } from '../../../helpers/planning/gestionTache/gantt'
import { getAllData } from '../../../helpers/suivi/suivi'
import { updateOnTask } from '../../../helpers/planning/favori/favori'
import Multiselect from 'multiselect-react-dropdown'
import { getAllTask } from '../../../helpers/planning/gestionTache/dataGantt'
import { getOneChantier } from '../../../helpers/acceuil/acceuil'
import BeatLoader from 'react-spinners/BeatLoader'
import 'dhtmlx-gantt/codebase/skins/dhtmlxgantt_material.css'
import './gestionTache.scss'
import 'dhtmlx-gantt/codebase/api'
import 'dhtmlx-gantt/codebase/dhtmlxgantt'
import axios from 'axios'
import PageNotFound from '../../../components/404/PageNotFound'
import { useToasts } from 'react-toast-notifications'
import ImportPlanning from './component/ImportPlanning'
import ListTacheNonAssigner from './component/ListTacheNonAssigner'
import LoaderFixed from '../../../components/loader/LoaderFixed'
//Gestion des taches sur dhtmlx
const GestionTache = () => {
  const navigate = useNavigate()
  const { addToast } = useToasts()
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  if (
    !localStorage.getItem('isLogin') ||
    (infoUser?.user?.role?.roleType != 'admin' &&
      infoUser?.user?.role?.roleType != 'opc' &&
      infoUser?.user?.role?.roleType != 'moex')
  ) {
    return <PageNotFound />
  }
  const [assignement, setAssignement] = useState(false)
  const [tacheNonAssigner, setTacheNonAssigner] = useState([])
  const [currentColumns, setCurrentColumns] = useState([])
  const [originalColumns, setOriginalColumns] = useState([])
  const [taskUpdate, setTaskUpdate] = useState([])
  const [cheminCritique, setChemeinCritique] = useState(true)
  const [planificationAuto, setPlanificationAuto] = useState(true)
  const [showPlannificationAuto, setShowPlannificationAuto] = useState(false)
  const [general, setGeneral] = useState(true)
  const [observation, setObservation] = useState(false)
  const [correspondance, setCorrespondance] = useState(false)
  const [update, setUpdate] = useState(false)
  const [oneTask, setOneTask] = useState({})
  const [task, setTask] = useState({})
  const [curentEntreprise, setCurrentEntreprise] = useState('')
  const [allLot, setAllLot] = useState([])
  const [newLot, setNewLot] = useState('')
  const [wait, setWait] = useState(false)
  const [bool, setBool] = useState(false)
  const [lastDate, setLastDate] = useState([])
  const [pt, setPt] = useState(false)
  const [datePt, setDatePt] = useState()
  const [havePlanning, setHavePlaning] = useState(false)
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const [item, setItem] = useState('jour')
  //nomre de jours entre deux date
  function getNumberOfDays(startDate, endDate) {
    const oneDay = 24 * 60 * 60 * 1000 // Nombre de millisecondes dans une journée
    const start = new Date(startDate)
    const end = new Date(endDate)

    // Calcul de la différence en jours
    const diffDays = Math.round(Math.abs((start - end) / oneDay))

    return diffDays
  }
  const filterColonne = (tab1, tab2) => {
    const result = []
    const names = tab2.map((t2) => t2.name)
    tab1.map((t1) => {
      if (names.includes(t1.name)) {
        result.push(t1)
      }
    })
    return result
  }
  //formatter de date en fr
  const dateFr = (date) => {
    var formattedDate =
      (date.getDate() < 10 ? '0' : '') +
      date.getDate() +
      '/' +
      (date.getMonth() < 9 ? '0' : '') +
      (date.getMonth() + 1) +
      '/' +
      date.getFullYear()
    return `Le ${formattedDate}`
  }
  //pointage globale
  const pointageGlobale = () => {
    addToast("Veuillez patienter pendant le calcul de l'avancement.", {
      appearance: 'success',
      autoDismiss: true,
    })
    axios
      .post(
        `${process.env.REACT_APP_URL}/api/v1/pointages/vertical?planning=${infoChantier.planning}&dateDePointage=${datePt}`,
        { data: 'rien' },
        {
          headers: {
            Authorization: `Bearer ${infoUser.token}`,
          },
        }
      )
      .then((e) => {
        addToast('Succès.', {
          appearance: 'success',
          autoDismiss: true,
        })
        setBool(!bool)
      })
      .catch((e) => {
        addToast("Une erreur s'est produit.", {
          appearance: 'error',
          autoDismiss: true,
        })
        console.log(e)
      })
  }

  //fonction pour l'export format pdf
  const exportToPdf = () => {
    const foot = document.querySelector('#foot')
    foot.style = `display:flex;justify-content:space-between;align-items:flex-end;height:100px;`

    const corps = document.querySelector('.hide-format')
    corps.style.position = 'absolute'
    corps.style.width = '100%'
    corps.style.display = 'flex'

    const cible = document.querySelector('#cibles')
    cible.style.position = 'absolute'
    cible.style.marginTop = '0'

    const container = document.createElement('div')
    container.style = 'position:absolute;padding:0;'
    container.style.width = '100%'

    const gantts = document.querySelector('#gantt1')
    container.appendChild(gantts)
    container.appendChild(foot)

    //container.appendChild(foot)

    document.body.innerHTML = ''
    cible.appendChild(container)
    document.body.style = 'background-color:white'
    document.body.appendChild(corps)

    window.print()
    location.reload()
  }
  //configuration gantt
  //plugin de dhtmlx
  gantt.plugins({
    auto_scheduling: true,
    critical_path: true,
    marker: true,
    undo: true,
    redo: true,
  })
  // just the Redo functionality is enabled
  gantt.config.undo = true
  gantt.config.redo = true
  //configuration
  gantt.config.date_format = '%Y-%m-%d'
  gantt.config.show_errors = false
  gantt.serialize('json')

  //suppression des tache samedi et dimanche
  gantt.config.work_time = true // removes non-working time from calculations
  //gantt.config.skip_off_time = true // hides non-working time in the chart
  //Suppression tache
  /* gantt.attachEvent('onAfterTaskDelete', function (id, item) {
  }) */

  //auto déplacement des taches
  //gantt.config.auto_scheduling = false
  //gantt.config.show_links = false
  gantt.attachEvent('onGanttReady', function () {
    // Code à exécuter lorsque le Gantt est prêt
    setOriginalColumns(gantt.getGridColumns())
    if (sessionStorage.getItem('colonne')) {
      const newColonne = JSON.parse(sessionStorage.getItem('colonne'))
      setCurrentColumns(newColonne)
    } else {
      setCurrentColumns(gantt.getGridColumns())
    }
    setOriginalColumns(gantt.getGridColumns())
  })
  //formatage date
  gantt.attachEvent('onTaskLoading', function (task) {
    task.planned_start = gantt.date.parseDate(task.planned_start, 'xml_date')
    task.planned_end = gantt.date.parseDate(task.planned_end, 'xml_date')
    return true
  })
  /* gantt.attachEvent('onColumnResize', function (id, new_width) {
    console.log(gantt.getGridColumns())
  }) */
  /* gantt.attachEvent('onGridResize', function (old, new_scale) {
    // your code here
    console.log('manova')
    console.log(new_scale)
  }) */
  //evenement par double clique
  gantt.attachEvent('onTaskDblClick', function (id, e) {
    setTask(gantt.getTask(id))
    setUpdate(true)
  })

  gantt.attachEvent('onAfterTaskUpdate', function (id, item) {})
  useEffect(() => {
    setCurrentEntreprise(task?.lot?.entreprise?.nom)
  }, [task])

  //Recupération de tous les lots
  useEffect(() => {
    getAllData(
      `planning=${infoChantier.planning}`,
      'lots',
      infoUser.token
    ).then((e) => {
      setAllLot(e.data)
    })
  }, [])

  //Recuperation parametre d'affichage lien
  useEffect(() => {
    setWait(true)
    if (infoChantier.planning) {
      getOneChantier(infoChantier._id, infoUser.token)
        .then((res) => {
          if (res?.data?.liaisonTaches) {
            setShowPlannificationAuto(true)
            gantt.config.show_links = true
          } else {
            gantt.config.show_links = false
            setShowPlannificationAuto(false)
          }
        })
        .catch((err) => {
          console.log(err)
          gantt.config.show_links = false
        })
      setHavePlaning(true)
      getAllTask(infoChantier.planning, infoUser.token).then((e) => {
        console.log(e)
        setTacheNonAssigner(e.data.data)
        getLastPointage(infoChantier.planning, infoUser.token).then((res) => {
          if (res.data.date) {
            setLastDate(res.data.date)
          }
        })
        dhtmlxGantt(gantt, 'gantt1', e)
        gantt.addTaskLayer(function draw_planned(task) {
          if (task.planned_start && task.planned_end) {
            var sizes = gantt.getTaskPosition(
              task,
              task.planned_start,
              task.planned_end
            )
            var el = document.createElement('div')
            el.className = 'baseline'
            el.style.left = sizes.left - 6 + 'px'
            el.style.width = sizes.width + 10 + 'px'
            el.style.height = sizes.height - 13 + 'px'
            el.style.top = sizes.top + 1 + 'px'
            return el
          }
          return false
        })

        gantt.eachTask(function (task) {
          if (+task.duration == 0) {
            task.type = gantt.config.types.milestone
          }
          if (+task.parent > 0) {
            var parent = gantt.getTask(task.parent)
            parent.type = gantt.config.types.project
            parent.color = 'lightgray'
          }
        })
        if (sessionStorage.getItem('colonne')) {
          const newColonne = JSON.parse(sessionStorage.getItem('colonne'))
          gantt.config.columns = filterColonne(
            gantt.getGridColumns(),
            newColonne
          )
          gantt.render()
        }
        setWait(false)
      })
    } else {
      setHavePlaning(false)
      setChemeinCritique(false)
      setWait(false)
      //gantt.config.highlight_critical_path = false
    }
  }, [bool])
  //affichage des trois dernier pointage
  useEffect(() => {
    lastDate.map((e, i) => {
      if (
        i == lastDate.length - 1 ||
        i == lastDate.length - 2 ||
        i == lastDate.length - 3
      ) {
        var dateToStr = gantt.date.date_to_str(gantt.config.task_date)
        var markerId = gantt.addMarker({
          start_date: new Date(e), //a Date object that sets the marker's date
          css: 'today', //a CSS class applied to the marker
          text: `${e.split('T')[0]}`, //the marker title
          title: dateToStr(new Date()), // the marker's tooltip
        })
      }
    })
  }, [lastDate])

  //debut baseline
  gantt.config.bar_height = 20
  gantt.config.row_height = 40
  // adding baseline display

  //fonction pour l'affichage des critical path
  const criticalPath = () => {
    if (cheminCritique == false) {
      gantt.config.highlight_critical_path = false
      gantt.render()
    } else {
      gantt.config.highlight_critical_path = true
      gantt.render()
    }
  }
  //fonction pour l'affichage des critical path
  const autoScheduler = () => {
    if (planificationAuto == false) {
      gantt.config.auto_scheduling = false
      gantt.render()
    } else {
      gantt.config.auto_scheduling = true
      gantt.render()
    }
  }

  //fonction pour l'affichage des derniers pointages
  const lastPt = () => {
    pt
      ? (gantt.config.show_markers = true)
      : (gantt.config.show_markers = false)
    gantt.render()
  }

  //formatage date
  const padTo2Digits = (num) => {
    return num.toString().padStart(2, '0')
  }

  //fonction pour formater les dates
  const formatDate = (date) => {
    date = new Date(date)
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-')
  }

  //modification des favori apres mis a jour
  gantt.attachEvent('onTaskClick', function (id, e) {
    var checkbox = gantt.utils.dom.closest(e.target, '.gantt-checkbox-column')
    if (checkbox) {
      checkbox.checked = !!checkbox.checked
      gantt.getTask(id).isCritical = checkbox.checked
      return false
    } else {
      return true
    }
  })

  //zoom to fit
  var scaleConfigs = [
    // minutes
    {
      unit: 'minute',
      step: 1,
      scale_unit: 'hour',
      date_scale: '%H',
      subscales: [{ unit: 'minute', step: 1, date: '%H:%i' }],
    },
    // hours
    {
      unit: 'hour',
      step: 1,
      scale_unit: 'day',
      date_scale: '%j %D',
      //subscales: [{ unit: 'hour', step: 1, date: '%H:%i' }],
    },
    // days
    {
      unit: 'day',
      step: 1,
      scale_unit: 'month',
      date_scale: '%F %Y',
      subscales: [{ unit: 'week', step: 1, date: '%j %D' }],
    },
    // weeks
    {
      unit: 'week',
      step: 1,
      scale_unit: 'month',
      date_scale: '%F',
      subscales: [
        {
          unit: 'week',
          step: 1,
          template: function (date) {
            var dateToStr = gantt.date.date_to_str('%d %M')
            var endDate = gantt.date.add(
              gantt.date.add(date, 1, 'week'),
              -1,
              'day'
            )
            return dateToStr(date) + ' - ' + dateToStr(endDate)
          },
        },
      ],
    },
    // months
    {
      unit: 'month',
      step: 1,
      scale_unit: 'year',
      date_scale: '%Y',
      subscales: [{ unit: 'month', step: 1, date: '%M' }],
    },
    // quarters
    {
      unit: 'month',
      step: 3,
      scale_unit: 'year',
      date_scale: '%Y',
      subscales: [
        {
          unit: 'month',
          step: 3,
          template: function (date) {
            var dateToStr = gantt.date.date_to_str('%M')
            var endDate = gantt.date.add(
              gantt.date.add(date, 3, 'month'),
              -1,
              'day'
            )
            return dateToStr(date) + ' - ' + dateToStr(endDate)
          },
        },
      ],
    },
    // years
    {
      unit: 'year',
      step: 1,
      scale_unit: 'year',
      date_scale: '%Y',
      subscales: [
        {
          unit: 'year',
          step: 5,
          template: function (date) {
            var dateToStr = gantt.date.date_to_str('%Y')
            var endDate = gantt.date.add(
              gantt.date.add(date, 5, 'year'),
              -1,
              'day'
            )
            return dateToStr(date) + ' - ' + dateToStr(endDate)
          },
        },
      ],
    },
    // decades
    {
      unit: 'year',
      step: 10,
      scale_unit: 'year',
      template: function (date) {
        var dateToStr = gantt.date.date_to_str('%Y')
        var endDate = gantt.date.add(
          gantt.date.add(date, 10, 'year'),
          -1,
          'day'
        )
        return dateToStr(date) + ' - ' + dateToStr(endDate)
      },
      subscales: [
        {
          unit: 'year',
          step: 100,
          template: function (date) {
            var dateToStr = gantt.date.date_to_str('%Y')
            var endDate = gantt.date.add(
              gantt.date.add(date, 100, 'year'),
              -1,
              'day'
            )
            return dateToStr(date) + ' - ' + dateToStr(endDate)
          },
        },
      ],
    },
  ]

  // Ajoutez une fonction pour appliquer la configuration en fonction de l'unité de temps
  const applyTimeUnitConfig = (timeUnit) => {
    const selectedConfig = scaleConfigs.find(
      (config) => config.unit === timeUnit
    )
    if (selectedConfig) {
      applyConfig(selectedConfig)
      gantt.render()
    }
  }

  const applyConfig = (config, dates) => {
    gantt.config.scale_unit = config.scale_unit
    if (config.date_scale) {
      gantt.config.date_scale = config.date_scale
      gantt.templates.date_scale = null
    } else {
      gantt.templates.date_scale = config.template
    }

    gantt.config.step = config.step
    gantt.config.subscales = config.subscales

    if (dates) {
      gantt.config.start_date = gantt.date.add(
        dates.start_date,
        -1,
        config.unit
      )
      gantt.config.end_date = gantt.date.add(
        gantt.date[config.unit + '_start'](dates.end_date),
        2,
        config.unit
      )
    } else {
      gantt.config.start_date = gantt.config.end_date = null
    }
  }
  const getUnitsBetween = (from, to, unit, step) => {
    var start = new Date(from),
      end = new Date(to)
    var units = 0
    while (start.valueOf() < end.valueOf()) {
      units++
      start = gantt.date.add(start, step, unit)
    }
    return units
  }
  const zoomToFit = () => {
    var project = gantt.getSubtaskDates(),
      areaWidth = gantt.$task.offsetWidth

    for (var i = 0; i < scaleConfigs.length; i++) {
      var columnCount = getUnitsBetween(
        project.start_date,
        project.end_date,
        scaleConfigs[i].unit,
        scaleConfigs[i].step
      )
      if ((columnCount + 2) * gantt.config.min_column_width <= areaWidth) {
        break
      }
    }

    if (i == scaleConfigs.length) {
      i--
    }

    applyConfig(scaleConfigs[i], project)
    gantt.render()
  }
  var config = gantt.config
  var cachedSettings = {}
  cachedSettings.scale_unit = config.scale_unit
  cachedSettings.date_scale = config.date_scale
  cachedSettings.step = config.step
  cachedSettings.subscales = config.subscales
  cachedSettings.template = gantt.templates.date_scale
  cachedSettings.start_date = config.start_date
  cachedSettings.end_date = config.end_date
  const restoreConfig = () => {
    applyConfig(cachedSettings)
  }
  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={true} active="planning" />
        <Header bool={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid" style={{ paddingTop: '0.5vw' }}>
            <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="float-end"></div>
                  <span style={{ marginLeft: '10px' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="#94A3B8"
                      style={{ width: '16px', height: '16px' }}>
                      <path
                        fillRule="evenodd"
                        d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                        clipRule="evenodd"
                      />
                    </svg>{' '}
                    <span
                      style={{
                        color: '#334155',
                        fontSize: '14px',
                        fontWeight: '600',
                      }}>
                      / Planification
                    </span>
                  </span>{' '}
                  <br />
                  <span
                    style={{
                      marginLeft: '15px',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <span
                      style={{
                        backgroundColor: '#E2E8F0',
                        padding: '2px',
                        borderRadius: '6px',
                      }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="#6366F1"
                        style={{
                          width: '20px',
                          height: '20px',
                          cursor: 'pointer',
                        }}
                        onClick={(e) => {
                          e.preventDefault()
                          navigate('/accueil')
                        }}>
                        <path
                          fillRule="evenodd"
                          d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                    <span
                      style={{
                        color: '#334155',
                        fontSize: '20px',
                        fontWeight: '600',
                        marginLeft: '10px',
                      }}>
                      {' '}
                      Planification
                    </span>
                  </span>
                </div>
              </div>
            </div>

            {/* debut NavBar */}
            <div style={{ display: 'flex', flexWrap: 'nowrap', width: '100%' }}>
              <div className="wrapper">
                {/* <div
                  className="item"
                  style={{
                    borderLeft: '1px solid #CBD5E1',
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px',
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    navigate('/planning_Dashboard')
                  }}>
                  <span
                    style={{
                      color: '#334155',
                      fontSize: '16px',
                      fontWeight: '400',
                      cursor: 'pointer',
                    }}>
                    Tableau de bord
                  </span>
                </div> */}
                <div
                  className="item"
                  style={{
                    borderLeft: '1px solid #CBD5E1',
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px',
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    navigate('/planning_GestionTache')
                  }}>
                  <span
                    style={{
                      color: '#F8FAFC',
                      fontSize: '16px',
                      fontWeight: '400',
                      backgroundColor: '#6366F1',
                      padding: '8px 24px',
                      borderRadius: '8px',
                    }}>
                    Gestion des tâches
                  </span>
                </div>
                <div
                  className="item"
                  onClick={(e) => {
                    e.preventDefault()
                    navigate('/Planning_GestionTache_LastPlanner')
                  }}>
                  <span
                    style={{
                      color: '#334155',
                      fontSize: '16px',
                      fontWeight: '400',
                      cursor: 'pointer',
                    }}>
                    Glissant
                  </span>
                </div>
                <div
                  className="item"
                  onClick={(e) => {
                    e.preventDefault()
                    navigate('/planning_Favori')
                  }}>
                  <span
                    style={{
                      color: '#334155',
                      fontSize: '16px',
                      fontWeight: '400',
                      cursor: 'pointer',
                    }}>
                    Jalons Critiques
                  </span>
                </div>
                <div
                  className="item"
                  onClick={(e) => {
                    e.preventDefault()
                    navigate('/planning_Retard')
                  }}
                  style={{
                    borderRight: '1px solid  #CBD5E1',
                    borderTopRightRadius: '8px',
                    borderBottomRightRadius: '8px',
                  }}>
                  <span
                    style={{
                      color: '#334155',
                      fontSize: '16px',
                      fontWeight: '400',
                      cursor: 'pointer',
                    }}>
                    Retards
                  </span>
                </div>
              </div>
            </div>
            {/* Fin NavBar */}

            {/* Debut Gantt */}
            <div
              className="container-fluid"
              style={{
                width: '100%',
                marginTop: '1vw',
                position: 'relative',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                border: '1px solid #E2E8F0',
                borderRadius: '8px',
                padding: '0px 0px',
                overflow: 'hidden',
                boxShadow:
                  '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
              }}>
              {havePlanning ? (
                <>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginTop: '12px',
                      marginBottom: '12px',
                      marginLeft: '8px',
                      marginRight: '8px',
                      padding: '12px 0px',
                    }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        border: '1px solid #CBD5E1',
                        borderRadius: '4px',
                        padding: '4px 4px 4px 16px',
                        fontSize: '12px',
                        fontWeight: '600',
                        color: '#475569',
                      }}>
                      <span
                        style={
                          item == 'jour'
                            ? {
                                color: '#F8FAFC',
                                padding: '4px 24px',
                                backgroundColor: '#6366F1',
                                borderRadius: '4px',
                              }
                            : { cursor: 'pointer' }
                        }
                        onClick={(e) => {
                          e.preventDefault()
                          setItem('jour')
                          sessionStorage.setItem('zoomPlanning', 'jour')
                          /* gantt.config.scales = [
                            { unit: 'day', step: 1, format: '%j %D' },
                          ]
                          gantt.render() */
                          applyTimeUnitConfig('hour')
                        }}>
                        {' '}
                        Jour
                      </span>
                      <span
                        style={
                          item == 'semaine'
                            ? {
                                color: '#F8FAFC',
                                padding: '4px 24px',
                                backgroundColor: '#6366F1',
                                borderRadius: '4px',
                                marginLeft: '16px',
                              }
                            : { marginLeft: '16px', cursor: 'pointer' }
                        }
                        onClick={(e) => {
                          e.preventDefault()
                          setItem('semaine')
                          sessionStorage.setItem('zoomPlanning', 'semaine')
                          /* gantt.config.scales = [
                            { unit: 'month', step: 1, format: '%F %Y' },
                            { unit: 'week', step: 1, format: '%j %D' },
                          ] */
                          applyTimeUnitConfig('day')
                        }}>
                        Semaine
                      </span>
                      <span
                        style={
                          item == 'mois'
                            ? {
                                color: '#F8FAFC',
                                padding: '4px 24px',
                                backgroundColor: '#6366F1',
                                borderRadius: '4px',
                                marginLeft: '16px',
                              }
                            : {
                                marginLeft: '16px',
                                cursor: 'pointer',
                                marginRight: '16px',
                              }
                        }
                        onClick={(e) => {
                          e.preventDefault()
                          setItem('mois')
                          sessionStorage.setItem('zoomPlanning', 'mois')
                          /* gantt.config.scales = [
                            { unit: 'year', step: 1, format: '%Y' },
                            { unit: 'month', step: 1, format: '%j %F' },
                          ]
                          gantt.render() */
                          //zoomToFit()
                          applyTimeUnitConfig('month')
                        }}>
                        Mois
                      </span>
                      <span
                        style={
                          item == 'globale'
                            ? {
                                color: '#F8FAFC',
                                padding: '4px 24px',
                                backgroundColor: '#6366F1',
                                borderRadius: '4px',
                                marginLeft: '16px',
                              }
                            : {
                                marginLeft: '16px',
                                cursor: 'pointer',
                                marginRight: '16px',
                              }
                        }
                        onClick={(e) => {
                          e.preventDefault()
                          setItem('globale')
                          sessionStorage.setItem('zoomPlanning', 'globale')
                          /* gantt.config.scales = [
                            { unit: 'year', step: 1, format: '%Y' },
                            { unit: 'month', step: 1, format: '%j %F' },
                          ]
                          gantt.render() */
                          zoomToFit()
                        }}>
                        Globale
                      </span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <button
                          className="btn btn-sm"
                          style={{
                            backgroundColor: '#4F46E5',
                            color: '#F8FAFC',
                            padding: '8px 12px',
                            fontSize: '12px',
                          }}
                          onClick={(e) => {
                            addToast('Veuillez patienter! ...', {
                              appearance: 'success',
                              autoDismiss: true,
                            })
                            console.log(gantt.serialize('json'))
                            axios
                              .patch(
                                `${process.env.REACT_APP_URL}/api/v1/taches`,
                                {
                                  planning: infoChantier?.planning,
                                  ...gantt.serialize('json'),
                                },
                                {
                                  headers: {
                                    Authorization: `Bearer ${infoUser.token}`,
                                  },
                                }
                              )
                              .then((e) => {
                                addToast('Succès.', {
                                  appearance: 'success',
                                  autoDismiss: true,
                                })
                                setTaskUpdate([])
                              })
                              .catch((e) => {
                                addToast("Une erreur s'est produit.", {
                                  appearance: 'error',
                                  autoDismiss: true,
                                })
                                setTaskUpdate([])
                              })
                          }}>
                          Enregistrer
                        </button>
                        <button
                          style={{
                            border: '1px solid #CBD5E1',
                            borderRadius: '8px',
                            padding: '8px',
                            width: '36px',
                            height: '36px',
                            marginLeft: '8px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            backgroundColor: 'transparent',
                          }}
                          onClick={(ev) => {
                            gantt.undo()
                          }}>
                          <i
                            class="bi bi-arrow-90deg-left"
                            style={{ color: '#6366F1' }}></i>
                        </button>
                        <button
                          style={{
                            border: '1px solid #CBD5E1',
                            borderRadius: '8px',
                            padding: '8px',
                            width: '36px',
                            height: '36px',
                            marginLeft: '8px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            backgroundColor: 'transparent',
                          }}
                          onClick={(ev) => {
                            gantt.redo()
                          }}>
                          <i
                            class="bi bi-arrow-90deg-right"
                            style={{ color: '#6366F1' }}></i>
                        </button>
                      </div>
                      <div
                        style={{
                          marginLeft: '32px',
                          display: 'flex',
                          alignItems: 'center',
                          border: '1px solid #CBD5E1',
                          borderRadius: '8px',
                          padding: '8px 12px',
                          fontSize: '14px',
                          fontWeight: '400',
                          color: ' #64748B',
                        }}>
                        {showPlannificationAuto && (
                          <>
                            <span
                              style={{
                                display: 'flex',
                                marginRight: '12px',
                                alignItems: 'center',
                              }}>
                              <div
                                className="form-check form-switch"
                                onClick={(e) => {
                                  setPlanificationAuto(!planificationAuto)
                                  autoScheduler()
                                }}>
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={!planificationAuto}
                                />
                              </div>{' '}
                              <span
                                style={{ marginLeft: '3px', fontSize: '12px' }}>
                                Planification auto
                              </span>
                            </span>
                          </>
                        )}
                        {/* <span>|</span> */}
                        <span
                          style={{
                            display: 'flex',
                            marginRight: '12px',
                            alignItems: 'center',
                          }}>
                          <div
                            className="form-check form-switch"
                            onClick={(e) => {
                              setChemeinCritique(!cheminCritique)
                              criticalPath()
                            }}>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              checked={!cheminCritique}
                            />
                          </div>{' '}
                          <span style={{ marginLeft: '3px', fontSize: '12px' }}>
                            Chemin critique
                          </span>
                        </span>
                        {/* <span>|</span> */}
                        <span
                          style={{
                            display: 'flex',
                            marginLeft: '12px',
                            alignItems: 'center',
                          }}>
                          <div
                            className="form-check form-switch"
                            onClick={(el) => {
                              setPt(!pt)
                              lastPt()
                            }}>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              checked={!pt}
                            />
                          </div>{' '}
                          <span style={{ marginLeft: '3px', fontSize: '12px' }}>
                            Pointages
                          </span>
                        </span>
                      </div>

                      {/**Boutton noueau pointage */}
                      <button
                        style={{
                          border: '1px solid #CBD5E1',
                          borderRadius: '8px',
                          padding: '8px',
                          width: '36px',
                          height: '36px',
                          marginLeft: '8px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                          backgroundColor: 'transparent',
                        }}
                        id="dropdownMenuButton5"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="#6366F1"
                          style={{ width: '22px', height: '22px' }}>
                          <path d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
                          <path
                            fillRule="evenodd"
                            d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                      <div
                        class="modal fade"
                        id="exampleModal"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true">
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div
                              class="modal-header"
                              style={{ backgroundColor: '#EBEFF5' }}>
                              <h5
                                class="modal-title"
                                style={{ color: '#334155' }}>
                                Inserer la date du pointage
                              </h5>
                              <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"></button>
                            </div>
                            <form
                              onSubmit={(e) => {
                                e.preventDefault()
                                e.target.reset()
                                pointageGlobale()
                              }}>
                              <div class="modal-body">
                                <div class="mb-3">
                                  <label class="form-label">
                                    Date du pointage
                                  </label>
                                  <input
                                    type="date"
                                    class="form-control"
                                    onChange={(e) => {
                                      setDatePt(e.target.value)
                                    }}
                                    required
                                  />
                                </div>
                              </div>
                              <div class="modal-footer">
                                <button
                                  type="submit"
                                  class="btn"
                                  data-bs-dismiss="modal"
                                  style={{
                                    color: 'white',
                                    backgroundColor: '#4F46E5',
                                    borderRadius: '8px',
                                  }}>
                                  valider
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      {/**Boutton filtrage colonne */}
                      <button
                        style={{
                          border: '1px solid #CBD5E1',
                          borderRadius: '8px',
                          padding: '8px',
                          width: '36px',
                          height: '36px',
                          marginLeft: '8px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                          backgroundColor: 'transparent',
                        }}
                        id="dropdownMenuButton5"
                        data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="#6366F1"
                          style={{ width: '20px', height: '20px' }}>
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
                          />
                        </svg>
                      </button>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton5"
                        onClick={(e) => {
                          e.stopPropagation()
                        }}>
                        <li>
                          <a href="#" class="dropdown-item">
                            <Multiselect
                              displayValue="label"
                              onRemove={function noRefCheck(e) {
                                setCurrentColumns(e)
                                sessionStorage.setItem(
                                  'colonne',
                                  JSON.stringify(e)
                                )

                                gantt.config.columns = e
                                gantt.render()
                              }}
                              onSearch={function noRefCheck() {}}
                              onSelect={function noRefCheck(e) {
                                setCurrentColumns(e)
                                sessionStorage.setItem(
                                  'colonne',
                                  JSON.stringify(e)
                                )
                                gantt.config.columns = e
                                gantt.render()
                              }}
                              options={originalColumns}
                              selectedValues={currentColumns}
                              placeholder="Colonne"
                            />
                          </a>
                        </li>
                      </ul>
                      <div
                        style={{
                          border: '1px solid #CBD5E1',
                          borderRadius: '8px',
                          padding: '8px',
                          width: '36px',
                          height: '36px',
                          marginLeft: '8px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#myModal">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="#6366F1"
                          style={{ width: '20px', height: '20px' }}>
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                          />
                        </svg>
                        <ImportPlanning
                          bool={bool}
                          setBool={setBool}
                          setAssignement={setAssignement}
                          setTacheNonAssigner={setTacheNonAssigner}
                          setWait={setWait}
                        />
                      </div>
                      <button
                        style={{
                          border: '1px solid #CBD5E1',
                          borderRadius: '8px',
                          padding: '8px',
                          width: '36px',
                          height: '36px',
                          marginLeft: '5px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                          backgroundColor: 'transparent',
                        }}
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="#6366F1"
                          style={{ width: '20px', height: '20px' }}>
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                          />
                        </svg>
                      </button>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1">
                        <li
                          onClick={(e) => {
                            e.preventDefault()
                            setWait(true)
                            gantt.config.scales = [
                              { unit: 'year', step: 1, format: '%Y' },
                              { unit: 'month', step: 1, format: '%F' },
                            ]
                            gantt.config.columns = [
                              { name: 'text', label: 'Nom', min_width: 390 },
                              {
                                name: 'start_date',
                                label: 'début',
                                min_width: 90,
                              },
                              { name: 'end_date', label: 'Fin', min_width: 90 },
                              {
                                name: 'progress',
                                label: 'Av%',
                                template: function (task) {
                                  return `${(task.progress * 100).toFixed(2)}%`
                                },
                                align: 'center',
                              },
                              {
                                name: 'duration',
                                label: 'Durée',
                                align: 'center',
                              },
                            ]
                            gantt.config.show_links = false
                            gantt.config.autosize = 'xy'
                            gantt.render()
                            exportToPdf()
                          }}>
                          <a
                            class="dropdown-item text-center"
                            href="#"
                            style={{ fontSize: '1.2vw' }}>
                            PDF
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    id="gantt1"
                    style={{
                      width: '100%',
                      height: '70vh',
                      marginTop: '1px',
                    }}></div>
                </>
              ) : (
                <>
                  <h1>vous devez importer un planning</h1>
                </>
              )}
            </div>
            {/* Fin Gantt*/}

            {/* debut colonne personnaliser */}
            {update && (
              <>
                <div className="container-fluid contenuRetard">
                  <div className="formRetard">
                    <div className="container-fluid headerGantt">
                      <h1 className="text-center">Informations de la tâche</h1>
                      <ul class="nav">
                        <li
                          class="nav-item"
                          onClick={(e) => {
                            e.preventDefault()
                            setCorrespondance(false)
                            setObservation(false)
                            setGeneral(true)
                          }}>
                          <a
                            class={
                              general
                                ? 'nav-link bgActive'
                                : 'nav-link bgNonActive'
                            }>
                            GENERAL
                          </a>
                        </li>
                        <li
                          class="nav-item"
                          onClick={(e) => {
                            e.preventDefault()
                            setCorrespondance(true)
                            setObservation(false)
                            setGeneral(false)
                          }}>
                          <a
                            class={
                              correspondance
                                ? 'nav-link bgActive'
                                : 'nav-link bgNonActive'
                            }>
                            CORRESPONDANCE
                          </a>
                        </li>
                        <li
                          class="nav-item"
                          onClick={(e) => {
                            e.preventDefault()
                            setCorrespondance(false)
                            setGeneral(false)
                            setObservation(true)
                          }}>
                          <a
                            class={
                              observation
                                ? 'nav-link bgActive'
                                : 'nav-link bgNonActive'
                            }>
                            OBSERVATIONS
                          </a>
                        </li>
                      </ul>
                    </div>
                    {general && (
                      <>
                        <form
                          style={{ width: '100%' }}
                          onSubmit={(e) => {
                            e.preventDefault()
                            const a = gantt.getTask(task?.id)
                            gantt.updateTask(task?.id, {
                              ...a,
                              ...{ ...oneTask },
                            })

                            setUpdate(false)
                            setOneTask({})
                            addToast('Veuillez patienter! ...', {
                              appearance: 'success',
                              autoDismiss: true,
                            })
                            console.log(gantt.serialize('json'))
                            axios
                              .patch(
                                `${process.env.REACT_APP_URL}/api/v1/taches`,
                                {
                                  planning: infoChantier?.planning,
                                  ...gantt.serialize('json'),
                                },
                                {
                                  headers: {
                                    Authorization: `Bearer ${infoUser.token}`,
                                  },
                                }
                              )
                              .then((e) => {
                                addToast('Succès.', {
                                  appearance: 'success',
                                  autoDismiss: true,
                                })
                                setTaskUpdate([])
                              })
                              .catch((e) => {
                                addToast("Une erreur s'est produit.", {
                                  appearance: 'error',
                                  autoDismiss: true,
                                })
                                setTaskUpdate([])
                              })
                          }}>
                          <div
                            className="d-flex"
                            style={{
                              width: '100%',
                              justifyContent: 'space-around',
                            }}>
                            <div class="mb-3">
                              <label
                                for="exampleFormControlInput1"
                                class="form-label">
                                ID
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                value={task?.id}
                                style={{ width: '12vw' }}
                                disabled={true}
                              />
                            </div>
                            <div class="mb-3">
                              <label
                                for="exampleFormControlTextarea1"
                                class="form-label">
                                Nom
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                defaultValue={task?.text}
                                style={{ width: '12vw' }}
                                onChange={(e) => {
                                  e.preventDefault()
                                  setOneTask({
                                    ...oneTask,
                                    text: e.target.value,
                                  })
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className="d-flex"
                            style={{
                              width: '100%',
                              justifyContent: 'space-around',
                            }}>
                            <div class="mb-3">
                              <label
                                for="exampleFormControlInput1"
                                class="form-label">
                                Date début prévue
                              </label>
                              <input
                                type="date"
                                class="form-control"
                                style={{ width: '12vw' }}
                                defaultValue={formatDate(task.planned_start)}
                                disabled={true}
                              />
                            </div>
                            <div class="mb-3">
                              <label
                                for="exampleFormControlTextarea1"
                                class="form-label">
                                Date début réelle
                              </label>
                              <input
                                type="date"
                                class="form-control"
                                style={{ width: '12vw' }}
                                defaultValue={formatDate(task.start_date)}
                                onChange={(e) => {
                                  e.preventDefault()
                                  setOneTask({
                                    ...oneTask,
                                    start_date: new Date(e.target.value),
                                  })
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className="d-flex"
                            style={{
                              width: '100%',
                              justifyContent: 'space-around',
                            }}>
                            <div class="mb-3">
                              <label
                                for="exampleFormControlInput1"
                                class="form-label">
                                Date fin prévue
                              </label>
                              <input
                                type="date"
                                class="form-control"
                                style={{ width: '12vw' }}
                                defaultValue={formatDate(task.planned_end)}
                                disabled={true}
                              />
                            </div>
                            <div class="mb-3">
                              <label
                                for="exampleFormControlTextarea1"
                                class="form-label">
                                Date fin réelle
                              </label>
                              <input
                                type="date"
                                class="form-control"
                                style={{ width: '12vw' }}
                                defaultValue={formatDate(task.end_date)}
                                onChange={(e) => {
                                  e.preventDefault()
                                  setOneTask({
                                    ...oneTask,
                                    end_date: new Date(e.target.value),
                                  })
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className="d-flex"
                            style={{
                              width: '100%',
                              justifyContent: 'space-around',
                            }}>
                            <div class="mb-3">
                              <label
                                for="exampleFormControlInput1"
                                class="form-label">
                                Durée Prévue
                              </label>
                              <input
                                type="number"
                                class="form-control"
                                defaultValue={getNumberOfDays(
                                  task.planned_start,
                                  task.planned_end
                                )}
                                style={{ width: '12vw' }}
                                disabled={true}
                              />
                            </div>
                            <div class="mb-3">
                              <label
                                for="exampleFormControlTextarea1"
                                class="form-label">
                                Durée réelle
                              </label>
                              <input
                                type="number"
                                class="form-control"
                                defaultValue={getNumberOfDays(
                                  task.start_date,
                                  task.end_date
                                )}
                                style={{ width: '12vw' }}
                                disabled={true}
                                onChange={(e) => {
                                  e.preventDefault()
                                  setOneTask({
                                    ...oneTask,
                                    duration: +e.target.value,
                                  })
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className="d-flex"
                            style={{
                              width: '100%',
                              justifyContent: 'space-around',
                            }}>
                            <div class="mb-3">
                              <label
                                for="exampleFormControlInput1"
                                class="form-label">
                                Avancement (%)
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                defaultValue={
                                  task?.progress
                                    ? `${(task.progress * 100).toFixed(0)}`
                                    : '0%'
                                }
                                style={{ width: '12vw' }}
                                onChange={(e) => {
                                  e.preventDefault()
                                  setOneTask({
                                    ...oneTask,
                                    progress: e.target.value / 100,
                                  })
                                }}
                              />
                            </div>
                            <div class="mb-3">
                              <label
                                for="exampleFormControlTextarea1"
                                class="form-label">
                                Date initiale
                              </label>
                              <input
                                type="date"
                                class="form-control"
                                defaultValue={
                                  task?.initial_date
                                    ? formatDate(task.initial_date)
                                    : 'jj/mm/yyyy'
                                }
                                style={{ width: '12vw' }}
                                onChange={(e) => {
                                  e.preventDefault()
                                  setOneTask({
                                    ...oneTask,
                                    initial_date: e.target.value,
                                  })
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className="d-flex"
                            style={{
                              width: '100%',
                              justifyContent: 'space-around',
                            }}></div>

                          <div
                            className="d-flex"
                            style={{
                              width: '100%',
                              alignItems: 'center',
                              justifyContent: 'space-around',
                            }}>
                            <button
                              className="btn"
                              type="submit"
                              style={{
                                color: 'white',
                                backgroundColor: '#4F46E5',
                                borderRadius: '8px',
                              }}>
                              Enregistrer
                            </button>
                            <button
                              className="btn btn-danger"
                              onClick={(e) => {
                                e.preventDefault()
                                setUpdate(false)
                                setOneTask({})
                                gantt.deleteTask(task.id)
                              }}>
                              Supprimer
                            </button>
                            <button
                              className="btn"
                              onClick={(e) => {
                                e.preventDefault()
                                setUpdate(false)
                                setOneTask({})
                              }}
                              style={{
                                color: 'black',
                                backgroundColor: 'lightgray',
                                borderRadius: '8px',
                              }}>
                              Fermer
                            </button>
                          </div>
                        </form>
                      </>
                    )}
                    {correspondance && (
                      <>
                        <form
                          onSubmit={(e) => {
                            e.preventDefault()
                            updateOnTask(
                              'lot',
                              newLot,
                              task._id,
                              infoUser.token,
                              infoChantier.planning
                            ).then((e) => {
                              setBool(!bool)
                            })
                            //à ne pas supprimer
                            setUpdate(false)
                            setOneTask({})
                          }}>
                          <div
                            class="mb-3"
                            style={{ width: '50%', marginLeft: '5%' }}>
                            <label for="exampleInputEmail1" class="form-label">
                              Lot:
                            </label>
                            <select
                              class="form-select"
                              aria-label="Default select example"
                              onChange={(e) => {
                                const ent = JSON.parse(e.target.value)
                                const datas = {
                                  lot: JSON.parse(e.target.value),
                                }
                                setNewLot(datas)
                                setCurrentEntreprise(ent.entreprise.nom)
                                // updateOnTask(
                                //   'lot',
                                //   datas,
                                //   task._id,
                                //   infoUser.token
                                // ).then((e) => {
                                //   console.log(e)
                                //   setBool(!bool)
                                // })
                              }}>
                              <option selected>
                                {task.lot
                                  ? task.lot.titre
                                  : 'Aucun lot associé'}
                              </option>
                              {allLot &&
                                allLot.map((e) => {
                                  return (
                                    <>
                                      <option value={JSON.stringify(e)}>
                                        {e.titre}
                                      </option>
                                    </>
                                  )
                                })}
                            </select>
                          </div>
                          <div
                            class="mb-3"
                            style={{ width: '50%', marginLeft: '5%' }}>
                            <label
                              for="exampleInputPassword1"
                              class="form-label">
                              Entreprise:
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              defaultValue={
                                curentEntreprise
                                  ? curentEntreprise
                                  : 'Aucune entreprise associée'
                              }
                              disabled={true}
                            />
                          </div>
                          <div
                            className="d-flex"
                            style={{
                              width: '100%',
                              alignItems: 'center',
                              justifyContent: 'space-around',
                            }}>
                            <button
                              className="btn"
                              style={{
                                color: 'white',
                                backgroundColor: '#4F46E5',
                                borderRadius: '8px',
                              }}
                              type="submit">
                              Enregistrer
                            </button>

                            <button
                              className="btn"
                              onClick={(e) => {
                                e.preventDefault()
                                setUpdate(false)
                                setOneTask({})
                              }}
                              style={{
                                color: 'white',
                                backgroundColor: 'lightgray',
                                borderRadius: '8px',
                              }}>
                              Fermer
                            </button>
                          </div>
                        </form>
                      </>
                    )}
                    {observation && (
                      <>
                        <form
                          onSubmit={(e) => {
                            e.preventDefault()
                            const a = gantt.getTask(task?.id)
                            gantt.updateTask(task?.id, {
                              ...a,
                              ...{ ...oneTask },
                            })
                            setUpdate(false)
                            setOneTask({})
                          }}>
                          <div
                            class="mb-3"
                            style={{ width: '50%', marginLeft: '5%' }}>
                            <label for="exampleInputEmail1" class="form-label">
                              Description:
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              defaultValue={task?.favori}
                              onChange={(e) => {
                                e.preventDefault()
                                setOneTask({
                                  ...oneTask,
                                  favori: e.target.value,
                                })
                              }}
                            />
                          </div>
                          <div
                            class="mb-3"
                            style={{ width: '50%', marginLeft: '5%' }}>
                            <label
                              for="exampleInputPassword1"
                              class="form-label">
                              Observations:
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              defaultValue={
                                task?.observation ? task.observation : ''
                              }
                              onChange={(e) => {
                                e.preventDefault()
                                setOneTask({
                                  ...oneTask,
                                  observation: e.target.value,
                                })
                              }}
                            />
                          </div>
                          <div
                            className="d-flex"
                            style={{
                              width: '100%',
                              alignItems: 'center',
                              justifyContent: 'space-around',
                            }}>
                            <button
                              className="btn"
                              type="submit"
                              style={{
                                color: 'white',
                                backgroundColor: '#4F46E5',
                                borderRadius: '8px',
                              }}>
                              Enregistrer
                            </button>
                            <button
                              className="btn"
                              onClick={(e) => {
                                e.preventDefault()
                                setUpdate(false)
                                setOneTask({})
                              }}
                              style={{
                                color: 'white',
                                backgroundColor: 'lightgray',
                                borderRadius: '8px',
                              }}>
                              Fermer
                            </button>
                          </div>
                        </form>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
            {/* fin colonne personnaliser */}
          </div>
        </div>
        <div className="hide-format">
          <table>
            <thead>
              <tr>
                <td>
                  <div class="page-header-space"></div>
                </td>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  <div class="page" id="cibles">
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        position: 'absolute',
                        top: '-70px',
                        left: '0',
                        right: '0',
                        borderBottom: 'solid gainsboro 1px',
                        paddingBottom: '10px',
                        zIndex: '9999999999999999999999999999999999999',
                      }}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '20%',
                        }}>
                        <img
                          src={
                            infoChantier?.logoClient
                              ? `${process.env.REACT_APP_URL}/api/v1/ressources/img/files,photo,logoClient/${infoChantier.logoClient}?accessToken=${infoUser.token}`
                              : 'https://back.sooda.fr/logo.png'
                          }
                          alt="KOLOS"
                          style={{ width: '100px', zIndex: '0' }}
                        />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '60%',
                        }}>
                        <h5 style={{ textAlign: 'center' }}>
                          {infoChantier.titre
                            ? infoChantier.titre.toUpperCase()
                            : ''}
                        </h5>
                      </div>
                      <div
                        style={{
                          width: '20%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}>
                        <h5 style={{ textAlign: 'center' }}>
                          PLANNING GENERALE
                        </h5>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>

            <tfoot>
              <tr>
                <td>
                  <div class="page-footer-space"></div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div style={{ display: 'none' }}>
        <div
          id="foot"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            width: '100%',
          }}>
          <div>{dateFr(new Date(Date.now()))}</div>
          <div>Propulsé par KOLOS</div>
        </div>
      </div>
      {assignement && (
        <ListTacheNonAssigner
          taches={tacheNonAssigner}
          setAsssignement={setAssignement}
          lot={allLot}
          setWait={setWait}
        />
      )}
      {wait && (
        <>
          <LoaderFixed />
        </>
      )}
    </>
  )
}

export default GestionTache
