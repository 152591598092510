import axios from 'axios'

export const addUser = async (data,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    await axios
      .post(`${process.env.REACT_APP_URL}/api/v1/user`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        },
      })
      .then((res) => {
        console.log(res)
        return res.data
      })
      .catch((err) => {
        console.log(err)
        return err
      })
  } catch (err) {
    console.log(err)
    return { message: 'erreur' }
  }
}
export const updateUser = async (data,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const result = await axios
      .patch(`${process.env.REACT_APP_URL}/api/v1/user/${data._id}`, data,config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
    console.log(err)
    return err
  }
}
export const getChantierUser = async (id,token,planning) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const result = await axios
      .get(`${process.env.REACT_APP_URL}/api/v1/chantiers/parIntervenant/${id}?planning=${planning}`,config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
    console.log(err)
    return err
  }
}
export const updateChantierUser = async (id,data,token,planning) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const result = await axios
      .patch(`${process.env.REACT_APP_URL}/api/v1/chantiers/${id}?planning=${planning}`,{envoieEmail:data},config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
    console.log(err)
    return err
  }
}

export const getAllUser = async (query,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const result = await axios
      .get(`${process.env.REACT_APP_URL}/api/v1/user?${query}`,config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
    console.log(err)
    return err
  }
}

export const delUser = async (data,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    //console.log(data._id)
    const result = await axios
      .delete(`${process.env.REACT_APP_URL}/api/v1/user/${data._id}`,config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
    console.log(err)
    return err
  }
}

export const getOneUser = async (id,token,planning) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const result = await axios
      .get(`${process.env.REACT_APP_URL}/api/v1/users/${id}?planning=${planning}`,config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
   console.log(err);
    return err
  }
}
export const updateUserInfo = async (id,nom,prenom,email,tel,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const result = await axios
      .patch(`${process.env.REACT_APP_URL}/api/v1/users/updateMe`,{name:nom,lastname:prenom,email:email,telephone:tel},config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
   console.log(err);
    return err
  }
}
export const updateUserSociete = async (id,societe,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const result = await axios
      .patch(`${process.env.REACT_APP_URL}/api/v1/users/updateMe`,{entrepriseName:societe},config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
   console.log(err);
    return err
  }
}


